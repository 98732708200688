import { Component, OnInit, forwardRef } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-predictive-year-text',
  templateUrl: './predictive-year-text.component.html',
  styleUrls: ['./predictive-year-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PredictiveYearTextComponent),
      multi: true
    }
  ]
})
export class PredictiveYearTextComponent implements OnInit, ControlValueAccessor {
  yearInput = new FormControl('');
  years: number[] = []; // Array to hold the years

  onChange: any = () => {};
  onTouched: any = () => {};

  ngOnInit(): void {
    this.generateYears();
  }

  private generateYears(): void {
    const currentYear = new Date().getFullYear();
    this.years = Array.from({ length: currentYear - 1900 + 3 }, (_, i) => 1900 + i);
  }

  writeValue(value: any): void {
    this.yearInput.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  selectYear(year: number): void {
    this.yearInput.setValue(year);
    this.onChange(year);
  }
}
