import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ERC20Currency } from '../types/ERC20currency.type';

@Injectable()
export class CoinListService {

  constructor() { }

  supportedCurrencies: { [nameAndnetwork: string]: ERC20Currency } = {
    DIGIMATIC: {
      network: 'MATIC',
      contractAddress: environment.maticCoinContractAddresses.digiAddressMatic,
      displayName: 'DIGI',
      decimalPlaces: 18,
      coingeckoId: 'digible',
      logoURL:
        'https://assets.coingecko.com/coins/images/14960/small/RE3Fiua.png?1619148839',
      displayRoundDecimals: 2,
    },

    USDTMATIC: {
      network: 'MATIC',
      contractAddress: environment.maticCoinContractAddresses.usdtAddressMatic,
      displayName: 'USDT',
      decimalPlaces: 6,
      coingeckoId: 'tether',
      logoURL: 'https://polygonscan.com/token/images/tether_32.png',
      displayRoundDecimals: 2,
    },

    USDCMATIC: {
      network: 'MATIC',
      contractAddress: environment.maticCoinContractAddresses.usdcAddressMatic,
      displayName: 'USDC',
      decimalPlaces: 6,
      coingeckoId: 'usd-coin',
      logoURL: 'https://polygonscan.com/token/images/centre-usdc_32.png',
      displayRoundDecimals: 2,
    },

    WETHMATIC: {
      network: 'MATIC',
      contractAddress: environment.maticCoinContractAddresses.wethAddressMatic,
      displayName: 'WETH',
      decimalPlaces: 18,
      coingeckoId: 'weth',
      logoURL: 'https://polygonscan.com/token/images/wETH_32.png',
      displayRoundDecimals: 4,
    },

    MATICMATIC: {
      network: 'MATIC',
      contractAddress: environment.maticCoinContractAddresses.maticCoinAddress,
      displayName: 'MATIC',
      decimalPlaces: 18,
      coingeckoId: 'matic-network',
      logoURL: 'https://polygonscan.com/token/images/matic_32.png',
      displayRoundDecimals: 2,
    },

    LINKMATIC: {
      network: 'MATIC',
      contractAddress: environment.maticCoinContractAddresses.linkAddressMatic,
      displayName: 'LINK',
      decimalPlaces: 18,
      coingeckoId: 'link',
      logoURL: 'https://polygonscan.com/token/images/chainlinktoken_32.png?v=6',
      displayRoundDecimals: 2,
    },

    WBTCMATIC: {
      network: 'MATIC',
      contractAddress: environment.maticCoinContractAddresses.wbtcAddressMatic,
      displayName: 'WBTC',
      decimalPlaces: 8,
      coingeckoId: 'wrapped-bitcoin',
      logoURL: 'https://polygonscan.com/token/images/wBTC_32.png',
      displayRoundDecimals: 6,
    },
    DIGIBSC: {
      network: 'BSC',
      contractAddress: environment.bscCoinContractAddresses.digiBSCAddress,
      displayName: 'DIGIBSC',
      decimalPlaces: 18,
      coingeckoId: 'digible',
      logoURL:
        'https://assets.coingecko.com/coins/images/14960/small/RE3Fiua.png?1619148839',
      displayRoundDecimals: 2,
    },

    BUSDBSC: {
      network: 'BSC',
      contractAddress: environment.bscCoinContractAddresses.busdBSCAddress,
      displayName: 'BUSD',
      decimalPlaces: 18,
      coingeckoId: 'binance-usd',
      logoURL: 'https://bscscan.com/token/images/busd_32.png',
      displayRoundDecimals: 2,
    },

    WBNBBSC: {
      network: 'BSC',
      contractAddress: environment.bscCoinContractAddresses.wbnbBSCAddress,
      displayName: 'WBNB',
      decimalPlaces: 18,
      coingeckoId: 'wbnb',
      logoURL: 'https://bscscan.com/token/images/binance_32.png',
      displayRoundDecimals: 2,
    },
  };

  getCoinDetails(address: string, network: string): any {
    if (network.toUpperCase() == 'MATIC') {
      if (
        address.toUpperCase() ==
        environment.maticCoinContractAddresses.digiAddressMatic.toUpperCase()
      ) {
        return this.supportedCurrencies['DIGIMATIC'];
      } else if (
        address.toUpperCase() ==
        environment.maticCoinContractAddresses.usdtAddressMatic.toUpperCase()
      ) {
        return this.supportedCurrencies['USDTMATIC'];
      } else if (
        address.toUpperCase() ==
        environment.maticCoinContractAddresses.usdcAddressMatic.toUpperCase()
      ) {
        return this.supportedCurrencies['USDCMATIC'];
      } else if (
        address.toUpperCase() ==
        environment.maticCoinContractAddresses.wethAddressMatic.toUpperCase()
      ) {
        return this.supportedCurrencies['WETHMATIC'];
      } else if (
        address.toUpperCase() ==
        environment.maticCoinContractAddresses.maticCoinAddress.toUpperCase()
      ) {
        return this.supportedCurrencies['MATICMATIC'];
      } else if (
        address.toUpperCase() ==
        environment.maticCoinContractAddresses.linkAddressMatic.toUpperCase()
      ) {
        return this.supportedCurrencies['LINKMATIC'];
      } else if (
        address.toUpperCase() ==
        environment.maticCoinContractAddresses.wbtcAddressMatic.toUpperCase()
      ) {
        return this.supportedCurrencies['WBTCMATIC'];
      }
    } else if (network.toUpperCase() == 'BSC') {
      if (
        address.toUpperCase() ==
        environment.bscCoinContractAddresses.digiBSCAddress.toUpperCase()
      ) {
        return this.supportedCurrencies['DIGIBSC'];
      } else if (
        address.toUpperCase() ==
        environment.bscCoinContractAddresses.busdBSCAddress.toUpperCase()
      ) {
        return this.supportedCurrencies['BUSDBSC'];
      } else if (
        address.toUpperCase() ==
        environment.bscCoinContractAddresses.wbnbBSCAddress.toUpperCase()
      ) {
        return this.supportedCurrencies['WBNBBSC'];
      }
    }
  }

  getRoundedDisplayPrice(network: string, addressCurrency: string, rawHumanPrice: number) {
    var roundPlaces = this.getCoinDetails(
      addressCurrency,
      network
    ).displayRoundDecimals;
    if (!roundPlaces) { roundPlaces = 2 };
    return rawHumanPrice.toFixed(roundPlaces);
  }

  getSupportedCurrencyDropDownByNetwork(network: string) {
    var currencyDropDown = [];
    for (let key in this.supportedCurrencies) {
      let ERC20currency = this.supportedCurrencies[key];
      if (ERC20currency.network != network) continue;

      currencyDropDown.push({
        name: ERC20currency.displayName,
        id: ERC20currency.contractAddress,
      });
    }
    return currencyDropDown;
  }

  // ### REALTIME PRICING

  dataSourcePrefix = "https://api.coincap.io/v2/assets/"



}
