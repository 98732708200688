import { Component, OnInit } from '@angular/core';
import { OffchainService } from 'src/app/services/offchain.service';
import {MongoDBService} from 'src/app/services/mongo-dbservice.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {


  password = '';
  correctPassword = '123';
  isPasswordCorrect = false;
  vins:VinNftType[] =[];
  owners =["TEST1","TEST2"];
  usernames:string[]=[];
 modalVisible = false;
  modalIndex: number;
  selectedOwner: string;
  customOwner: string;


  

  constructor(
    private readonly offchain: OffchainService,
    private readonly mongo:MongoDBService,
  ) { }

  ngOnInit(): void {
  }

  checkPassword() {
    if (this.password === this.correctPassword) {
      this.isPasswordCorrect = true;
      this.loadData();

    } else {
      this.isPasswordCorrect = false;
    }
  }

  async loadData(){

    this.vins = await this.offchain.getAllNfts();
    console.log("Vins", this.vins);
    
    this.vins.forEach(async element => {
      if(element.owner) {
        var un = await this.offchain.getUsernameById(element.owner)
        this.usernames.push(un);}
        else {
          this.usernames.push('n/a');
        }
    });
   
  
this.getOwners();
    
  }

  openModal(index: number) {
    this.modalVisible = true;
    this.modalIndex = index;
  }

  closeModal() {
    this.modalVisible = false;
    this.modalIndex = -1;
    this.selectedOwner = "";
    this.customOwner = "";
    
  }
  changeOwner(index: number){



    this.closeModal();
  }

  async getOwners(){

    console.log("ATTEMPTING TO GET OWNERS");
    
   var a = await this.mongo.selectData("vinnftprofiles");
  console.log("OWNERS" ,a);
  var b  = await this.mongo.getTables();
  console.log("Tables", b);
  
  

   
  }

  createNewUser(email:string, username:string, password:string){
    this.offchain.createProfile(email, username, password);
  }
}
