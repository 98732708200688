<div class="predictive-text-container">
    <input 
      type="text" 
      [formControl]="searchInput" 
      (input)="onSearchChange()"
      placeholder="Start typing make or model"
      aria-label="Search make or model"
      class="predictive-text-input"
      maxlength="100"
    />
    <ul *ngIf="showDropdown && (suggestions$ | async)?.length > 0" class="suggestions-list" role="listbox" aria-label="Year">
      <li *ngFor="let suggestion of (suggestions$ | async)" (click)="selectSuggestion(suggestion)" role="option" class="suggestion-item">
        {{ suggestion }}
      </li>
    </ul>
  </div>
  