import { Component, Input, OnInit } from '@angular/core';
import { ILastSales } from 'src/assets/data/card-data';

@Component({
  selector: 'app-history-table',
  templateUrl: './history-table.component.html',
  styleUrls: ['./history-table.component.scss'],
})
export class HistoryTableComponent implements OnInit {
  @Input() RowsData!: ILastSales[];
  constructor() {}

  ngOnInit(): void {}
}
