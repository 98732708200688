<div class="mt-8">
  <form
    #f="ngForm"
    (ngSubmit)="onClickSubmit(f.value)"
    class="grid grid-cols-1 md:grid-cols-5 items-center gap-x-8"
  >
    <div class="formGroup col-span-2">
      <label>Name</label>
      <input
        ngModel
        type="text"
        id="name"
        required
        [value]="formData.name"
        name="name"
        placeholder="Your Name"
      />
    </div>
    <div class="formGroup col-span-2">
      <label>Comment</label>
      <textarea
        ngModel
        type="text"
        rows="1"
        id="comment"
        required
        [value]="formData.comment"
        name="comment"
        placeholder="Your Comment"
      ></textarea>
    </div>
    <div>
      <button type="submit" class="writeButton">Comment</button>
    </div>
  </form>
  <div *ngIf="commentsData.length <= 0">
    <div class="text-center text-gray-700 text-lg">No Comments</div>
  </div>
  <div *ngIf="commentsData.length > 0">
    <div *ngFor="let item of commentsData">
      <div class="grid grid-cols-6 items-center justify-center">
        <div>
          <img
            src="https://i.imgur.com/IyEp7mf.jpeg"
            class="rounded-full h-20 w-20 object-cover"
            alt=""
          />
        </div>
        <div class="shadow-lg col-span-5 px-4 py-8">
          <h3 class="text-xl font-semibold">{{ item.name }}</h3>
          <p class="text-gray-400 text-sm">{{ item.date | dateAsAgo }}</p>
          <p class="my-2 text-justify">{{ item.comment }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
