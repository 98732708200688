import { environment } from "src/environments/environment";
export interface IHowItWorksData {
  title: string;
  text: string;
  link: string;
}
export const HowItWorksData: IHowItWorksData[] = [
  {
    title: 'Choose a Package',
    text:  "For $5 VINnft offers an Ethusiast level package to track your vehicle's history. Additional packages are available depending on your automotive ethusiasm and needs. You may also certify your VINnft by providing proof of ownership and CARFAX&#174;.",
    link: '/pricing',
  },
  {
    title: 'VINnft Details',
    text: 'Provide a photo of your ride, VIN, optional proof of ownership, a brief description of the vehicle and what makes it special. Other options include the ability to store and update service records and historical documents.',
    link: '',
  },
  {
    title: 'VINnft Creation',
    text: "VINnft will mint your VINnft and deliver it with instructions on how to hold your VINnft in your digital wallet and update your VINnft as your vehicle's history evolves.",
    link: '',
  },
  {
    title: 'After VINnft is Created',
    text: 'Refer back to the VINnft to access photos and media, records, registrations, important documents, and social commentary. Use the VINnft as a tool to market and showcase your vehicle.',
    link: '',
  },
];
