import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  constructor(
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    var referralCode;
    this.activatedRoute.queryParamMap.subscribe((params) => {
       referralCode = params.get('refCode');

      if (referralCode) {
        this.cookieService.set('referrer_discount_code', referralCode, 30); // Cookie will expire in 30 days
      }
    });
    console.log("Layout got ref Code ", referralCode);
    
  }
}
