import { Injectable } from '@angular/core';
import { OffchainService } from './offchain.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthService {
  public isLogin: boolean = false;
  public curUser: any = null;
  constructor(
    private readonly offchain: OffchainService,
    private readonly toastr: ToastrService
  ) {
  }

  async checkUser() {
    let tmp: any = localStorage.getItem('vinnft-user');
    if (!tmp) return;
    tmp = JSON.parse(tmp);
    try {
      const user = await this.offchain.checkUser(tmp.email, tmp.hash);
      if (user) {
        this.curUser = user;
        this.isLogin = true;
        console.log('signed in', user);
      }
    } catch (error) {
      console.log(error, 'check User error');
    }
  }

  async loginUser(email: string, password: string) {
    const user = await this.offchain.loginUser(email, password);
    if (user) {
      localStorage.setItem('vinnft-user', JSON.stringify({
        email: user.email,
        hash: user.hash
      }));
      this.curUser = user;
      this.isLogin = true;
    }
  }

  async createUser(email: string, username: string, password: string) {
    const user = await this.offchain.createProfile(email, username, password);
    console.log(user, 'created User');
    localStorage.setItem('vinnft-user', JSON.stringify({
      email: user.email,
      hash: user.password
    }));
    this.curUser = user;
    this.isLogin = true;
  }

  logout() {
    localStorage.removeItem('vinnft-user');
    this.isLogin = false;
    this.curUser = null;
  }
}
