import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'src/app/components/_modal';
import { VinNFTService } from 'src/app/services/vinNFT.service';
import { WalletService } from 'src/app/services/wallet.service';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
} from '@angular/forms';
import { MathService } from 'src/app/services/math.service';
import { OffchainService } from 'src/app/services/offchain.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { VindataserviceService } from 'src/app/services/vindataservice.service';
import { AuthService } from 'src/app/services/auth.service';
import mergeImages from '../../utils/myMergeImages';
import { AngularStripeService } from '@fireflysemantics/angular-stripe-service';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss'],
})
export class UpdateComponent implements OnInit {
  nft_image_content: any;
  uploadData: any = {
    nft_image: null,
    ext_record: [],
    service_record: [],
    ownership: [],
  };
  ipfsExtension: any = {
    nft_image: '',
    ext_record: [],
    service_record: [],
    ownership: [],
  };
  miles: string = '';
  color: string = '';
  interiorColor: string = '';
  motor: string = '';
  transmission: string = '';
  vin: string = '';
  acquisitionDescription: string = '';
  specialDescription: string = '';
  listForSale: boolean = false;
  price: string = '';
  selectedPackage: string = '';
  isForSale: boolean = true;
  year: string = '';
  model: string = '';
  loading: boolean = false;
  name = '';
  description = '';
  links: string = '';
  isVideo: boolean = false;
  traitsArray: any;
  addAttributesForm: any;
  vinSn: string = '';
  curPassword: string = '';
  mintFee: number = 0;
  allowedAmount: number = 0;
  isAdmin: boolean = false;
  _minter: string | null = null;
  _receiver: string = '';
  _pricePlan: string = 'enthusiast';
  showWeb3Payment: boolean = false;
  planList: any = {
    enthusiast: {
      name: 'VIN-Tracker $0',
      additionalCnt: 25,
    },
    professional: {
      name: 'VIN-Tracker Pro $10',
      additionalCnt: 250,
    },
    // enthusiast: {
    //   name: 'VIN-Tracker $5',
    //   additionalCnt: 5,
    // },
    // collector: {
    //   name: 'VIN-Tracker + Digital Storage $10',
    //   additionalCnt: 23,
    // },
    // professional: {
    //   name: 'VIN-Tracker Pro + CARFAX&#174; $50',
    //   additionalCnt: 100,
    // },

  };
  additionalMediaCount: number = 0;
  currentMediaCount: number = 0;
  additionMedias: any[] = [];
  additionMediaFiles: any[] = [];
  mintWay: string = '';
  img_ipfs_uri: string;
  token_ipfs_uri: string;
  token_metadata: string;
  token_private_ipfs_uri: string = '';
  token_private_metadata: string = '';

  wyre_orderId: string;
  wyre_transferId: string;
  decodedMake: string;

  uploadType: string = 'ext_record';
  tmpUploadFile: File;
  tmpDataType: string;
  docType: any = {
    ext_record: [
      {
        name: 'Build sheet',
      },
      {
        name: 'Warranty card',
      },
      {
        name: 'CARFAX report',
      },
      {
        name: 'Sales brochure',
      },
    ],
    service_record: [
      {
        name: 'Service record',
      },
      {
        name: 'Parts receipt',
      },
      {
        name: 'Mileage log',
      },
    ],
    ownership: [
      {
        name: 'Title',
      },
      {
        name: 'Registration',
      },
      {
        name: 'Bill of Sale',
      },
    ],
  };
  @ViewChild('cardInfo', { static: false }) cardInfo: ElementRef;
  stripeError: any;
  stripe: any;
  card: any;
  cardHandler = this.onStripeChange.bind(this);
  stripeProcessing: boolean = false;

  _updateId: number = 0;
  _tokenData: VinNftType;
  noAccess: boolean = false;
  formOldPassword: string = '';
  formNewPassword: string = '';
  formNewConfirmPassword: string = '';
  isUpdatingPassword: boolean = false;

  selectedOptions = {};
  otherDescription: string = '';
  constructor(
    private modalService: ModalService,
    private fb: FormBuilder,
    private wallet: WalletService,
    private vinnft: VinNFTService,
    private readonly math: MathService,
    private readonly toastr: ToastrService,
    private readonly route: ActivatedRoute,
    private readonly offchain: OffchainService,
    private readonly vinData: VindataserviceService,
    public readonly authService: AuthService,
    private stripeService: AngularStripeService,
    private router: Router
  ) { }

  async ngOnInit() {
    this.route.params.subscribe((queryParams) => {
      this._updateId = queryParams['id'];
    });

    await this.loadTokenData();
    this.loadFeeTokenData();
  }

  async loadTokenData() {
    this._tokenData = await this.offchain.getNftById(this._updateId);
    console.log(this._tokenData, 'token Data of update nft');
    const walletAddress = await this.wallet.getAccount();
    if (
      this._tokenData.owner?.toLowerCase() !== walletAddress?.toLowerCase() &&
      this._tokenData.owner?.toLowerCase() !==
      this.authService.curUser?._id?.toLowerCase()
    )
      this.noAccess = true;

    // Set Token Data
    const tmpAttr: any = [];
    this._tokenData.attributes?.map((item: any) =>
      tmpAttr.push(this.createTraitInput(item.trait_type, item.value))
    );
    this.addAttributesForm = this.fb.group({
      // attributes: this.fb.array([this.initialValue], Validators.required),
      attributes: this.fb.array(tmpAttr, Validators.required),
    });
    this.setTraits();
    this._receiver = this._tokenData.owner ?? '';
    this.name = this._tokenData.name;
    this.description = this._tokenData.description;
    this.links = this._tokenData.links ? this._tokenData.links : '';
    this.vinSn = this._tokenData.vinSn;
    this._pricePlan = this._tokenData.package ?? 'enthusiast';
    this.changedPricePlan();
    this.nft_image_content = this.img_ipfs_uri = this._tokenData.image;
    this.currentMediaCount = this._tokenData.extras?.length ?? 0;
    this.miles = this._tokenData.miles;
    this.color = this._tokenData.color;
    this.interiorColor = this._tokenData.interiorColor;
    this.motor = this._tokenData.motor;
    this.transmission = this._tokenData.transmission;
    this.vin = this._tokenData.vin;
    this.selectedOptions = this._tokenData.selectedOptions;
    this.otherDescription = this._tokenData.otherDescription;
    this.acquisitionDescription = this._tokenData.acquisitionDescription;
    this.specialDescription = this._tokenData.specialDescription;
    this.isForSale = this._tokenData.isForSale;
    this.price = this._tokenData.price;
    if (this.currentMediaCount > 0)
      this.additionMedias = this._tokenData.extras;
    let recordData;
    if (this._tokenData.hasPassword) {
      recordData = await this.offchain.getPrivateDataById(
        this._updateId
      );
      console.log(recordData, 'privateData');
      this.curPassword = recordData?.password ?? '';
      recordData = JSON.parse(recordData?.private_token_metadata);
    } else {
      recordData = this._tokenData.records;
    }
    this.uploadData = {
      ...recordData,
      nft_image: null,
    };
  }

  onStripeChange(error: any) {
    console.log(error, this, 'stripe change erorr');
    if (error.error) {
      this.stripeError = error.error.message;
    } else {
      this.stripeError = null;
    }
  }

  async openAttributeForm() {
    this.openModal('vehicle-detail-modal');
  }

  // async onSubmit() {
  //   this.stripeProcessing = true;
  //   this.stripeError = null;
  //   try {
  //     const intent = await this.offchain.stripeCreateIntent(this.mintFee);
  //     const result = await this.stripe.confirmCardPayment(
  //       intent.client_secret,
  //       {
  //         payment_method: {
  //           card: this.card,
  //         },
  //       }
  //     );
  //     console.log(result, 'stripe confirm');
  //     if (result.error) {
  //       this.stripeError = result.error.message;
  //       throw Error;
  //     }
  //     if (result.paymentIntent?.status === 'succeeded') {
  //       this.toastr.success(
  //         'Success! Your payment have been processed successfully! Please wait while VIN-Tracker information is synchronised.'
  //       );
  //       this.closeModal('stripe-checkout-modal');
  //       this.loading = true;
  //       await this.uploadToIpfs();
  //       await this.offchain.primaryUpdate({
  //         tokenUri: this.token_ipfs_uri,
  //         tokenPrivateUri: this.token_private_ipfs_uri,
  //         token_id: this._updateId,
  //         id: result.paymentIntent.id,
  //       });
  //       await this.offchain.updateNft(
  //         this._updateId,
  //         this._receiver,
  //         this.token_ipfs_uri,
  //         this.token_metadata,
  //         this.token_private_metadata,
  //       );
  //       this.toastr.success('Successfully updated');
  //       this.loading = false;
  //       this.router.navigate(['/']);
  //     }
  //   } catch (error) {
  //     console.log(error, 'stripe confirm error');
  //     this.toastr.error('Stripe Error');
  //   }
  //   this.stripeProcessing = false;
  // }

  // case update fee is 0
  async onSubmit() {
    this.loading = true;
    try{
      await this.uploadToIpfs();
      // await this.offchain.primaryUpdate({
      //   tokenUri: this.token_ipfs_uri,
      //   tokenPrivateUri: this.token_private_ipfs_uri,
      //   token_id: this._updateId,
      //   id: result.paymentIntent.id,
      // });
      await this.offchain.updateNft(
        this._updateId,
        this._receiver,
        this.token_ipfs_uri,
        this.token_metadata,
        this.token_private_metadata,
      );
      this.toastr.success('Successfully updated');
      this.loading = false;
      this.router.navigate(['/']);
    } catch (error) {
      console.log('Error happened -->', error);
      this.loading = false;
    }
  }


  async loadFeeTokenData() {
    this.mintFee = environment.updateFee;
    this.allowedAmount = this.math.toHumanValue(
      await this.vinnft.allowedToken()
    );
  }

  changedPricePlan() {
    this.additionalMediaCount = this.planList[this._pricePlan].additionalCnt;
    this.additionMedias.splice(this.additionalMediaCount, 100);
    this.additionMediaFiles.splice(this.additionalMediaCount, 100);
    if (this.currentMediaCount > this.additionalMediaCount)
      this.currentMediaCount = this.additionalMediaCount;
  }

  validateMint(): boolean {
    // if (!this._minter) {
    //   this.toastr.error('Please connect your wallet first', 'Wallet');
    //   return false;
    // }
    if (!this.nft_image_content) {
      this.toastr.error('Please upload NFT image.', 'Error');
      return false;
    }
    if (!this.name || this.name === '') {
      this.toastr.error('Please enter the name', 'Error');
      return false;
    }
    if (!this.description || this.description === '') {
      this.toastr.error('Please enter the description', 'Error');
      return false;
    }
    // if (!this.vinSn || this.vinSn === '' || this.vinSn.length != 17) {
    //   this.toastr.error('Please enter the 17-character VIN', 'Error');
    //   return false;
    // }
    // #### NO MORE REQUIREMENT FOR HAVING AT LEAST ONE ATTRIBUTE
    // if (this.addAttributesForm.status !== 'VALID') {
    //   this.toastr.error('Please fill-in the vehicle details.', 'Error');
    //   return false;
    // }
    return true;
  }

  async decodeMake() {
    this.decodedMake = await this.vinData.getMake(
      this.vinSn.slice(0, 3).toLocaleUpperCase()
    );
    if (this.decodedMake !== '') {
      this.addAttributesForm.get('attributes')['controls'][0].setValue({
        trait_type: 'Make',
        value: this.decodedMake,
      });
    }
  }

  async approveToken() {
    this.loading = true;
    try {
      const aprovAmount = this.math.toBlockchainValue(this.mintFee);
      await this.vinnft.approveToken(aprovAmount);
      await this.loadFeeTokenData();
      this.toastr.success('Approved!');
    } catch (error: any) {
      console.log('approve err', error);
      this.toastr.error(error?.message ?? 'Something went wrong');
    }
    this.loading = false;
  }

  async openMintModal() {
    if (!this.validateMint()) return;
    this.openModal('select-mint-mode-modal');
  }

  async mintPrimary() {
    if (!this.authService.isLogin) {
      this.toastr.warning('Please login first to mint NFT');
      this.modalService.open('login-modal');
      return;
    }
    const stripeInstance = await this.stripeService.setPublishableKey(environment.stripeInfo.PUBLISHABLE_KEY);
    this.closeModal('select-mint-mode-modal');
    this.stripe = stripeInstance;
    const elements = await stripeInstance.elements();
    this.card = await elements.create('card');
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);
    this.openModal('stripe-checkout-modal');
  }

  async mergeTwo() {
    const nftImage = new Image();
    nftImage.onload = () => {
      const badgeWidth = nftImage.width * 0.3;
      const badgeHeight = badgeWidth / 5;
      //console.log("img sizes ", nftImage.width, badgeWidth, badgeHeight);

      mergeImages(
        [
          {
            src: this.nft_image_content,
            x: 0,
            y: 0,
          },
          {
            src: '../../../assets/images/vin-track-new.png',
            x: 50,
            y: 50,
            width: badgeWidth,
            height: badgeHeight,
          },
        ],
        {
          quality: 0.5,
          format:
            this.ipfsExtension.nft_image === 'png' ? 'image/png' : 'image/jpeg',
        }
      ).then((b64: any) => {
        this.nft_image_content = b64;
      });
    };

    nftImage.src = this.nft_image_content;
  }

  async uploadToIpfs(): Promise<void> {
    try {
      // Upload NFT Image to ipfs
      if (this.uploadData.nft_image) {
        const nftBlob = await (await fetch(this.nft_image_content)).blob();
        const file = new File([nftBlob], 'File name', { type: 'image/png' });
        let filePath = 'images/' + Math.random() * 10000000000000000 + '_' + this.name;
        let S3BucketImage = await this.offchain.uploadToS3Service(file, filePath);
        this.img_ipfs_uri = S3BucketImage.Location;
      }
      // Upload Extra medias
      const extras = [];
      for (let idx = 0; idx < this.additionMedias.length; idx++) {
        const _media = this.additionMedias[idx];
        let extra_ipfs_uri = _media.content;
        if (this.additionMediaFiles[idx]){
          let filePath1 = 'images/' + Math.random() * 10000000000000000;
          let extraS3BucketImage = await this.offchain.uploadToS3Service(
            this.additionMediaFiles[idx],
            filePath1 + `${this.name}_additional_${idx + 1}`
          );
          extra_ipfs_uri = extraS3BucketImage.Location;
        }
        extras.push({
          content: extra_ipfs_uri,
          isVideo: _media.isVideo,
        });
      }

      const tokenUri: VinNftType = {
        name: this.name,
        links: this.links,
        image: this.img_ipfs_uri,
        attributes: this.traitsArray,
        vinSn: this.vinSn,
        isVideo: this.isVideo,
        package: this._pricePlan,
        motor: this.motor,
        transmission: this.transmission,
        vin: this.vin,
        selectedOptions: this.selectedOptions,
        otherDescription: this.otherDescription,
        acquisitionDescription: this.acquisitionDescription,
        specialDescription: this.specialDescription,
        isForSale: this.isForSale,
        price: this.price,
        year: this.year,
        model: this.model,
        extras,
        miles: this.miles,
        color: this.color,
        interiorColor: this.interiorColor,
        description: this.description
      };

      const tokenRecord: any = {
        ext_record: [],
        service_record: [],
        ownership: [],
      };

      // Check Record Documents
      if (this.uploadData.ext_record.length > 0) {
        // Save External Record to token uri
        for (let idx = 0; idx < this.uploadData.ext_record.length; idx++) {
          const extItem = this.uploadData.ext_record[idx];
          let record_ipfs_url;
          if (extItem.file){
            let filePath1 = 'images/' + Math.random() * 10000000000000000;
            record_ipfs_url = await this.offchain.uploadToS3Service(
              extItem.file,
              filePath1 + `${this.name}_externalRecord_${idx + 1}`
            );
            record_ipfs_url = record_ipfs_url.Location;
          }
          else record_ipfs_url = extItem.url;
          tokenRecord.ext_record.push({
            url: record_ipfs_url,
            type: extItem.type,
          });
        }
      }
      console.log(tokenUri, tokenRecord);
      if (this.uploadData.service_record.length > 0) {
        // Save Service Record to token uri
        for (let idx = 0; idx < this.uploadData.service_record.length; idx++) {
          const serviceItem = this.uploadData.service_record[idx];
          let record_ipfs_url;
          if (serviceItem.file){
            let filePath1 = 'images/' + Math.random() * 10000000000000000;
            record_ipfs_url = await this.offchain.uploadToS3Service(
              serviceItem.file,
              filePath1 + `${this.name}_serviceRecord_${idx + 1}`
            );
            record_ipfs_url = record_ipfs_url.Location;
          }
          else record_ipfs_url = serviceItem.url;
          tokenRecord.service_record.push({
            url: record_ipfs_url,
            type: serviceItem.type,
          });
        }
      }
      if (this.uploadData.ownership.length > 0) {
        // Save Service Record to token uri
        for (let idx = 0; idx < this.uploadData.ownership.length; idx++) {
          const ownershipItem = this.uploadData.ownership[idx];
          let record_ipfs_url;
          if (ownershipItem.file){
            let filePath1 = 'images/' + Math.random() * 10000000000000000;
            record_ipfs_url = await this.offchain.uploadToS3Service(
              ownershipItem.file,
              filePath1 + `${this.name}_ownershipRecord_${idx + 1}`
            );
            record_ipfs_url = record_ipfs_url.Location;
          }
          else record_ipfs_url = ownershipItem.url;
          tokenRecord.ownership.push({
            url: record_ipfs_url,
            type: ownershipItem.type,
          });
        }
      }

      if (this.curPassword && this.curPassword.length > 0) {
        this.token_private_metadata = JSON.stringify(tokenRecord);
        this.token_private_ipfs_uri = await this.offchain.uploadJsonToHeroku(
          tokenRecord
        );
      } else {
        tokenUri.records = tokenRecord;
      }
      this.token_metadata = JSON.stringify(tokenUri);
      console.log(tokenUri, tokenRecord);
      this.token_ipfs_uri = await this.offchain.uploadJsonToHeroku(tokenUri);
      console.log(this.token_ipfs_uri, this.token_private_ipfs_uri);
    } catch (error: any) {
      this.toastr.error(error?.message, 'Error');
      console.log('Upload To Ipfs error: ', error);
    }
  }

  async updateVin(): Promise<void> {
    this.closeModal('select-mint-mode-modal');
    this.loading = true;

    await this.uploadToIpfs();
    try {
      await this.vinnft.updateVinUri(
        this._updateId,
        this.token_ipfs_uri,
        this.token_private_ipfs_uri,
        this._receiver,
        this.token_metadata,
        this.token_private_metadata,
      );
      this.toastr.success('Updated VIN-Tracker successfully.');
      this.router.navigate(['/']);
    } catch (error: any) {
      this.toastr.error(error?.message, 'Error');
      console.log('Update VIN-Tracker error: ', error);
    }
    this.loading = false;
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
    // if (id == 'upload-external-modal') {
    //   const element = document.querySelector('#vault');
    //   if (element) { element.scrollIntoView(); }

    // } else if (id == 'vehicle-detail-modal') {
    //   const element = document.querySelector('#details');
    //   if (element) { element.scrollIntoView(); }
    // }
  }

  async onFileChanged(event: any, type = 'nft_image') {
    if (!event.target.files[0] || event.target.files[0].length == 0) {
      return;
    }

    if (type === 'nft_image') {
      this.uploadData[type] = event.target.files[0];
      const fileType = this.checkIsVideo(this.uploadData[type].name, type);
      this.isVideo = fileType === 2;
      var reader = new FileReader();
      reader.readAsDataURL(this.uploadData[type]);

      reader.onload = (_event) => {
        this.nft_image_content = reader.result;
        if (!this.isVideo) this.mergeTwo();
      };
    } else {
      this.uploadType = type;
      this.tmpDataType = '';
      this.tmpUploadFile = event.target.files[0];
      this.openModal('upload-external-modal');
    }
  }

  getUploadTypeText() {
    if (this.uploadType === 'ext_record') return 'External Record';
    if (this.uploadType === 'service_record') return 'Service Record';
    if (this.uploadType === 'ownership') return 'Ownership Document';
    return '';
  }

  confirmUpload() {
    this.uploadData[this.uploadType].push({
      file: this.tmpUploadFile,
      type: this.tmpDataType,
    });
    this.closeModal('upload-external-modal');
  }

  addExtraMediaFile(extraFile: any) {
    const fileType = this.checkIsVideo(extraFile.name, '');

    var reader = new FileReader();
    reader.readAsDataURL(extraFile);
    reader.onload = (_event) => {
      this.additionMediaFiles[this.currentMediaCount] = extraFile;
      this.additionMedias[this.currentMediaCount++] = {
        content: reader.result,
        isVideo: fileType === 2,
      };
    };
  }

  async addExtraFile(event: any) {
    if (!event.target.files[0] || event.target.files[0].length == 0) {
      return;
    }
    const extraLen = this._tokenData.extras? this._tokenData.extras.length : 0;
    console.log(extraLen, '---> extra length')
    if(event.target.files.length + this.additionMediaFiles.length - extraLen > 10){
      this.toastr.warning('Upload limit is 10 per time, please update your Vin-Tracker to add more.');
      return;
    }
    const videoCnt = [];
    for (let idx = 0; idx < event.target.files.length; idx++) {
      const element = event.target.files[idx];
      const fileType = this.checkIsVideo(element.name, '');
      if(fileType == 2) videoCnt.push(fileType);
    }
    if (videoCnt.length > 1) {
      this.toastr.warning('You can upload 1 video per time.');
      return;
    }

    for (let idx = 0; idx < event.target.files.length; idx++) {
      const element = event.target.files[idx];
      await this.addExtraMediaFile(element);
    }
  }


  closeExtra(_index: number) {
    this.currentMediaCount--;
    this.additionMedias.splice(_index, 1);
    this.additionMediaFiles.splice(_index, 1);
  }

  closeRecord(_index: number, _type: string) {
    this.uploadData[_type].splice(_index, 1);
    console.log(this.uploadData, _index, _type);
  }

  checkIsVideo(fileName: string, type: string) {
    const allowedImageFiles = ['.png', '.jpg', '.jpeg', '.gif'];
    const allowedVideoFiles = ['.mp4', '.mov', '.wmv', '.avi'];
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName.toLowerCase());
    if (undefined !== extension && null !== extension) {
      this.ipfsExtension[type] = extension[0];
      for (const ext of allowedImageFiles) {
        if (ext === extension[0]) return 1; // Image
      }
      for (const ext of allowedVideoFiles) {
        if (ext === extension[0]) return 2; // Video
      }
    }
    return 0;
  }

  getFileType(fileName: string) {
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName);
    if (undefined !== extension && null !== extension) {
      return extension[0];
    }
    return 'unknown';
  }

  setTraits() {
    // GET TRAITS DATA AND SUBMIT HERE
    if (this.addAttributesForm.status == 'VALID') {
      this.traitsArray = this.addAttributesForm.value.attributes;
    }
  }

  get attributes(): FormArray {
    return <FormArray>this.addAttributesForm?.get('attributes');
  }

  removeTrait(data: any) {
    if (this.attributes.length !== 1) this.attributes.removeAt(data);
  }
  addTrait() {
    // if (this.attributes.length !== 11)
    this.attributes.push(this.createTraitInput('', ''));
  }

  createTraitInput(type: string, value: string): FormGroup {
    return this.fb.group({
      trait_type: [type, Validators.required],
      value: [value, Validators.required],
    });
  }

  async toggleWeb3() {
    const walletAddress = await this.wallet.getAccount();
    if (!walletAddress) {
      // No wallet connected
      await this.wallet.connectWallet();
    }
    const network = await this.wallet.getNetwork();
    if (network === 'UNSUPPORTED') await this.wallet.switchToMatic();
    this.showWeb3Payment = !this.showWeb3Payment;
  }

  async updatePassword() {
    if (this.formOldPassword !== this.curPassword) {
      this.toastr.error('Current password is not correct');
      return;
    }
    if (this.formNewPassword !== this.formNewConfirmPassword) {
      this.toastr.error('Password does not match');
      return;
    }
    this.isUpdatingPassword = true;
    try {
      await this.offchain.updatePassword(this._updateId, this.formNewPassword);
      this.toastr.success('Updated password successfully');
      this.closeModal('update-password-modal');
    } catch (error) {
      console.log(error, 'update password');
      this.toastr.error('Something went wrong. Please try again later...');
    }
    this.isUpdatingPassword = false;
  }
}
