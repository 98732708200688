<div class="pricing-container">
  <div class="plan free plan-free">
    <span class="raceFont3 plan-logo">
      <img src="../../../assets/images/vin-track-new.png" width="200px" />
    </span>

    <h2 class="plan-title">Enthusiast Version</h2>
    <p class="plan-price">Free</p> <!-- Moved the price to a separate element for better balance -->
    <h3 class="plan-subtitle">Streamline Your Car's Story</h3>
    <ul class="afont">
      <li class="icon">Upload and manage up to 25 media files (photos or videos) with ease</li>
      <li class="icon">Share your vehicle's unique history through a single, accessible link</li>
      <li class="icon">Enjoy a clutter-free experience, whether you're selling or just staying organized</li>
      <li class="icon">Simplify your life by consolidating your vehicle's digital footprint</li>
      <li class="icon no-data-vault">No Secure Data Vault for sharing important documents</li>
      <li class="icon">Free</li>

    </ul>
    <a href="/create/enthusiast" class="cta-btn free-btn">Get Started For Free</a>
  </div>
  <div class="plan pro plan-pro">
    <span class="raceFont3 plan-logo">
      <img src="../../../assets/images/vin-track-new.png" width="200px" />
    </span>
    <h2 class="plan-title">Professional Version</h2>
    <p class="plan-price">${{priceProfessional}} first year <br /> $0.99/mo thereafter.</p>
    <!-- Moved the price to a separate element for better balance -->
    <h3 class="plan-subtitle">Sell Your Car with Confidence</h3>
    <ul class="afont">
      <li class="icon">Store, update, and share up to 250 media files (photos or videos) in real-time with a single link
      </li>
      <li class="icon">Secure Data Vault: Share essential documents like title and registration with authorized
        individuals only</li>
      <li class="icon">Vehicle data is on the blockchain for unparalleled security and trust</li>
      <li class="icon">Effortlessly transfer your Vin-Tracker and all vehicle history to the buyer upon sale</li>
      <li class="icon">Simplify and accelerate the sales process with a comprehensive, easy-to-use platform</li>
      <li class="icon">Increase your vehicle's value by showcasing its history and maintenance records</li>
      <li class="icon">Eliminate the need for multiple platforms (Dropbox, Instagram, YouTube, auction sites) with a
        single, all-in-one solution</li>
      <li class="icon"> ${{priceProfessional}} for first year. $0.99/mo thereafter</li>
    </ul>
    <a href="/create/professional" class="cta-btn pro-btn">Unlock Pro Features</a>
  </div>
</div>