import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-start-vin-button',
  templateUrl: './start-vin-button.component.html',
  styleUrls: ['./start-vin-button.component.scss']
})
export class StartVinButtonComponent implements OnInit {

  @Input() profile?:string
  constructor() { }

  
  ngOnInit(): void {
  }

  
}
