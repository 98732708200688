<!-- Header Section -->
<header [ngClass]="headerClass">
  <!-- Hamburger Menu for Mobile -->
  <div class="menu-toggle text-white text-center hamburger md:w-[200px]" id="hamburger" (click)="OpenNav()">
    <i [ngClass]="iconClass"></i>
  </div>

  <!-- Logo and Branding -->
  <div class="container text-center bg-vin-blue py-0 px-1 md:py-2 sm:px-4 md:px-12">
    <h6 class="brand raceFont3">
      <a routerLink="/">
        <img src="../../../assets/images/vin-track-new.png" class="mx-auto" width="550px" />
      </a>
    </h6>
  </div>

</header>

<!-- Overlay Class for Styling -->
<div [ngClass]="overlayClass"></div>


<!-- Navigation Menu -->
<nav [ngClass]="navClass">
  <ul class="flex items-center raceFont3">
    <!-- Static Navigation Links -->
    <!-- Example Static Link -->
    <li class="animate-li">
      <a class="nav-link" (click)="CloseNav()" routerLink="/explorer">Search Database</a>
    </li>
    <!-- Add more static links as needed -->

    <!-- Conditional Navigation Links -->
    <!-- My Garage Link - Visible if User is Logged in with a Web3 Wallet Address -->
    <li class="animate-li" *ngIf="address">
      <a class="nav-link" (click)="CloseNav()" [routerLink]="'/profile/' + address">My Garage</a>
    </li>

    <!-- My Garage Link - Visible if User is Logged in Normally (without Web3 Wallet Address) -->
    <li class="animate-li" *ngIf="!address && auth.isLogin">
      <a class="nav-link" (click)="CloseNav()" [routerLink]="'/profile/' + auth.curUser._id">My Garage</a>
    </li>

    <!-- Login Link - Visible if User is Not Logged In -->
    <li class="animate-li" *ngIf="!auth.isLogin">
      <a class="nav-link" (click)="CloseNav(); openLogin()">Login</a>
    </li>

    <!-- Connect Web3 Wallet Link - Visible if User is Not Using Web3 Authentication -->
    <li class="animate-li" *ngIf="!address">
      <a class="nav-link" (click)="CloseNav(); connectWallet()">Connect Web3</a>
    </li>

      <!-- contact -->
      <li class="animate-li">
        <a class="nav-link" (click)="CloseNav()" routerLink="/contact">Contact Us</a>
      </li>

    <!-- Logout Link - Visible if User is Logged In -->
    <li class="animate-li" *ngIf="auth.isLogin">
      <a class="nav-link" (click)="CloseNav(); logout()">Logout</a>
    </li>

  
  </ul>
</nav>

