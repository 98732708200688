import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AiService {

  constructor(
  private http: HttpClient


  ) { }


  public async callApi_GetDescription(inputText:string){


    const requestData = {
      ProductID: environment.ai_productID,
      ClientID: environment.ai_clientID,
      InputText: JSON.stringify(inputText)
    };
    
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    try {
      console.log('Calling API...')
      console.log('Request Data: ' + JSON.stringify(requestData))

      const response = await this.http.post(environment.ai_apiEndpoint, requestData, { headers }).toPromise();
      const result = response as any;

      if (result.result && result.success) {
        var sanitized_result = this.sanitizeResponse(result.result as string)
        return sanitized_result
      } else {
        console.error('API Sent Error Message: ', result.error);
        return "FAIL"

      }
    } catch (error) {
      console.error('Error calling API: ', error);
      return "FAIL"

    }

  }

  public sanitizeResponse(response: string): string {
    var sanitized_result = response
    try {
      if(response.includes("JSON")){
        var sanitized_result = response.replace("JSON", "Vehicle Records")
      }   
      
    } catch (error) {
      console.log('Error sanitising response: ', error);

    }
      return sanitized_result

  }

}
