import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import Web3 from 'web3';
import {env} from 'process';

declare global {
  interface Window {
    ethereum: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class WalletService {
  web3: any = null;
  chainId: number = 0;
  address: string | null = null;
  provider = null;

  constructor(private readonly toastr: ToastrService) {
    this.init();
  }

  async init() {
    if (window.ethereum) {
      this.web3 = new Web3(window.ethereum);
      this.provider = window.ethereum;
      this.chainId = await this.web3.eth.getChainId();
      await this.getAccount();
      if (!this.address) return;
      if (this.chainId !== environment.chainId) {
        
        this.toastr.warning('Your wallet is on the wrong network. Please switch network to Polygon', 'WEB3 Wallet Detected.');
                
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: this.web3.utils.toHex(environment.chainId) }]
          });
        } catch (err: any) {
            // This error code indicates that the chain has not been added to MetaMask
          if (err.code === 4902) {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainName: environment.chainName,
                  chainId: this.web3.utils.toHex(environment.chainId),
                  nativeCurrency: { name: environment.nativeCurrency_name, decimals: environment.nativeDecimals, symbol: environment.nativeSymbol },
                  rpcUrls: [environment.rpc]
                }
              ]
            });
          }
        }
     
    
      }
    } else {
     // this.toastr.error('You have not installed metamask.', 'Wallet');
    }
  }

  getWeb3(): any {
    return this.web3;
  }

  getProvider(): any {
    return this.provider;
  }

  async getNetwork(): Promise<any> {
    if (this.chainId === environment.chainId) return 'MATIC';
    return 'UNSUPPORTED';
  }

  async getAccount(): Promise<string | null> {
    if (!window.ethereum) return null;
    const accounts = await this.getWeb3().eth.getAccounts();

    if (accounts.length === 0) return null;
    this.address = accounts[0];
    return this.address;
  }

  async getGasPrice() {
    const gasPrice = await this.getWeb3().eth.getGasPrice();
    return gasPrice;
  }

  async switchToMatic(): Promise<void> {
    if (environment.testnet) {
      await this.getProvider().request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: '0x13881',
            chainName: 'Matic Testnet',
            nativeCurrency: {
              name: 'MATIC',
              symbol: 'MATIC',
              decimals: 18,
            },
            rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
          },
        ],
      });
    } else {
      await this.getProvider().request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: '0x89',
            chainName: 'Matic',
            nativeCurrency: {
              name: 'MATIC',
              symbol: 'MATIC',
              decimals: 18,
            },
            rpcUrls: ['https://rpc-mainnet.maticvigil.com/'],
          },
        ],
      });
    }
  }

  async connectWallet(): Promise<any> {
    if (!window.ethereum) {
      console.error('You have to install metamask');
      return;
    };
    try {
      // Request account access
      return new Promise((resolve, reject) => {
        this.getProvider()
          .request({ method: 'eth_requestAccounts' })
          .then((address: any) => {
            if (address !== null) {
              resolve(address[0]);
            }
          });
      });
    } catch (error) {
      // User denied account access...
      console.error('User denied account access');
    }
  }

  async disconnect() {
    await this.getProvider().request({
      method: 'eth_requestAccounts',
      params: [
        {
          eth_accounts: {},
        },
      ],
    });
  }

  isAddress(_addr: string) {
    try {
      const _checkSumAddr = this.getWeb3().utils.toChecksumAddress(_addr);
      return this.getWeb3().utils.isAddress(_checkSumAddr);
    } catch {
      return false;
    }
  }
}
