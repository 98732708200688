import { User } from './user.component';
import { ModalService } from 'src/app/components/_modal';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Moralis } from 'moralis';
import { AuthService } from './services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  user?: User;
  static myapp: any;
  userName: string = '';
  userEmail: string = '';
  userPw: string = '';
  userPwConfirm: string = '';
  isProcessing: boolean = false;
  loading: boolean = true;
  scriptElement: HTMLScriptElement;

  constructor(
    private readonly modalService: ModalService,
    private readonly auth: AuthService,
    private readonly toastr: ToastrService,
    private cdr: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    await this.auth.checkUser();
    this.loading = false;
    AppComponent.myapp = this;
    this.scriptElement = document.createElement('script');
    this.scriptElement.src = "https://sdk.amazonaws.com/js/aws-sdk-2.1478.0.min.js";
    document.body.appendChild(this.scriptElement);
  }

  reset() {
    this.userName = '';
    this.userEmail = '';
    this.userPw = '';
    this.userPwConfirm = '';
  }

  validateEmail(email: string) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  async login() {
    if (this.isProcessing) return;
    if (this.userEmail === '') {
      this.toastr.error('Enter email address');
      return;
    }
    if (!this.validateEmail(this.userEmail)) {
      this.toastr.error('Email is not valid');
      return;
    }
    if (this.userPw === '') {
      this.toastr.error('Enter password');
      return;
    }
    this.isProcessing = true;
    try {
      await this.auth.loginUser(this.userEmail, this.userPw);
      this.toastr.success('Login Success');
      this.modalService.close('login-modal');
    } catch (error: any) {
      console.log(error);
      this.toastr.error(error?.error?.message, 'Error');
    }
    this.isProcessing = false;
  }

  async register() {
    if (this.isProcessing) return;
    if (this.userName === '') {
      this.toastr.error('Enter username');
      return;
    }
    if (this.userEmail === '') {
      this.toastr.error('Enter email address');
      return;
    }
    if (!this.validateEmail(this.userEmail)) {
      this.toastr.error('Email is not valid');
      return;
    }
    if (this.userPw === '') {
      this.toastr.error('Enter password');
      return;
    }
    if (this.userPw !== this.userPwConfirm) {
      this.toastr.error('Password does not match');
      return;
    }
    this.isProcessing = true;
    try {
      await this.auth.createUser(this.userEmail, this.userName, this.userPw);
      this.toastr.success('Successfully created');
      this.modalService.close('signup-modal');
    } catch (error: any) {
      console.log(error);
      this.toastr.error(error?.error?.message, 'Error');
    }
    this.isProcessing = false;
  }

  openSignup() {
    this.reset();
    this.modalService.close('login-modal');
    this.modalService.open('signup-modal');
  }

  openLogin() {
    this.reset();
    this.modalService.close('signup-modal');
    this.modalService.open('login-modal');
  }

  async setLoggedInUser() {
    this.user = await Moralis.authenticate();
    console.info('Loggedin user:', this.user);
    this.cdr.detectChanges();
  }
}
