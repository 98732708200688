import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { OffchainService } from 'src/app/services/offchain.service';

@Component({
  selector: 'app-single-marketplace-item-card',
  templateUrl: './single-marketplace-item-card.component.html',
  styleUrls: ['./single-marketplace-item-card.component.scss'],
})
export class SingleMarketplaceItemCardComponent implements OnInit {
  @Input() singleItem: any;
  isFavorited:boolean = false;
  itemId: any;
  processing: boolean = false;

  constructor(
    private readonly toastr: ToastrService,
    public readonly auth: AuthService,
    private readonly offchain: OffchainService,
  ) { }

  ngOnInit() {
    this.loadData();
  }

  async loadData() {
    console.log(this.singleItem, "----------")
    this.itemId = this.singleItem.id;
    if(this.auth.isLogin) {
      const favids = this.auth.curUser.favids;
      if(favids.includes(this.itemId)) this.isFavorited = true;
      else this.isFavorited = false;
    }
  }

  async toggleFavorite() {
    if (!this.auth.isLogin) {
      this.toastr.error(
        'You have to login add favorite'
      );
      return;
    }
    this.processing = true;
    if(this.isFavorited){
      await this.removeFromFavorites();
    }else{
      await this.addToFavorites();
    }
    this.toastr.success('Saved!');
    this.processing = false;
  }

  async addToFavorites(){
    const res = await this.offchain.addToFavorites(
      this.itemId,
      this.auth.curUser._id
    );
    this.isFavorited = true;
  }

  async removeFromFavorites(){
    await this.offchain.removeFromFavorites(
      this.itemId,
      this.auth.curUser._id
    )
    this.isFavorited = false;
  }
}
