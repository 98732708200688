<div class="mt-16 sm:mt-20 w-[90%] sm:w-[80%] md:w-[70%] md:px-4 mx-auto">
    <div *ngIf="isLoading"
        class="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-gray-500 bg-opacity-50">
        <div class="spinner"></div>
    </div>
    <div class="flex flex-col md:flex-row">
        <div class="w-full sm:w-[80%] md:w-[60%] sm:mx-auto md:mr-2" *ngIf="article">
            <!-- Blog Post -->
            <div class="my-8">
                <p class="text-gray-500">{{ articleDate }}</p>
                <h1 class="text-2xl md:text-3xl font-bold">{{ article.title }}</h1>
                <div class="mt-4">
                    <!-- Blog Post Content -->
                    <div *ngIf="!article.imageUrl">
                        <img [src]="article.videolink" alt="Article Image" *ngIf="!article?.isvideo"
                            class="w-full h-auto object-cover">
                        <video loop controlsList="nodownload" class="w-full h-auto" controls *ngIf="article?.isvideo">
                            <source type="video/webm" [src]="article.videolink" />
                            <source type="video/mp4" [src]="article.videolink" />
                        </video>
                    </div>
                    <div *ngIf="article.imageUrl">
                        <img [src]="article.imageUrl" alt="Article Image" *ngIf="!article?.isvideo"
                            class="w-full h-auto object-cover">
                        <video loop controlsList="nodownload" class="w-full h-auto" controls *ngIf="article?.isvideo">
                            <source type="video/webm" [src]="article.imageUrl" />
                            <source type="video/mp4" [src]="article.imageUrl" />
                        </video>
                    </div>
                    <p [innerHTML]="article.story"></p>
                </div>
            </div>
            <!-- Comments Section -->
            <div class="my-8">
                <h2 class="text-2xl font-bold">{{ article?.comments.length > 0 ? article?.comments.length : '' }} Comments</h2>
                <!-- Comment Input Field -->
                <div class="mt-4">
                    <h3 class="text-lg font-bold">Leave a Comment</h3>
                    <form>
                        <div class="mt-2">
                            <label for="comment" class="block">Comment</label>
                            <textarea id="comment" rows="3" class="border border-2 border-solid border-gray-500" placeholder="Add a comment here" name="comment" [value]="comment"
                                (input)="comment = $event.target.value"
                                class="w-full px-2 py-1 border rounded"></textarea>
                        </div>
                        <div class="mt-4 flex justify-end">
                            <button type="submit" class="px-4 py-2 bg-blue-500 text-white rounded"
                                (click)="createComment()">Submit</button>
                        </div>
                    </form>
                </div>
                <!-- Existing Comments -->
                <div class="mt-8" *ngIf="article">
                    <div class="block mb-4">
                        <ng-container *ngFor="let comment of article?.comments; let i = index">
                            <div class="flex flex-col md:flex-row w-full my-4">
                                <div class="post-date md:w-[29%] md:mr-[1%] w-full">
                                    <p class="text-gray-500">{{ convertDateString(comment.date) }}</p>
                                </div>
                                <div class="post-content md:w-[70%] w-full">
                                    <h3 class="font-medium">{{comment.user ? comment.user?.username: 'User'}}</h3>
                                    <p [innerHTML]="renderLinks(comment.comment)"></p>
                                    <button class="like-button border-2 border-gray-500 border border-solid flex gap-2 items-center rounded px-2 py-1" (click)="doLikeComment(i)">
                                        <span class="like-count">
                                            👍 {{ comment.likes.length }}
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="related-featured hidden lg:block md:w-[40%] mt-16">
            <h1 class="default-font px-2 mb-1 text-2xl font-bold">Recent Features</h1>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-4 px-2">
                <div *ngFor="let article of articles">
                    <a [routerLink]="'/featured/'+ article._id">
                        <div class="mt-2 bg-white rounded-lg shadow-md overflow-hidden">
                            <div *ngIf="!article.imageUrl">
                                <img [src]="article.videolink" alt="Article Image" *ngIf="!article?.isvideo" class="w-full h-48 object-cover">
                                <video loop controlsList="nodownload" class="w-full h-48" controls *ngIf="article?.isvideo">
                                    <source type="video/webm" [src]="article.videolink" />
                                    <source type="video/mp4" [src]="article.videolink" />
                                </video>
                            </div>
                            <div *ngIf="article.imageUrl">
                                <img [src]="article.imageUrl" alt="Article Image" *ngIf="!article?.isvideo" class="w-full h-48 object-cover">
                                <video loop controlsList="nodownload" class="w-full h-48" controls *ngIf="article?.isvideo">
                                    <source type="video/webm" [src]="article.imageUrl" />
                                    <source type="video/mp4" [src]="article.imageUrl" />
                                </video>
                            </div>
                            <div class="p-1">
                                <h2 class="text-xl text-left font-semibold mb-2 line-clamp-1">{{ article.title }}</h2>
                                <p class="text-left text-gray-700 mb-4 line-clamp-2">{{ article.story}}</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="w-full mt-4 px-2">
                <a routerLink="/featured" class="w-full block bg-blue-500 text-center text-white px-4 py-2 rounded-lg">View All Featured</a>
            </div>
        </div>
    </div>
</div>