import { Injectable } from '@angular/core';
import { VinDataType } from '../types/vinData.type';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VindataserviceService {
  private baseUrl: string = 'https://vpic.nhtsa.dot.gov/api/vehicles/decodevin/';
  constructor(private http: HttpClient) {
    this.countries = require('src/assets/db/countries.json');
    this.manufacturers = require('src/assets/db/manufacters.json');
    console.log(this.countries, this.manufacturers);
  }


  async getVinDetailsfromNHTSA(vin: string, modelYear?: string): Promise<VinResult> {
    try {
      const url = `${this.baseUrl}${vin}?format=json${modelYear ? `&modelyear=${modelYear}` : ''}`;
      const response = await this.http.get<any>(url).toPromise();
      return this.extractVinDetails(response);
    } catch (error) {
      // Handle error
      console.error('Error fetching VIN data:', error);
      throw error;
    }
  }

  countries: any;
  manufacturers: any;

  async getMake(code: string): Promise<string> {
    let result = '';
    let dataFile = require('src/assets/db/vinmancodes.json');

    for (let index = 0; index < dataFile.length; index++) {
      const element = dataFile[index];

      if (element.Code.toString().toLocaleUpperCase() == code) {
        result = element.Make;
        break;
      }
    }
    return result;
  }

  async validate(vin: any): Promise<boolean> {
    let splitVIN = vin.split('');
    let total = 0;

    for (let i = 0; i < splitVIN.length; i++) {
      let numValue = 0;
      let weight = 0;

      switch (splitVIN[i]) {
        case '0':
          numValue = 0;
          break;
        case '1':
        case 'a':
        case 'j':
          numValue = 1;
          break;
        case '2':
        case 'b':
        case 'k':
        case 's':
          numValue = 2;
          break;
        case '3':
        case 'c':
        case 'l':
        case 't':
          numValue = 3;
          break;
        case '4':
        case 'd':
        case 'm':
        case 'u':
          numValue = 4;
          break;
        case '5':
        case 'e':
        case 'n':
        case 'v':
          numValue = 5;
          break;
        case '6':
        case 'f':
        case 'w':
          numValue = 6;
          break;
        case '7':
        case 'g':
        case 'p':
        case 'x':
          numValue = 7;
          break;
        case '8':
        case 'h':
        case 'y':
          numValue = 8;
          break;
        case '9':
        case 'r':
        case 'z':
          numValue = 9;
          break;
        default:
          return false;
      }

      switch (i) {
        case 0:
          weight = 8;
          break;
        case 1:
          weight = 7;
          break;
        case 2:
          weight = 6;
          break;
        case 3:
          weight = 5;
          break;
        case 4:
          weight = 4;
          break;
        case 5:
          weight = 3;
          break;
        case 6:
          weight = 2;
          break;
        case 7:
          weight = 10;
          break;
        case 8:
          weight = 0;
          break;
        case 9:
          weight = 9;
          break;
        case 10:
          weight = 8;
          break;
        case 11:
          weight = 7;
          break;
        case 12:
          weight = 6;
          break;
        case 13:
          weight = 5;
          break;
        case 14:
          weight = 4;
          break;
        case 15:
          weight = 3;
          break;
        case 16:
          weight = 2;
          break;
        default:
          return false;
      }
      splitVIN.splice(i, 1, numValue * weight);
    }

    for (let j = 0; j < splitVIN.length; j++) {
      total += splitVIN[j];
    }

    const lastFive = vin.split('');
    lastFive.splice(0, 12);
    for (let k = 0; k < lastFive.length; k++) {
      if (!Number.isInteger(parseInt(lastFive[k]))) {
        return false;
      }
    }

    if (total % 11 === parseInt(vin.split('')[8])) {
      return true;
    } else if (total % 11 === 10 && vin.split('')[8] === 'x') {
      return true;
    } else {
      return false;
    }

    return true;
  }

  async split(vin: any): Promise<VinDataType> {
    var INDEXES = {
      MADE_IN_START: 0,
      MADE_IN_END: 2,
      MANUFACTURER_START: 0,
      MANUFACTURER_END: 3,
      DETAILS_START: 3,
      DETAILS_END: 8,
      SECURITY_CODE: 8,
      YEAR: 9,
      ASSEMBLY_PLANT: 10,
      SERIAL_NUMBER_START: 11,
    };

    var rawInfo = {
      madeIn: vin.substring(INDEXES.MADE_IN_START, INDEXES.MADE_IN_END),
      manufacturer: vin.substring(
        INDEXES.MANUFACTURER_START,
        INDEXES.MANUFACTURER_END
      ),
      details: vin.substring(INDEXES.DETAILS_START, INDEXES.DETAILS_END),
      securityCode: vin.charAt(INDEXES.SECURITY_CODE),
      year: vin.charAt(INDEXES.YEAR),
      assemblyPlant: vin.charAt(INDEXES.ASSEMBLY_PLANT),
      serialNumber: vin.substring(INDEXES.SERIAL_NUMBER_START),
    };

    return rawInfo;
  }

  async lookup(keyName: any, key: any, elements: []) {
    for (var i = 0; i < elements.length; i++) {
      var element = elements[i];

      if (element[keyName] == key) return element;
    }

    return '';
  }

  async getCountry(countryCode: any) {
    var country = await this.lookup('code', countryCode, this.countries);
    // return country.name;
    return country;
  }

  async getManufacturer(manufacturerCode: any) {
    var manufacturer = await this.lookup(
      'code',
      manufacturerCode,
      this.manufacturers
    );
    // return manufacturer.name;
    return manufacturer;
  }

  async decode(vin: any) {
    var codeValues = await this.split(vin);

    var carInfo = {
      country: this.getCountry(codeValues.madeIn),
      serialNumber: codeValues.serialNumber,
      manufacturer: this.getManufacturer(codeValues.manufacturer),
    };

    return carInfo;
  }


 getVinEstimateById(id: number){

  if(id == 14){
    return "$169,000"; //1983 Porsche 930, Twin Plugged, K28, ‘The Benihana Car’
  }
  else if(id == 48){
    return "$45,000"; //2002 Pontiac Firebird Trans Am - GMMG 35th Anniversary #13
  }
  else if(id == 49){ // Long Windshield DP935 original VIN
    return "$375,000";
  }
  else if(id == 47){ //1985 Porsche DP935 II, Full Kevlar Body and Engine Modifications
    return "$275,000";
  }
  else if(id == 46){ //Gemballa
    return "$110,000";
  }

  else if (id == 54) {    //49th Lamborghini Diablo produced, 1991
 
    return "$335,000";
  }

  else return "0";



}





 extractVinDetails(vinData: any): VinResult {
  const result: VinResult = {};

  vinData.Results.forEach((item: any) => {
    switch (item.Variable) {
      case 'Make':
        result.Make = item.Value;
        break;
      case 'Model':
        result.Model = item.Value;
        break;
      case 'Model Year':
        result.ModelYear = item.Value;
        break;
      case 'Vehicle Type':
      case 'Body Class':
        result.VehicleType = item.Value;
        break;
      case 'Engine Number of Cylinders':
      case 'Displacement (CC)':
      case 'Engine Brake (hp) From':
        result.EngineInfo = `${result.EngineInfo ? result.EngineInfo + ', ' : ''}${item.Value}`;
        break;
      case 'Fuel Type - Primary':
        result.FuelType = item.Value;
        break;
      case 'Plant Country':
        result.PlantCountry = item.Value;
        break;
      case 'Gross Vehicle Weight Rating From':
        result.GVWR = item.Value;
        break;
      case 'Trim':
        result.TrimLevel = item.Value;
        break;
      case 'Transmission Style':
      case 'Transmission Speeds':
        result.TransmissionInfo = `${result.TransmissionInfo ? result.TransmissionInfo + ', ' : ''}${item.Value}`;
        break;
    }
  });

  return result;
}


}

interface VinResult {
  Make?: string;
  Model?: string;
  ModelYear?: string;
  VehicleType?: string;
  EngineInfo?: string;
  FuelType?: string;
  PlantCountry?: string;
  GVWR?: string;
  TrimLevel?: string;
  TransmissionInfo?: string;
}