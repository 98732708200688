import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './screens/home/home.component';
import { ItemDetailsComponent } from './screens/item-details/item-details.component';
import { ItemTransferComponent } from './screens/itemTransfer/itemTransfer.component';
import { LayoutComponent } from './components/layout/layout.component';
import { CreateComponent } from './screens/create/create.component';
import { UpdateComponent } from './screens/update/update.component';
import { ProfileComponent } from './screens/profile/profile.component';
import { ExplorerComponent } from './screens/explorer/explorer.component';
import { PricingComponent } from './screens/pricing/pricing.component';
import { AboutComponent } from './components/about/about.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { FaqComponent } from './faq/faq.component';
import { TestComponent } from './components/test/test.component';
import { ConciergeComponent } from './screens/concierge/concierge.component';
import { AdminComponent } from './screens/admin/admin.component';
import { GameComponent } from './screens/game/game.component';
import { ArticlesSectionComponent } from './articles-section/articles-section.component';
import { ViewAllArticlesComponent } from './view-all-articles/view-all-articles.component';
import { BlogComponent } from './screens/blog/blog.component';
import { ViewMarketPlaceComponent } from './screens/view-market-place/view-market-place.component';
import { CreateWizardFormComponent } from './create-wizard-form/create-wizard-form.component';
import { ContactUsComponent } from './screens/contact-us/contact-us.component';
const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'item/:id',
        component: ItemDetailsComponent,
      },
      {
        path: 'itemTransfer/:id',
        component: ItemTransferComponent,
      },

      // {
      //   path: 'create/:plan/:refCode',
      //   component: CreateComponent,
      // },

      // {
      //   path: 'create/:plan',
      //   component: CreateComponent,
      // },

      {
        path: 'create/:plan/:refCode',
        component: CreateWizardFormComponent,
      },

      {
        path: 'create/:plan',
        component: CreateWizardFormComponent,
      },


      {
        path: 'update/:id',
        component: UpdateComponent,
      },
      {
        path: 'pricing',
        component: PricingComponent,
      },
      {
        path: 'profile/:id',
        component: ProfileComponent,
      },
      {
        path: 'garage/:id',
        component: ProfileComponent,
      },
      {
        path: 'explorer',
        component: ExplorerComponent,
      },
      {
        path: 'about',
        component: AboutComponent,
      },
      {
        path: 'privacy',
        component: PrivacyComponent,
      },
      {
        path: 'terms',
        component: PrivacyComponent,
      },
      {
        path: 'faq',
        component: FaqComponent,
      },
      {
        path: 'FAQ',
        component: FaqComponent,
      },
      {
        path: 'concierge',
        component: ConciergeComponent,
      },
      {
        path: 'test',
        component: TestComponent,
      },
      {
        path: 'admin',
        component: AdminComponent,
      },
      {
        path: 'game',
        component: GameComponent,
      },
      {
        path: 'articles',
        component: ArticlesSectionComponent,
      },
      {
        path: 'featured',
        component: ViewAllArticlesComponent,
      },
      {
        path: 'featured/:id',
        component: BlogComponent,
      },
      {
        path: 'marketplace',
        component: ViewMarketPlaceComponent,
      },
      {
        path: 'contact',
        component: ContactUsComponent
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
