import { Component, OnInit, forwardRef } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-predictive-make-model-text',
  templateUrl: './predictive-make-model-text.component.html',
  styleUrls: ['./predictive-make-model-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PredictiveMakeModelTextComponent),
      multi: true
    }
  ]
})
export class PredictiveMakeModelTextComponent implements OnInit, ControlValueAccessor {
  searchInput = new FormControl('');
  suggestions$: Observable<string[]>;
  showDropdown = true; // Flag to control the visibility of the dropdown

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: any): void {
    this.searchInput.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
    this.suggestions$ = this.searchInput.valueChanges.pipe(
      debounceTime(300), // wait for 300 ms after the last event before emitting the last value
      distinctUntilChanged(), // only emit if the current value is different from the last
      switchMap(searchTerm => this.searchMakesModels(searchTerm))
    );
    this.searchInput.valueChanges.subscribe(value => {
      this.onChange(value);
    });
  }

  private searchMakesModels(searchTerm: string): Observable<string[]> {
    return new Observable(observer => {
      const suggestions = [];
      if (searchTerm) {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        for (let car of this.carMakesModels.models) {
          const fullName = `${car.make_name} ${car.name}`;
          if (fullName.toLowerCase().includes(lowerCaseSearchTerm)) {
            suggestions.push(fullName);
          }
        }
      }
      observer.next(suggestions);
      observer.complete();
    });
  }

  selectSuggestion(suggestion: string): void {
    this.searchInput.setValue(suggestion);
    this.showDropdown = false; // Hide the dropdown when a suggestion is selected
    // Perform any other action needed when a suggestion is selected
  }

  onSearchChange(): void {
    this.showDropdown = true; // Show the dropdown when search input changes
  }

  private carMakesModels = {
    models: [
      {
          "name": "1",
          "slug": "polestar-1",
          "make_name": "Polestar",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "100",
          "slug": "austin_healey-100",
          "make_name": "Austin-Healey",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "100",
          "slug": "audi-100",
          "make_name": "Audi",
          "stock_types": []
      },
      {
          "name": "100-6",
          "slug": "austin_healey-100_6",
          "make_name": "Austin-Healey",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "100",
          "slug": "austin_healey-100",
          "make_name": "Austin-Healey",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "100",
          "slug": "audi-100",
          "make_name": "Audi",
          "stock_types": []
      },
      {
          "name": "100-6",
          "slug": "austin_healey-100_6",
          "make_name": "Austin-Healey",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "110",
          "slug": "datsun-110",
          "make_name": "Datsun",
          "stock_types": []
      },
      {
          "name": "110",
          "slug": "packard-110",
          "make_name": "Packard",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "115C",
          "slug": "packard-115c",
          "make_name": "Packard",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "120",
          "slug": "packard-120",
          "make_name": "Packard",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "124",
          "slug": "fiat-124",
          "make_name": "FIAT",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "124 Spider",
          "slug": "fiat-124_spider",
          "make_name": "FIAT",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "128",
          "slug": "fiat-128",
          "make_name": "FIAT",
          "stock_types": []
      },
      {
          "name": "128",
          "slug": "bmw-128",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "135",
          "slug": "bmw-135",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "150",
          "slug": "chevrolet-150",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "1500",
          "slug": "fiat-1500",
          "make_name": "FIAT",
          "stock_types": []
      },
      {
          "name": "1500",
          "slug": "datsun-1500",
          "make_name": "Datsun",
          "stock_types": []
      },
      {
          "name": "1500",
          "slug": "ram-1500",
          "make_name": "RAM",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "1500",
          "slug": "bmw-1500",
          "make_name": "BMW",
          "stock_types": []
      },
      {
          "name": "1500",
          "slug": "chevrolet-1500",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "1500",
          "slug": "gmc-1500",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "1500 Classic",
          "slug": "ram-1500_classic",
          "make_name": "RAM",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "1600",
          "slug": "datsun-1600",
          "make_name": "Datsun",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "1600",
          "slug": "packard-1600",
          "make_name": "Packard",
          "stock_types": []
      },
      {
          "name": "1600",
          "slug": "bmw-1600",
          "make_name": "BMW",
          "stock_types": []
      },
      {
          "name": "1600",
          "slug": "subaru-1600",
          "make_name": "Subaru",
          "stock_types": []
      },
      {
          "name": "1600",
          "slug": "volkswagen-1600",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "164",
          "slug": "alfa_romeo-164",
          "make_name": "Alfa Romeo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "164",
          "slug": "volvo-164",
          "make_name": "Volvo",
          "stock_types": []
      },
      {
          "name": "1800",
          "slug": "triumph-1800",
          "make_name": "Triumph",
          "stock_types": []
      },
      {
          "name": "1800",
          "slug": "mazda-1800",
          "make_name": "Mazda",
          "stock_types": []
      },
      {
          "name": "1800",
          "slug": "bmw-1800",
          "make_name": "BMW",
          "stock_types": []
      },
      {
          "name": "1800",
          "slug": "volvo-1800",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "1800",
          "slug": "subaru-1800",
          "make_name": "Subaru",
          "stock_types": []
      },
      {
          "name": "1900",
          "slug": "fiat-1900",
          "make_name": "FIAT",
          "stock_types": []
      },
      {
          "name": "1900",
          "slug": "alfa_romeo-1900",
          "make_name": "Alfa Romeo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "1900",
          "slug": "opel-1900",
          "make_name": "Opel",
          "stock_types": []
      },
      {
          "name": "2",
          "slug": "polestar-2",
          "make_name": "Polestar",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "20/25",
          "slug": "rolls_royce-20_25",
          "make_name": "Rolls-Royce",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "200",
          "slug": "chrysler-200",
          "make_name": "Chrysler",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "200",
          "slug": "mercedes_benz-200",
          "make_name": "Mercedes-Benz",
          "stock_types": []
      },
      {
          "name": "200",
          "slug": "packard-200",
          "make_name": "Packard",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "200",
          "slug": "audi-200",
          "make_name": "Audi",
          "stock_types": []
      },
      {
          "name": "2000",
          "slug": "triumph-2000",
          "make_name": "Triumph",
          "stock_types": []
      },
      {
          "name": "2000",
          "slug": "pontiac-2000",
          "make_name": "Pontiac",
          "stock_types": []
      },
      {
          "name": "2000",
          "slug": "datsun-2000",
          "make_name": "Datsun",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "2000",
          "slug": "alfa_romeo-2000",
          "make_name": "Alfa Romeo",
          "stock_types": []
      },
      {
          "name": "2000",
          "slug": "bmw-2000",
          "make_name": "BMW",
          "stock_types": []
      },
      {
          "name": "2002",
          "slug": "bmw-2002",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "200SX",
          "slug": "datsun-200sx",
          "make_name": "Datsun",
          "stock_types": []
      },
      {
          "name": "200SX",
          "slug": "nissan-200sx",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "210",
          "slug": "datsun-210",
          "make_name": "Datsun",
          "stock_types": []
      },
      {
          "name": "210",
          "slug": "chevrolet-210",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "228",
          "slug": "bmw-228",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "228",
          "slug": "maserati-228",
          "make_name": "Maserati",
          "stock_types": []
      },
      {
          "name": "228 Gran Coupe",
          "slug": "bmw-228_gran_coupe",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "230",
          "slug": "mercedes_benz-230",
          "make_name": "Mercedes-Benz",
          "stock_types": []
      },
      {
          "name": "230",
          "slug": "bmw-230",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "240",
          "slug": "mercedes_benz-240",
          "make_name": "Mercedes-Benz",
          "stock_types": []
      },
      {
          "name": "240",
          "slug": "volvo-240",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "240Z",
          "slug": "datsun-240z",
          "make_name": "Datsun",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "2500",
          "slug": "ram-2500",
          "make_name": "RAM",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "2500",
          "slug": "bmw-2500",
          "make_name": "BMW",
          "stock_types": []
      },
      {
          "name": "2500",
          "slug": "chevrolet-2500",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "2500",
          "slug": "gmc-2500",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "2600",
          "slug": "alfa_romeo-2600",
          "make_name": "Alfa Romeo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "2600",
          "slug": "bmw-2600",
          "make_name": "BMW",
          "stock_types": []
      },
      {
          "name": "260Z",
          "slug": "datsun-260z",
          "make_name": "Datsun",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "280SE",
          "slug": "mercedes_benz-280se",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "280SL",
          "slug": "mercedes_benz-280sl",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "280Z",
          "slug": "datsun-280z",
          "make_name": "Datsun",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "280ZX",
          "slug": "datsun-280zx",
          "make_name": "Datsun",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "280ZX",
          "slug": "nissan-280zx",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "296 GTB",
          "slug": "ferrari-296_gtb",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "300",
          "slug": "chrysler-300",
          "make_name": "Chrysler",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "300",
          "slug": "mercedes_benz-300",
          "make_name": "Mercedes-Benz",
          "stock_types": []
      },
      {
          "name": "300",
          "slug": "packard-300",
          "make_name": "Packard",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "3000",
          "slug": "austin_healey-3000",
          "make_name": "Austin-Healey",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "3000 Mk II",
          "slug": "austin_healey-3000_mk_ii",
          "make_name": "Austin-Healey",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "3000 Mk III",
          "slug": "austin_healey-3000_mk_iii",
          "make_name": "Austin-Healey",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "3000GT",
          "slug": "mitsubishi-3000gt",
          "make_name": "Mitsubishi",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "300C",
          "slug": "chrysler-300c",
          "make_name": "Chrysler",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "300C",
          "slug": "mercedes_benz-300c",
          "make_name": "Mercedes-Benz",
          "stock_types": []
      },
      {
          "name": "300M",
          "slug": "chrysler-300m",
          "make_name": "Chrysler",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "300ZX",
          "slug": "nissan-300zx",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "308",
          "slug": "ferrari-308",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "3100",
          "slug": "chevrolet-3100",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "320",
          "slug": "bmw-320",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "323",
          "slug": "mazda-323",
          "make_name": "Mazda",
          "stock_types": []
      },
      {
          "name": "323",
          "slug": "bmw-323",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "325",
          "slug": "bmw-325",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "328",
          "slug": "ferrari-328",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "328",
          "slug": "bmw-328",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "328",
          "slug": "lasalle-328",
          "make_name": "LaSalle",
          "stock_types": []
      },
      {
          "name": "328 Gran Turismo",
          "slug": "bmw-328_gran_turismo",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "328d",
          "slug": "bmw-328d",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "330",
          "slug": "ferrari-330",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "330",
          "slug": "dodge-330",
          "make_name": "Dodge",
          "stock_types": []
      },
      {
          "name": "330",
          "slug": "bmw-330",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "330 Gran Turismo",
          "slug": "bmw-330_gran_turismo",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "330e",
          "slug": "bmw-330e",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "335",
          "slug": "bmw-335",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "335 Gran Turismo",
          "slug": "bmw-335_gran_turismo",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "340",
          "slug": "jaguar-340",
          "make_name": "Jaguar",
          "stock_types": []
      },
      {
          "name": "340",
          "slug": "bmw-340",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "340",
          "slug": "lasalle-340",
          "make_name": "LaSalle",
          "stock_types": []
      },
      {
          "name": "340 Gran Turismo",
          "slug": "bmw-340_gran_turismo",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "348",
          "slug": "ferrari-348",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "3500",
          "slug": "ram-3500",
          "make_name": "RAM",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "3500",
          "slug": "chevrolet-3500",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "3500",
          "slug": "gmc-3500",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "3500",
          "slug": "maserati-3500",
          "make_name": "Maserati",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "350Z",
          "slug": "nissan-350z",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "356",
          "slug": "porsche-356",
          "make_name": "Porsche",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "360 Modena",
          "slug": "ferrari-360_modena",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "360 Spider",
          "slug": "ferrari-360_spider",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "365",
          "slug": "ferrari-365",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "370Z",
          "slug": "nissan-370z",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "38",
          "slug": "willys-38",
          "make_name": "Willys",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "380SL",
          "slug": "mercedes_benz-380sl",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "4",
          "slug": "renault-4",
          "make_name": "Renault",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "400 GT",
          "slug": "lamborghini-400_gt",
          "make_name": "Lamborghini",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "400i",
          "slug": "ferrari-400i",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "420",
          "slug": "jaguar-420",
          "make_name": "Jaguar",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "428",
          "slug": "ac-428",
          "make_name": "AC",
          "stock_types": []
      },
      {
          "name": "428",
          "slug": "bmw-428",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "428 Gran Coupe",
          "slug": "bmw-428_gran_coupe",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "430",
          "slug": "bmw-430",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "430",
          "slug": "maserati-430",
          "make_name": "Maserati",
          "stock_types": []
      },
      {
          "name": "430 Gran Coupe",
          "slug": "bmw-430_gran_coupe",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "435",
          "slug": "bmw-435",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "435 Gran Coupe",
          "slug": "bmw-435_gran_coupe",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "440",
          "slug": "studebaker-440",
          "make_name": "Studebaker",
          "stock_types": []
      },
      {
          "name": "440",
          "slug": "dodge-440",
          "make_name": "Dodge",
          "stock_types": []
      },
      {
          "name": "440",
          "slug": "bmw-440",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "440 Gran Coupe",
          "slug": "bmw-440_gran_coupe",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "442",
          "slug": "oldsmobile-442",
          "make_name": "Oldsmobile",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "450SL",
          "slug": "mercedes_benz-450sl",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "458 Italia",
          "slug": "ferrari-458_italia",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "458 Speciale",
          "slug": "ferrari-458_speciale",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "458 Spider",
          "slug": "ferrari-458_spider",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "488 GTB",
          "slug": "ferrari-488_gtb",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "488 Pista",
          "slug": "ferrari-488_pista",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "488 Pista Spider",
          "slug": "ferrari-488_pista_spider",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "488 Spider",
          "slug": "ferrari-488_spider",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "4C",
          "slug": "alfa_romeo-4c",
          "make_name": "Alfa Romeo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "4C Spider",
          "slug": "alfa_romeo-4c_spider",
          "make_name": "Alfa Romeo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "4Runner",
          "slug": "toyota-4runner",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "50",
          "slug": "lasalle-50",
          "make_name": "LaSalle",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "500",
          "slug": "fiat-500",
          "make_name": "FIAT",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "500C",
          "slug": "fiat-500c",
          "make_name": "FIAT",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "500L",
          "slug": "fiat-500l",
          "make_name": "FIAT",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "500X",
          "slug": "fiat-500x",
          "make_name": "FIAT",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "500e",
          "slug": "fiat-500e",
          "make_name": "FIAT",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "510",
          "slug": "datsun-510",
          "make_name": "Datsun",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "512",
          "slug": "ferrari-512",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "512 TR",
          "slug": "ferrari-512_tr",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "525",
          "slug": "bmw-525",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "528",
          "slug": "bmw-528",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "530",
          "slug": "bmw-530",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "530e",
          "slug": "bmw-530e",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "535",
          "slug": "bmw-535",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "535 Gran Turismo",
          "slug": "bmw-535_gran_turismo",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "535d",
          "slug": "bmw-535d",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "540",
          "slug": "bmw-540",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "550",
          "slug": "bmw-550",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "550 Gran Turismo",
          "slug": "bmw-550_gran_turismo",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "550 Maranello",
          "slug": "ferrari-550_maranello",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "570GT",
          "slug": "mclaren-570gt",
          "make_name": "McLaren",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "570S",
          "slug": "mclaren-570s",
          "make_name": "McLaren",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "575 M",
          "slug": "ferrari-575_m",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "599 GTB Fiorano",
          "slug": "ferrari-599_gtb_fiorano",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "599 GTO",
          "slug": "ferrari-599_gto",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "600",
          "slug": "mercedes_benz-600",
          "make_name": "Mercedes-Benz",
          "stock_types": []
      },
      {
          "name": "600",
          "slug": "dodge-600",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "600",
          "slug": "honda-600",
          "make_name": "Honda",
          "stock_types": []
      },
      {
          "name": "600",
          "slug": "fiat-600",
          "make_name": "FIAT",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "600",
          "slug": "bmw-600",
          "make_name": "BMW",
          "stock_types": []
      },
      {
          "name": "600",
          "slug": "nash-600",
          "make_name": "Nash",
          "stock_types": []
      },
      {
          "name": "600LT",
          "slug": "mclaren-600lt",
          "make_name": "McLaren",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "612 Scaglietti",
          "slug": "ferrari-612_scaglietti",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "620R",
          "slug": "mclaren-620r",
          "make_name": "McLaren",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "626",
          "slug": "mazda-626",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "626",
          "slug": "packard-626",
          "make_name": "Packard",
          "stock_types": []
      },
      {
          "name": "635",
          "slug": "bmw-635",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "640",
          "slug": "packard-640",
          "make_name": "Packard",
          "stock_types": []
      },
      {
          "name": "640",
          "slug": "bmw-640",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "640 Gran Coupe",
          "slug": "bmw-640_gran_coupe",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "640 Gran Turismo",
          "slug": "bmw-640_gran_turismo",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "645",
          "slug": "packard-645",
          "make_name": "Packard",
          "stock_types": []
      },
      {
          "name": "645",
          "slug": "bmw-645",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "650",
          "slug": "bmw-650",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "650 Gran Coupe",
          "slug": "bmw-650_gran_coupe",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "650S",
          "slug": "mclaren-650s",
          "make_name": "McLaren",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "675LT",
          "slug": "mclaren-675lt",
          "make_name": "McLaren",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "718 Boxster",
          "slug": "porsche-718_boxster",
          "make_name": "Porsche",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "718 Cayman",
          "slug": "porsche-718_cayman",
          "make_name": "Porsche",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "718 Spyder",
          "slug": "porsche-718_spyder",
          "make_name": "Porsche",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "720S",
          "slug": "mclaren-720s",
          "make_name": "McLaren",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "740",
          "slug": "packard-740",
          "make_name": "Packard",
          "stock_types": []
      },
      {
          "name": "740",
          "slug": "bmw-740",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "740",
          "slug": "volvo-740",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "740e",
          "slug": "bmw-740e",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "745",
          "slug": "packard-745",
          "make_name": "Packard",
          "stock_types": []
      },
      {
          "name": "745",
          "slug": "bmw-745",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "745e",
          "slug": "bmw-745e",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "75",
          "slug": "rover-75",
          "make_name": "Rover",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "750",
          "slug": "bmw-750",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "750e",
          "slug": "bmw-750e",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "76",
          "slug": "oldsmobile-76",
          "make_name": "Oldsmobile",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "760",
          "slug": "bmw-760",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "760",
          "slug": "volvo-760",
          "make_name": "Volvo",
          "stock_types": []
      },
      {
          "name": "765LT",
          "slug": "mclaren-765lt",
          "make_name": "McLaren",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "812 GTS",
          "slug": "ferrari-812_gts",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "812 Superfast",
          "slug": "ferrari-812_superfast",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "840",
          "slug": "packard-840",
          "make_name": "Packard",
          "stock_types": []
      },
      {
          "name": "840",
          "slug": "bmw-840",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "840 Gran Coupe",
          "slug": "bmw-840_gran_coupe",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "850",
          "slug": "fiat-850",
          "make_name": "FIAT",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "850",
          "slug": "bmw-850",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "850",
          "slug": "volvo-850",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "86",
          "slug": "toyota-86",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "88",
          "slug": "oldsmobile-88",
          "make_name": "Oldsmobile",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "880",
          "slug": "dodge-880",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "880",
          "slug": "nash-880",
          "make_name": "Nash",
          "stock_types": []
      },
      {
          "name": "8c Competizione",
          "slug": "alfa_romeo-8c_competizione",
          "make_name": "Alfa Romeo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "9-2X",
          "slug": "saab-9_2x",
          "make_name": "Saab",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "9-3",
          "slug": "saab-9_3",
          "make_name": "Saab",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "9-3X",
          "slug": "saab-9_3x",
          "make_name": "Saab",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "9-5",
          "slug": "saab-9_5",
          "make_name": "Saab",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "9-7X",
          "slug": "saab-9_7x",
          "make_name": "Saab",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "900",
          "slug": "saab-900",
          "make_name": "Saab",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "900",
          "slug": "packard-900",
          "make_name": "Packard",
          "stock_types": []
      },
      {
          "name": "9000",
          "slug": "saab-9000",
          "make_name": "Saab",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "911",
          "slug": "porsche-911",
          "make_name": "Porsche",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "912",
          "slug": "porsche-912",
          "make_name": "Porsche",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "914",
          "slug": "porsche-914",
          "make_name": "Porsche",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "918 Spyder",
          "slug": "porsche-918_spyder",
          "make_name": "Porsche",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "924",
          "slug": "porsche-924",
          "make_name": "Porsche",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "928",
          "slug": "porsche-928",
          "make_name": "Porsche",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "930",
          "slug": "porsche-930",
          "make_name": "Porsche",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "944",
          "slug": "porsche-944",
          "make_name": "Porsche",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "96",
          "slug": "saab-96",
          "make_name": "Saab",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "960",
          "slug": "volvo-960",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "960",
          "slug": "nash-960",
          "make_name": "Nash",
          "stock_types": []
      },
      {
          "name": "968",
          "slug": "porsche-968",
          "make_name": "Porsche",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "98",
          "slug": "oldsmobile-98",
          "make_name": "Oldsmobile",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "A-Class",
          "slug": "mercedes_benz-a_class",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "A3",
          "slug": "audi-a3",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "A3 e-tron",
          "slug": "audi-a3_e_tron",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "A4",
          "slug": "audi-a4",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "A4 allroad",
          "slug": "audi-a4_allroad",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "A5",
          "slug": "audi-a5",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "A5 Sportback",
          "slug": "audi-a5_sportback",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "A6",
          "slug": "audi-a6",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "A6 allroad",
          "slug": "audi-a6_allroad",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "A7",
          "slug": "audi-a7",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "A7 e",
          "slug": "audi-a7_e",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "A8",
          "slug": "audi-a8",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "A8 e",
          "slug": "audi-a8_e",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "AIV Roadster",
          "slug": "panoz-aiv_roadster",
          "make_name": "Panoz",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "ALPINA B7",
          "slug": "bmw-alpina_b7",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "ALPINA B8 Gran Coupe",
          "slug": "bmw-alpina_b8_gran_coupe",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "ALPINA XB7",
          "slug": "bmw-alpina_xb7",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "AMG A 35",
          "slug": "mercedes_benz-amg_a_35",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "AMG C",
          "slug": "mercedes_benz-amg_c",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "AMG C 43",
          "slug": "mercedes_benz-amg_c_43",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "AMG C 63",
          "slug": "mercedes_benz-amg_c_63",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "AMG CLA 35",
          "slug": "mercedes_benz-amg_cla_35",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "AMG CLA 45",
          "slug": "mercedes_benz-amg_cla_45",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "AMG CLS",
          "slug": "mercedes_benz-amg_cls",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "AMG CLS 53",
          "slug": "mercedes_benz-amg_cls_53",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "AMG E",
          "slug": "mercedes_benz-amg_e",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "AMG E 43",
          "slug": "mercedes_benz-amg_e_43",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "AMG E 53",
          "slug": "mercedes_benz-amg_e_53",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "AMG E 63",
          "slug": "mercedes_benz-amg_e_63",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "AMG EQE",
          "slug": "mercedes_benz-amg_eqe",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "AMG EQS",
          "slug": "mercedes_benz-amg_eqs",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "AMG G",
          "slug": "mercedes_benz-amg_g",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "AMG G 63",
          "slug": "mercedes_benz-amg_g_63",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "AMG GLA 35",
          "slug": "mercedes_benz-amg_gla_35",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "AMG GLA 45",
          "slug": "mercedes_benz-amg_gla_45",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "AMG GLB 35",
          "slug": "mercedes_benz-amg_glb_35",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "AMG GLC 43",
          "slug": "mercedes_benz-amg_glc_43",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "AMG GLC 63",
          "slug": "mercedes_benz-amg_glc_63",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "AMG GLE",
          "slug": "mercedes_benz-amg_gle",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "AMG GLE 43",
          "slug": "mercedes_benz-amg_gle_43",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "AMG GLE 53",
          "slug": "mercedes_benz-amg_gle_53",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "AMG GLE 63",
          "slug": "mercedes_benz-amg_gle_63",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "AMG GLS 63",
          "slug": "mercedes_benz-amg_gls_63",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "AMG GT",
          "slug": "mercedes_benz-amg_gt",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "AMG GT 43",
          "slug": "mercedes_benz-amg_gt_43",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "AMG GT 53",
          "slug": "mercedes_benz-amg_gt_53",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "AMG GT 63",
          "slug": "mercedes_benz-amg_gt_63",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "AMG S",
          "slug": "mercedes_benz-amg_s",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "AMG S 63",
          "slug": "mercedes_benz-amg_s_63",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "AMG S 65",
          "slug": "mercedes_benz-amg_s_65",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "AMG SL 43",
          "slug": "mercedes_benz-amg_sl_43",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "AMG SL 55",
          "slug": "mercedes_benz-amg_sl_55",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "AMG SL 63",
          "slug": "mercedes_benz-amg_sl_63",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "AMG SLC 43",
          "slug": "mercedes_benz-amg_slc_43",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "ARIYA",
          "slug": "nissan-ariya",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "ATS",
          "slug": "cadillac-ats",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "ATS-V",
          "slug": "cadillac-ats_v",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Acadia",
          "slug": "gmc-acadia",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Acadia Limited",
          "slug": "gmc-acadia_limited",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Accent",
          "slug": "hyundai-accent",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Acclaim",
          "slug": "plymouth-acclaim",
          "make_name": "Plymouth",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Accord",
          "slug": "honda-accord",
          "make_name": "Honda",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Accord Crosstour",
          "slug": "honda-accord_crosstour",
          "make_name": "Honda",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Accord Hybrid",
          "slug": "honda-accord_hybrid",
          "make_name": "Honda",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Accord Plug-In Hybrid",
          "slug": "honda-accord_plug_in_hybrid",
          "make_name": "Honda",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Ace",
          "slug": "ac-ace",
          "make_name": "AC",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "ActiveHybrid 3",
          "slug": "bmw-activehybrid_3",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Aerio",
          "slug": "suzuki-aerio",
          "make_name": "Suzuki",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Air",
          "slug": "lucid-air",
          "make_name": "Lucid",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Alero",
          "slug": "oldsmobile-alero",
          "make_name": "Oldsmobile",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Alfetta",
          "slug": "alfa_romeo-alfetta",
          "make_name": "Alfa Romeo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Allante",
          "slug": "cadillac-allante",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Alpine",
          "slug": "sunbeam-alpine",
          "make_name": "Sunbeam",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Altima",
          "slug": "nissan-altima",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Altima Hybrid",
          "slug": "nissan-altima_hybrid",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Amanti",
          "slug": "kia-amanti",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Ambassador",
          "slug": "american_motors-ambassador",
          "make_name": "American Motors",
          "stock_types": []
      },
      {
          "name": "Ambassador",
          "slug": "nash-ambassador",
          "make_name": "Nash",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Amigo",
          "slug": "isuzu-amigo",
          "make_name": "Isuzu",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Apache",
          "slug": "chevrolet-apache",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Armada",
          "slug": "nissan-armada",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Arnage",
          "slug": "bentley-arnage",
          "make_name": "Bentley",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Arteon",
          "slug": "volkswagen-arteon",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Artura",
          "slug": "mclaren-artura",
          "make_name": "McLaren",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Ascender",
          "slug": "isuzu-ascender",
          "make_name": "Isuzu",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Ascent",
          "slug": "subaru-ascent",
          "make_name": "Subaru",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Aspen",
          "slug": "chrysler-aspen",
          "make_name": "Chrysler",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Aspen",
          "slug": "dodge-aspen",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Aspen Hybrid",
          "slug": "chrysler-aspen_hybrid",
          "make_name": "Chrysler",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Astra",
          "slug": "saturn-astra",
          "make_name": "Saturn",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Astro",
          "slug": "chevrolet-astro",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Atlas",
          "slug": "volkswagen-atlas",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Atlas Cross Sport",
          "slug": "volkswagen-atlas_cross_sport",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Aura",
          "slug": "saturn-aura",
          "make_name": "Saturn",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Aurora",
          "slug": "oldsmobile-aurora",
          "make_name": "Oldsmobile",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Avalanche",
          "slug": "chevrolet-avalanche",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Avalon",
          "slug": "toyota-avalon",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Avalon Hybrid",
          "slug": "toyota-avalon_hybrid",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Avanti",
          "slug": "studebaker-avanti",
          "make_name": "Studebaker",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Avanti",
          "slug": "avanti_motors-avanti",
          "make_name": "Avanti Motors",
          "stock_types": []
      },
      {
          "name": "Avanti II",
          "slug": "avanti_motors-avanti_ii",
          "make_name": "Avanti Motors",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Avenger",
          "slug": "dodge-avenger",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Aventador",
          "slug": "lamborghini-aventador",
          "make_name": "Lamborghini",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Aventador S",
          "slug": "lamborghini-aventador_s",
          "make_name": "Lamborghini",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Aventador SVJ",
          "slug": "lamborghini-aventador_svj",
          "make_name": "Lamborghini",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Aveo",
          "slug": "chevrolet-aveo",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Aviator",
          "slug": "lincoln-aviator",
          "make_name": "Lincoln",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Axiom",
          "slug": "isuzu-axiom",
          "make_name": "Isuzu",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Azera",
          "slug": "hyundai-azera",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Aztek",
          "slug": "pontiac-aztek",
          "make_name": "Pontiac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Azure",
          "slug": "bentley-azure",
          "make_name": "Bentley",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "B-Class",
          "slug": "mercedes_benz-b_class",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "B-Series",
          "slug": "dodge-b_series",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "B2300",
          "slug": "mazda-b2300",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "B2500",
          "slug": "mazda-b2500",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "B2600",
          "slug": "mazda-b2600",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "B3000",
          "slug": "mazda-b3000",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "B4000",
          "slug": "mazda-b4000",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "B9 Tribeca",
          "slug": "subaru-b9_tribeca",
          "make_name": "Subaru",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "BRZ",
          "slug": "subaru-brz",
          "make_name": "Subaru",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Baja",
          "slug": "subaru-baja",
          "make_name": "Subaru",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Barracuda",
          "slug": "plymouth-barracuda",
          "make_name": "Plymouth",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Beetle",
          "slug": "volkswagen-beetle",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Beetle (Pre-1980)",
          "slug": "volkswagen-beetle_pre_1980",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Bel Air",
          "slug": "chevrolet-bel_air",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Belvedere",
          "slug": "plymouth-belvedere",
          "make_name": "Plymouth",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Bentayga",
          "slug": "bentley-bentayga",
          "make_name": "Bentley",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Bentayga EWB",
          "slug": "bentley-bentayga_ewb",
          "make_name": "Bentley",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Bentayga Hybrid",
          "slug": "bentley-bentayga_hybrid",
          "make_name": "Bentley",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Biscayne",
          "slug": "chevrolet-biscayne",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Biturbo",
          "slug": "maserati-biturbo",
          "make_name": "Maserati",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Blackwood",
          "slug": "lincoln-blackwood",
          "make_name": "Lincoln",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Blazer",
          "slug": "chevrolet-blazer",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Blazer EV",
          "slug": "chevrolet-blazer_ev",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Bolt EUV",
          "slug": "chevrolet-bolt_euv",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Bolt EV",
          "slug": "chevrolet-bolt_ev",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Bonneville",
          "slug": "pontiac-bonneville",
          "make_name": "Pontiac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Bora",
          "slug": "maserati-bora",
          "make_name": "Maserati",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Borrego",
          "slug": "kia-borrego",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Boxster",
          "slug": "porsche-boxster",
          "make_name": "Porsche",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Brat",
          "slug": "subaru-brat",
          "make_name": "Subaru",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Bravada",
          "slug": "oldsmobile-bravada",
          "make_name": "Oldsmobile",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Bronco",
          "slug": "ford-bronco",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Bronco II",
          "slug": "ford-bronco_ii",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Bronco Sport",
          "slug": "ford-bronco_sport",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Brooklands",
          "slug": "bentley-brooklands",
          "make_name": "Bentley",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Brougham",
          "slug": "cadillac-brougham",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Brougham",
          "slug": "mercury-brougham",
          "make_name": "Mercury",
          "stock_types": []
      },
      {
          "name": "C-Class",
          "slug": "mercedes_benz-c_class",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "C-HR",
          "slug": "toyota-c_hr",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "C-Max Energi",
          "slug": "ford-c_max_energi",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "C-Max Hybrid",
          "slug": "ford-c_max_hybrid",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "C10/K10",
          "slug": "chevrolet-c10_k10",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "C20/K20",
          "slug": "chevrolet-c20_k20",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "C30",
          "slug": "volvo-c30",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "C40 Recharge Pure Electric",
          "slug": "volvo-c40_recharge_pure_electric",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "C70",
          "slug": "volvo-c70",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "C8",
          "slug": "spyker-c8",
          "make_name": "Spyker",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "CC",
          "slug": "volkswagen-cc",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "CJ",
          "slug": "jeep-cj",
          "make_name": "Jeep",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "CJ-5",
          "slug": "willys-cj_5",
          "make_name": "Willys",
          "stock_types": []
      },
      {
          "name": "CJ-5",
          "slug": "jeep-cj_5",
          "make_name": "Jeep",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "CJ-7",
          "slug": "jeep-cj_7",
          "make_name": "Jeep",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "CL",
          "slug": "acura-cl",
          "make_name": "Acura",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "CL-Class",
          "slug": "mercedes_benz-cl_class",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "CLA 250",
          "slug": "mercedes_benz-cla_250",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "CLA-Class",
          "slug": "mercedes_benz-cla_class",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "CLK-Class",
          "slug": "mercedes_benz-clk_class",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "CLS 450",
          "slug": "mercedes_benz-cls_450",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "CLS 550",
          "slug": "mercedes_benz-cls_550",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "CLS-Class",
          "slug": "mercedes_benz-cls_class",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "CR-V",
          "slug": "honda-cr_v",
          "make_name": "Honda",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "CR-V Hybrid",
          "slug": "honda-cr_v_hybrid",
          "make_name": "Honda",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "CR-Z",
          "slug": "honda-cr_z",
          "make_name": "Honda",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "CRX",
          "slug": "honda-crx",
          "make_name": "Honda",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "CT 200h",
          "slug": "lexus-ct_200h",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "CT4",
          "slug": "cadillac-ct4",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "CT4-V",
          "slug": "cadillac-ct4_v",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "CT5",
          "slug": "cadillac-ct5",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "CT5-V",
          "slug": "cadillac-ct5_v",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "CT6",
          "slug": "cadillac-ct6",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "CT6 PLUG-IN",
          "slug": "cadillac-ct6_plug_in",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "CT6-V",
          "slug": "cadillac-ct6_v",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "CTS",
          "slug": "cadillac-cts",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "CTS-V",
          "slug": "cadillac-cts_v",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "CX-3",
          "slug": "mazda-cx_3",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "CX-30",
          "slug": "mazda-cx_30",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "CX-5",
          "slug": "mazda-cx_5",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "CX-50",
          "slug": "mazda-cx_50",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "CX-7",
          "slug": "mazda-cx_7",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "CX-9",
          "slug": "mazda-cx_9",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "CX-90",
          "slug": "mazda-cx_90",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "CX-90 PHEV",
          "slug": "mazda-cx_90_phev",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Caballero",
          "slug": "gmc-caballero",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Cabrio",
          "slug": "yugo-cabrio",
          "make_name": "Yugo",
          "stock_types": []
      },
      {
          "name": "Cabrio",
          "slug": "volkswagen-cabrio",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Cabriolet",
          "slug": "volkswagen-cabriolet",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Cabriolet",
          "slug": "audi-cabriolet",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Cadenza",
          "slug": "kia-cadenza",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Calais",
          "slug": "cadillac-calais",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Calais",
          "slug": "oldsmobile-calais",
          "make_name": "Oldsmobile",
          "stock_types": []
      },
      {
          "name": "Caliber",
          "slug": "dodge-caliber",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "California",
          "slug": "ferrari-california",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "California",
          "slug": "buick-california",
          "make_name": "Buick",
          "stock_types": []
      },
      {
          "name": "Camaro",
          "slug": "chevrolet-camaro",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Camry",
          "slug": "toyota-camry",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Camry Hybrid",
          "slug": "toyota-camry_hybrid",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Camry Solara",
          "slug": "toyota-camry_solara",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Canyon",
          "slug": "gmc-canyon",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Capri",
          "slug": "lincoln-capri",
          "make_name": "Lincoln",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Capri",
          "slug": "mercury-capri",
          "make_name": "Mercury",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Caprice",
          "slug": "chevrolet-caprice",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Caprice Classic",
          "slug": "chevrolet-caprice_classic",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Captiva Sport",
          "slug": "chevrolet-captiva_sport",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Caravan",
          "slug": "dodge-caravan",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Caravan",
          "slug": "opel-caravan",
          "make_name": "Opel",
          "stock_types": []
      },
      {
          "name": "Cargo",
          "slug": "ram-cargo",
          "make_name": "RAM",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Caribbean",
          "slug": "packard-caribbean",
          "make_name": "Packard",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Carnival",
          "slug": "kia-carnival",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Carrera GT",
          "slug": "porsche-carrera_gt",
          "make_name": "Porsche",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Cascada",
          "slug": "buick-cascada",
          "make_name": "Buick",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Catalina",
          "slug": "pontiac-catalina",
          "make_name": "Pontiac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Catera",
          "slug": "cadillac-catera",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Cavalier",
          "slug": "packard-cavalier",
          "make_name": "Packard",
          "stock_types": []
      },
      {
          "name": "Cavalier",
          "slug": "chevrolet-cavalier",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Cayenne",
          "slug": "porsche-cayenne",
          "make_name": "Porsche",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Cayenne E-Hybrid",
          "slug": "porsche-cayenne_e_hybrid",
          "make_name": "Porsche",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Cayenne E-Hybrid Coupe",
          "slug": "porsche-cayenne_e_hybrid_coupe",
          "make_name": "Porsche",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Cayenne Hybrid",
          "slug": "porsche-cayenne_hybrid",
          "make_name": "Porsche",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Cayman",
          "slug": "porsche-cayman",
          "make_name": "Porsche",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Celica",
          "slug": "toyota-celica",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Century",
          "slug": "buick-century",
          "make_name": "Buick",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Challenge Stradale",
          "slug": "ferrari-challenge_stradale",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Challenger",
          "slug": "studebaker-challenger",
          "make_name": "Studebaker",
          "stock_types": []
      },
      {
          "name": "Challenger",
          "slug": "dodge-challenger",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Champion",
          "slug": "studebaker-champion",
          "make_name": "Studebaker",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Charger",
          "slug": "dodge-charger",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Cherokee",
          "slug": "jeep-cherokee",
          "make_name": "Jeep",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Chevelle",
          "slug": "chevrolet-chevelle",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Chevy II",
          "slug": "chevrolet-chevy_ii",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Chieftain",
          "slug": "pontiac-chieftain",
          "make_name": "Pontiac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Chiron",
          "slug": "bugatti-chiron",
          "make_name": "Bugatti",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "City Express",
          "slug": "chevrolet-city_express",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Civic",
          "slug": "honda-civic",
          "make_name": "Honda",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Civic Hybrid",
          "slug": "honda-civic_hybrid",
          "make_name": "Honda",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Civic Si",
          "slug": "honda-civic_si",
          "make_name": "Honda",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Civic Type R",
          "slug": "honda-civic_type_r",
          "make_name": "Honda",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Clarity Plug-In Hybrid",
          "slug": "honda-clarity_plug_in_hybrid",
          "make_name": "Honda",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Classic",
          "slug": "american_motors-classic",
          "make_name": "American Motors",
          "stock_types": []
      },
      {
          "name": "Classic",
          "slug": "chevrolet-classic",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Clipper",
          "slug": "packard-clipper",
          "make_name": "Packard",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Club Sedan",
          "slug": "ford-club_sedan",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Club Wagon",
          "slug": "ford-club_wagon",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Clubman",
          "slug": "mini-clubman",
          "make_name": "MINI",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Cobalt",
          "slug": "chevrolet-cobalt",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Colony Park",
          "slug": "mercury-colony_park",
          "make_name": "Mercury",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Colorado",
          "slug": "chevrolet-colorado",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Comanche",
          "slug": "jeep-comanche",
          "make_name": "Jeep",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Comet",
          "slug": "mercury-comet",
          "make_name": "Mercury",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Commander",
          "slug": "studebaker-commander",
          "make_name": "Studebaker",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Commander",
          "slug": "jeep-commander",
          "make_name": "Jeep",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Compass",
          "slug": "jeep-compass",
          "make_name": "Jeep",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Concorde",
          "slug": "chrysler-concorde",
          "make_name": "Chrysler",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Confederate",
          "slug": "chevrolet-confederate",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Conquest",
          "slug": "chrysler-conquest",
          "make_name": "Chrysler",
          "stock_types": []
      },
      {
          "name": "Conquest",
          "slug": "dodge-conquest",
          "make_name": "Dodge",
          "stock_types": []
      },
      {
          "name": "Conquest",
          "slug": "plymouth-conquest",
          "make_name": "Plymouth",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Continental",
          "slug": "lincoln-continental",
          "make_name": "Lincoln",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Continental",
          "slug": "bentley-continental",
          "make_name": "Bentley",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Continental Flying Spur",
          "slug": "bentley-continental_flying_spur",
          "make_name": "Bentley",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Continental GT",
          "slug": "bentley-continental_gt",
          "make_name": "Bentley",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Continental GTC",
          "slug": "bentley-continental_gtc",
          "make_name": "Bentley",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Continental Supersports",
          "slug": "bentley-continental_supersports",
          "make_name": "Bentley",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Contour",
          "slug": "ford-contour",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Convertible",
          "slug": "mini-convertible",
          "make_name": "MINI",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Cooper",
          "slug": "austin-cooper",
          "make_name": "Austin",
          "stock_types": []
      },
      {
          "name": "Cooper",
          "slug": "mini-cooper",
          "make_name": "MINI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Cooper Clubman",
          "slug": "mini-cooper_clubman",
          "make_name": "MINI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Cooper Countryman",
          "slug": "mini-cooper_countryman",
          "make_name": "MINI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Cooper S",
          "slug": "mini-cooper_s",
          "make_name": "MINI",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Cooper S Clubman",
          "slug": "mini-cooper_s_clubman",
          "make_name": "MINI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Cooper S Countryman",
          "slug": "mini-cooper_s_countryman",
          "make_name": "MINI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Cordoba",
          "slug": "chrysler-cordoba",
          "make_name": "Chrysler",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Corniche",
          "slug": "rolls_royce-corniche",
          "make_name": "Rolls-Royce",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Corniche",
          "slug": "bentley-corniche",
          "make_name": "Bentley",
          "stock_types": []
      },
      {
          "name": "Corolla",
          "slug": "toyota-corolla",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Corolla Cross",
          "slug": "toyota-corolla_cross",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Corolla Cross Hybrid",
          "slug": "toyota-corolla_cross_hybrid",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Corolla Hatchback",
          "slug": "toyota-corolla_hatchback",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Corolla Hybrid",
          "slug": "toyota-corolla_hybrid",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Corolla iM",
          "slug": "toyota-corolla_im",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Coronet",
          "slug": "dodge-coronet",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Corrado",
          "slug": "volkswagen-corrado",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Corsair",
          "slug": "lincoln-corsair",
          "make_name": "Lincoln",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Corsair",
          "slug": "edsel-corsair",
          "make_name": "Edsel",
          "stock_types": []
      },
      {
          "name": "Corvair",
          "slug": "chevrolet-corvair",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Corvette",
          "slug": "chevrolet-corvette",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Corvette Stingray",
          "slug": "chevrolet-corvette_stingray",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Cosmopolitan",
          "slug": "lincoln-cosmopolitan",
          "make_name": "Lincoln",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Cougar",
          "slug": "mercury-cougar",
          "make_name": "Mercury",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Countach",
          "slug": "lamborghini-countach",
          "make_name": "Lamborghini",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Country Squire",
          "slug": "ford-country_squire",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Countryman",
          "slug": "mini-countryman",
          "make_name": "MINI",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Coupe",
          "slug": "volvo-coupe",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Coupe",
          "slug": "ford-coupe",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Coupe",
          "slug": "mini-coupe",
          "make_name": "MINI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Coupe",
          "slug": "maserati-coupe",
          "make_name": "Maserati",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Cranbrook",
          "slug": "plymouth-cranbrook",
          "make_name": "Plymouth",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Cressida",
          "slug": "toyota-cressida",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Crestline",
          "slug": "ford-crestline",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Crossfire",
          "slug": "chrysler-crossfire",
          "make_name": "Chrysler",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Crosstour",
          "slug": "honda-crosstour",
          "make_name": "Honda",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Crosstrek",
          "slug": "subaru-crosstrek",
          "make_name": "Subaru",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Crosstrek Hybrid",
          "slug": "subaru-crosstrek_hybrid",
          "make_name": "Subaru",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Crown",
          "slug": "toyota-crown",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Crown Victoria",
          "slug": "ford-crown_victoria",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Cruze",
          "slug": "chevrolet-cruze",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Cruze Limited",
          "slug": "chevrolet-cruze_limited",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Cube",
          "slug": "nissan-cube",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Cuda",
          "slug": "plymouth-cuda",
          "make_name": "Plymouth",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Cullinan",
          "slug": "rolls_royce-cullinan",
          "make_name": "Rolls-Royce",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Custom",
          "slug": "lincoln-custom",
          "make_name": "Lincoln",
          "stock_types": []
      },
      {
          "name": "Custom",
          "slug": "pontiac-custom",
          "make_name": "Pontiac",
          "stock_types": []
      },
      {
          "name": "Custom",
          "slug": "desoto-custom",
          "make_name": "Desoto",
          "stock_types": []
      },
      {
          "name": "Custom",
          "slug": "cadillac-custom",
          "make_name": "Cadillac",
          "stock_types": []
      },
      {
          "name": "Custom",
          "slug": "dodge-custom",
          "make_name": "Dodge",
          "stock_types": []
      },
      {
          "name": "Custom",
          "slug": "packard-custom",
          "make_name": "Packard",
          "stock_types": []
      },
      {
          "name": "Custom",
          "slug": "oldsmobile-custom",
          "make_name": "Oldsmobile",
          "stock_types": []
      },
      {
          "name": "Custom",
          "slug": "mercury-custom",
          "make_name": "Mercury",
          "stock_types": []
      },
      {
          "name": "Custom",
          "slug": "ford-custom",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Custom",
          "slug": "hudson-custom",
          "make_name": "Hudson",
          "stock_types": []
      },
      {
          "name": "Custom Deluxe",
          "slug": "ford-custom_deluxe",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Customline",
          "slug": "ford-customline",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Cutlass",
          "slug": "oldsmobile-cutlass",
          "make_name": "Oldsmobile",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Cutlass Calais",
          "slug": "oldsmobile-cutlass_calais",
          "make_name": "Oldsmobile",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Cutlass Ciera",
          "slug": "oldsmobile-cutlass_ciera",
          "make_name": "Oldsmobile",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Cutlass Salon",
          "slug": "oldsmobile-cutlass_salon",
          "make_name": "Oldsmobile",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Cutlass Supreme",
          "slug": "oldsmobile-cutlass_supreme",
          "make_name": "Oldsmobile",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Cyclone",
          "slug": "mercury-cyclone",
          "make_name": "Mercury",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "D100",
          "slug": "dodge-d100",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "D150",
          "slug": "dodge-d150",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "D350",
          "slug": "dodge-d350",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "DB AR1 Zagato",
          "slug": "aston_martin-db_ar1_zagato",
          "make_name": "Aston Martin",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "DB11",
          "slug": "aston_martin-db11",
          "make_name": "Aston Martin",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "DB2/4",
          "slug": "aston_martin-db2_4",
          "make_name": "Aston Martin",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "DB4",
          "slug": "aston_martin-db4",
          "make_name": "Aston Martin",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "DB6",
          "slug": "aston_martin-db6",
          "make_name": "Aston Martin",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "DB7",
          "slug": "aston_martin-db7",
          "make_name": "Aston Martin",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "DB7 Vantage",
          "slug": "aston_martin-db7_vantage",
          "make_name": "Aston Martin",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "DB9",
          "slug": "aston_martin-db9",
          "make_name": "Aston Martin",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "DBS",
          "slug": "aston_martin-dbs",
          "make_name": "Aston Martin",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "DBX",
          "slug": "aston_martin-dbx",
          "make_name": "Aston Martin",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "DMC-12",
          "slug": "delorean-dmc_12",
          "make_name": "Delorean",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "DTS",
          "slug": "cadillac-dts",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Dakota",
          "slug": "dodge-dakota",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Darrin",
          "slug": "kaiser-darrin",
          "make_name": "Kaiser",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Dart",
          "slug": "dodge-dart",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Dawn",
          "slug": "rolls_royce-dawn",
          "make_name": "Rolls-Royce",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Daytona",
          "slug": "ferrari-daytona",
          "make_name": "Ferrari",
          "stock_types": []
      },
      {
          "name": "Daytona",
          "slug": "studebaker-daytona",
          "make_name": "Studebaker",
          "stock_types": []
      },
      {
          "name": "Daytona",
          "slug": "dodge-daytona",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "DeLuxe",
          "slug": "desoto-deluxe",
          "make_name": "Desoto",
          "stock_types": []
      },
      {
          "name": "DeLuxe",
          "slug": "plymouth-deluxe",
          "make_name": "Plymouth",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "DeLuxe",
          "slug": "nash-deluxe",
          "make_name": "Nash",
          "stock_types": []
      },
      {
          "name": "DeLuxe",
          "slug": "hudson-deluxe",
          "make_name": "Hudson",
          "stock_types": []
      },
      {
          "name": "DeVille",
          "slug": "cadillac-deville",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Defender",
          "slug": "oldsmobile-defender",
          "make_name": "Oldsmobile",
          "stock_types": []
      },
      {
          "name": "Defender",
          "slug": "land_rover-defender",
          "make_name": "Land Rover",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Delray",
          "slug": "chevrolet-delray",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Delta 88",
          "slug": "oldsmobile-delta_88",
          "make_name": "Oldsmobile",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Deluxe",
          "slug": "pontiac-deluxe",
          "make_name": "Pontiac",
          "stock_types": []
      },
      {
          "name": "Deluxe",
          "slug": "dodge-deluxe",
          "make_name": "Dodge",
          "stock_types": []
      },
      {
          "name": "Deluxe",
          "slug": "packard-deluxe",
          "make_name": "Packard",
          "stock_types": []
      },
      {
          "name": "Deluxe",
          "slug": "chevrolet-deluxe",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Deluxe",
          "slug": "ford-deluxe",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Diablo",
          "slug": "lamborghini-diablo",
          "make_name": "Lamborghini",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Dictator",
          "slug": "studebaker-dictator",
          "make_name": "Studebaker",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Dino",
          "slug": "ferrari-dino",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Dino 2400",
          "slug": "fiat-dino",
          "make_name": "FIAT",
          "stock_types": []
      },
      {
          "name": "Discovery",
          "slug": "land_rover-discovery",
          "make_name": "Land Rover",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Discovery Sport",
          "slug": "land_rover-discovery_sport",
          "make_name": "Land Rover",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Duetto",
          "slug": "alfa_romeo-duetto",
          "make_name": "Alfa Romeo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Durango",
          "slug": "dodge-durango",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Duster",
          "slug": "plymouth-duster",
          "make_name": "Plymouth",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Dynamic 88",
          "slug": "oldsmobile-dynamic_88",
          "make_name": "Oldsmobile",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "E Class",
          "slug": "chrysler-e_class",
          "make_name": "Chrysler",
          "stock_types": []
      },
      {
          "name": "E Countryman",
          "slug": "mini-e_countryman",
          "make_name": "MINI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "E-Class",
          "slug": "mercedes_benz-e_class",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "E-PACE",
          "slug": "jaguar-e_pace",
          "make_name": "Jaguar",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "E-Transit",
          "slug": "ford-e_transit",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "E-Type",
          "slug": "jaguar-e_type",
          "make_name": "Jaguar",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "E150",
          "slug": "ford-e150",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "E250",
          "slug": "ford-e250",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "E350",
          "slug": "ford-e350",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "E350 Super Duty",
          "slug": "ford-e350_super_duty",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "ECHO",
          "slug": "toyota-echo",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "ELR",
          "slug": "cadillac-elr",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "EQ ForTwo",
          "slug": "smart-eq_fortwo",
          "make_name": "smart",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "EQB 250",
          "slug": "mercedes_benz-eqb_250",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "EQB 300",
          "slug": "mercedes_benz-eqb_300",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "EQB 350",
          "slug": "mercedes_benz-eqb_350",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "EQE 350",
          "slug": "mercedes_benz-eqe_350",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "EQE 350+",
          "slug": "mercedes_benz-eqe_350_plus",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "EQE 500",
          "slug": "mercedes_benz-eqe_500",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "EQS 450",
          "slug": "mercedes_benz-eqs_450",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "EQS 450+",
          "slug": "mercedes_benz-eqs_450_plus",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "EQS 580",
          "slug": "mercedes_benz-eqs_580",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "ES 250",
          "slug": "lexus-es_250",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "ES 300",
          "slug": "lexus-es_300",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "ES 300h",
          "slug": "lexus-es_300h",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "ES 330",
          "slug": "lexus-es_330",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "ES 350",
          "slug": "lexus-es_350",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "EV6",
          "slug": "kia-ev6",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "EV9",
          "slug": "kia-ev9",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "EX35",
          "slug": "infiniti-ex35",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "EX37",
          "slug": "infiniti-ex37",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Eclipse",
          "slug": "mitsubishi-eclipse",
          "make_name": "Mitsubishi",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Eclipse Cross",
          "slug": "mitsubishi-eclipse_cross",
          "make_name": "Mitsubishi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "EcoSport",
          "slug": "ford-ecosport",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Edge",
          "slug": "ford-edge",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Eight",
          "slug": "packard-eight",
          "make_name": "Packard",
          "stock_types": []
      },
      {
          "name": "Eight",
          "slug": "mercury-eight",
          "make_name": "Mercury",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Eight",
          "slug": "bentley-eight",
          "make_name": "Bentley",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Eight",
          "slug": "hudson-eight",
          "make_name": "Hudson",
          "stock_types": []
      },
      {
          "name": "Eighty-Eight",
          "slug": "oldsmobile-eighty_eight",
          "make_name": "Oldsmobile",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "El Camino",
          "slug": "chevrolet-el_camino",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Elantra",
          "slug": "hyundai-elantra",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Elantra GT",
          "slug": "hyundai-elantra_gt",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Elantra HEV",
          "slug": "hyundai-elantra_hev",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Elantra N",
          "slug": "hyundai-elantra_n",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Elantra Touring",
          "slug": "hyundai-elantra_touring",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Eldorado",
          "slug": "cadillac-eldorado",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Electra",
          "slug": "buick-electra",
          "make_name": "Buick",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Electra 225",
          "slug": "buick-electra_225",
          "make_name": "Buick",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Electrified G80",
          "slug": "genesis-electrified_g80",
          "make_name": "Genesis",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Electrified GV70",
          "slug": "genesis-electrified_gv70",
          "make_name": "Genesis",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Element",
          "slug": "honda-element",
          "make_name": "Honda",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Eletre",
          "slug": "lotus-eletre",
          "make_name": "Lotus",
          "stock_types": [
              "all",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Elise",
          "slug": "lotus-elise",
          "make_name": "Lotus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Elite",
          "slug": "lotus-elite",
          "make_name": "Lotus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Elite",
          "slug": "ford-elite",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Elva",
          "slug": "mclaren-elva",
          "make_name": "McLaren",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Emira",
          "slug": "lotus-emira",
          "make_name": "Lotus",
          "stock_types": [
              "all",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Enclave",
          "slug": "buick-enclave",
          "make_name": "Buick",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Encore",
          "slug": "american_motors-encore",
          "make_name": "American Motors",
          "stock_types": []
      },
      {
          "name": "Encore",
          "slug": "buick-encore",
          "make_name": "Buick",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Encore GX",
          "slug": "buick-encore_gx",
          "make_name": "Buick",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Endeavor",
          "slug": "mitsubishi-endeavor",
          "make_name": "Mitsubishi",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Entourage",
          "slug": "hyundai-entourage",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Envision",
          "slug": "buick-envision",
          "make_name": "Buick",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Envista",
          "slug": "buick-envista",
          "make_name": "Buick",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Envoy",
          "slug": "gmc-envoy",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Envoy XL",
          "slug": "gmc-envoy_xl",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Envoy XUV",
          "slug": "gmc-envoy_xuv",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Eos",
          "slug": "volkswagen-eos",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Equator",
          "slug": "suzuki-equator",
          "make_name": "Suzuki",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Equinox",
          "slug": "chevrolet-equinox",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Equus",
          "slug": "hyundai-equus",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Escalade",
          "slug": "cadillac-escalade",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Escalade ESV",
          "slug": "cadillac-escalade_esv",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Escalade EXT",
          "slug": "cadillac-escalade_ext",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Escalade Hybrid",
          "slug": "cadillac-escalade_hybrid",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Escape",
          "slug": "ford-escape",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Escape Hybrid",
          "slug": "ford-escape_hybrid",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Escape PHEV",
          "slug": "ford-escape_phev",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Escort",
          "slug": "ford-escort",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Esprit",
          "slug": "lotus-esprit",
          "make_name": "Lotus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Esprit V8",
          "slug": "lotus-esprit_v8",
          "make_name": "Lotus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Eurovan",
          "slug": "volkswagen-eurovan",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Evora",
          "slug": "lotus-evora",
          "make_name": "Lotus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Evora 400",
          "slug": "lotus-evora_400",
          "make_name": "Lotus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Evora GT",
          "slug": "lotus-evora_gt",
          "make_name": "Lotus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Excursion",
          "slug": "ford-excursion",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Exige S",
          "slug": "lotus-exige_s",
          "make_name": "Lotus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Expedition",
          "slug": "ford-expedition",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Expedition EL",
          "slug": "ford-expedition_el",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Expedition Max",
          "slug": "ford-expedition_max",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Explorer",
          "slug": "ford-explorer",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Explorer Sport",
          "slug": "ford-explorer_sport",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Explorer Sport Trac",
          "slug": "ford-explorer_sport_trac",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Expo",
          "slug": "mitsubishi-expo",
          "make_name": "Mitsubishi",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Express 1500",
          "slug": "chevrolet-express_1500",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Express 2500",
          "slug": "chevrolet-express_2500",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Express 3500",
          "slug": "chevrolet-express_3500",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "F-150",
          "slug": "ford-f_150",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "F-150 Lightning",
          "slug": "ford-f_150_lightning",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "F-250",
          "slug": "ford-f_250",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "F-350",
          "slug": "ford-f_350",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "F-450",
          "slug": "ford-f_450",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "F-PACE",
          "slug": "jaguar-f_pace",
          "make_name": "Jaguar",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "F-TYPE",
          "slug": "jaguar-f_type",
          "make_name": "Jaguar",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "F1",
          "slug": "ford-f1",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "F100",
          "slug": "ford-f100",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "F12berlinetta",
          "slug": "ferrari-f12berlinetta",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "F12tdf",
          "slug": "ferrari-f12tdf",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "F355",
          "slug": "ferrari-f355",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "F40",
          "slug": "ferrari-f40",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "F430",
          "slug": "ferrari-f430",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "F8 Spider",
          "slug": "ferrari-f8_spider",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "F8 Tributo",
          "slug": "ferrari-f8_tributo",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "FF",
          "slug": "ferrari-ff",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "FJ Cruiser",
          "slug": "toyota-fj_cruiser",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "FR-S",
          "slug": "scion-fr_s",
          "make_name": "Scion",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "FX35",
          "slug": "infiniti-fx35",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "FX37",
          "slug": "infiniti-fx37",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "FX45",
          "slug": "infiniti-fx45",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "FX50",
          "slug": "infiniti-fx50",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Fairlane",
          "slug": "ford-fairlane",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Fairlane 500",
          "slug": "ford-fairlane_500",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Falcon",
          "slug": "ford-falcon",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Fiero",
          "slug": "pontiac-fiero",
          "make_name": "Pontiac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Fiesta",
          "slug": "oldsmobile-fiesta",
          "make_name": "Oldsmobile",
          "stock_types": []
      },
      {
          "name": "Fiesta",
          "slug": "ford-fiesta",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Firebird",
          "slug": "pontiac-firebird",
          "make_name": "Pontiac",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Fit",
          "slug": "honda-fit",
          "make_name": "Honda",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Five Hundred",
          "slug": "ford-five_hundred",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Fleetline",
          "slug": "chevrolet-fleetline",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Fleetmaster",
          "slug": "chevrolet-fleetmaster",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Fleetwood",
          "slug": "cadillac-fleetwood",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Flex",
          "slug": "ford-flex",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Flying Spur",
          "slug": "bentley-flying_spur",
          "make_name": "Bentley",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Flying Spur Hybrid",
          "slug": "bentley-flying_spur_hybrid",
          "make_name": "Bentley",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Focus",
          "slug": "ford-focus",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Focus Electric",
          "slug": "ford-focus_electric",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Focus RS",
          "slug": "ford-focus_rs",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Focus ST",
          "slug": "ford-focus_st",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "ForTwo",
          "slug": "smart-fortwo",
          "make_name": "smart",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "ForTwo Electric Drive",
          "slug": "smart-fortwo_electric_drive",
          "make_name": "smart",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Forenza",
          "slug": "suzuki-forenza",
          "make_name": "Suzuki",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Forester",
          "slug": "subaru-forester",
          "make_name": "Subaru",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Forte",
          "slug": "kia-forte",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Forte Koup",
          "slug": "kia-forte_koup",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Freelander",
          "slug": "land_rover-freelander",
          "make_name": "Land Rover",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Freestar",
          "slug": "ford-freestar",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Freestyle",
          "slug": "ford-freestyle",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Frontier",
          "slug": "nissan-frontier",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Fury",
          "slug": "plymouth-fury",
          "make_name": "Plymouth",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Fusion",
          "slug": "ford-fusion",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Fusion Energi",
          "slug": "ford-fusion_energi",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Fusion Hybrid",
          "slug": "ford-fusion_hybrid",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "G 550 4x4 Squared",
          "slug": "mercedes_benz-g_550_4x4_squared",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "G-Class",
          "slug": "mercedes_benz-g_class",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "G20",
          "slug": "infiniti-g20",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "G25",
          "slug": "infiniti-g25",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "G25x",
          "slug": "infiniti-g25x",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "G3",
          "slug": "pontiac-g3",
          "make_name": "Pontiac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "G35",
          "slug": "infiniti-g35",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "G35x",
          "slug": "infiniti-g35x",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "G37",
          "slug": "infiniti-g37",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "G37x",
          "slug": "infiniti-g37x",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "G5",
          "slug": "pontiac-g5",
          "make_name": "Pontiac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "G6",
          "slug": "pontiac-g6",
          "make_name": "Pontiac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "G70",
          "slug": "genesis-g70",
          "make_name": "Genesis",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "G8",
          "slug": "pontiac-g8",
          "make_name": "Pontiac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "G80",
          "slug": "genesis-g80",
          "make_name": "Genesis",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "G90",
          "slug": "genesis-g90",
          "make_name": "Genesis",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "GL-Class",
          "slug": "mercedes_benz-gl_class",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "GLA 250",
          "slug": "mercedes_benz-gla_250",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "GLA-Class",
          "slug": "mercedes_benz-gla_class",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "GLB 250",
          "slug": "mercedes_benz-glb_250",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "GLC 300",
          "slug": "mercedes_benz-glc_300",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "GLC 350e",
          "slug": "mercedes_benz-glc_350e",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "GLC-Class",
          "slug": "mercedes_benz-glc_class",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "GLE 350",
          "slug": "mercedes_benz-gle_350",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "GLE 400",
          "slug": "mercedes_benz-gle_400",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "GLE 450",
          "slug": "mercedes_benz-gle_450",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "GLE 450 Plug-In Hybrid",
          "slug": "mercedes_benz-gle_450_plug_in_hybrid",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "GLE 580",
          "slug": "mercedes_benz-gle_580",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "GLE-Class",
          "slug": "mercedes_benz-gle_class",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "GLK-Class",
          "slug": "mercedes_benz-glk_class",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "GLS 450",
          "slug": "mercedes_benz-gls_450",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "GLS 550",
          "slug": "mercedes_benz-gls_550",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "GLS 580",
          "slug": "mercedes_benz-gls_580",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "GR Corolla",
          "slug": "toyota-gr_corolla",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "GR Supra",
          "slug": "toyota-gr_supra",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "GR86",
          "slug": "toyota-gr86",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "GS 200t",
          "slug": "lexus-gs_200t",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "GS 300",
          "slug": "lexus-gs_300",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "GS 350",
          "slug": "buick-gs_350",
          "make_name": "Buick",
          "stock_types": []
      },
      {
          "name": "GS 350",
          "slug": "lexus-gs_350",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "GS 400",
          "slug": "buick-gs_400",
          "make_name": "Buick",
          "stock_types": []
      },
      {
          "name": "GS 400",
          "slug": "lexus-gs_400",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "GS 430",
          "slug": "lexus-gs_430",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "GS 450h",
          "slug": "lexus-gs_450h",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "GS F",
          "slug": "lexus-gs_f",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "GS-6",
          "slug": "karma-gs_6",
          "make_name": "Karma",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "GT",
          "slug": "ferrari-gt",
          "make_name": "Ferrari",
          "stock_types": []
      },
      {
          "name": "GT",
          "slug": "saab-gt",
          "make_name": "Saab",
          "stock_types": []
      },
      {
          "name": "GT",
          "slug": "jensen-gt",
          "make_name": "Jensen",
          "stock_types": []
      },
      {
          "name": "GT",
          "slug": "opel-gt",
          "make_name": "Opel",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "GT",
          "slug": "ford-gt",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "GT",
          "slug": "audi-gt",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "GT",
          "slug": "mclaren-gt",
          "make_name": "McLaren",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "GT-R",
          "slug": "nissan-gt_r",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "GT40",
          "slug": "ford-gt40",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "GT6",
          "slug": "triumph-gt6",
          "make_name": "Triumph",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "GTC4Lusso",
          "slug": "ferrari-gtc4lusso",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "GTI",
          "slug": "volkswagen-gti",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "GTO",
          "slug": "ferrari-gto",
          "make_name": "Ferrari",
          "stock_types": []
      },
      {
          "name": "GTO",
          "slug": "pontiac-gto",
          "make_name": "Pontiac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "GTV",
          "slug": "alfa_romeo-gtv",
          "make_name": "Alfa Romeo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "GTV-6",
          "slug": "alfa_romeo-gtv_6",
          "make_name": "Alfa Romeo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "GTX",
          "slug": "plymouth-gtx",
          "make_name": "Plymouth",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "GV60",
          "slug": "genesis-gv60",
          "make_name": "Genesis",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "GV70",
          "slug": "genesis-gv70",
          "make_name": "Genesis",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "GV80",
          "slug": "genesis-gv80",
          "make_name": "Genesis",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "GX 460",
          "slug": "lexus-gx_460",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "GX 470",
          "slug": "lexus-gx_470",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Galant",
          "slug": "mitsubishi-galant",
          "make_name": "Mitsubishi",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Galaxie",
          "slug": "ford-galaxie",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Galaxie 500",
          "slug": "ford-galaxie_500",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Galaxie 500 XL",
          "slug": "ford-galaxie_500_xl",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Gallardo",
          "slug": "lamborghini-gallardo",
          "make_name": "Lamborghini",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Genesis",
          "slug": "hyundai-genesis",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Genesis Coupe",
          "slug": "hyundai-genesis_coupe",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Ghibli",
          "slug": "maserati-ghibli",
          "make_name": "Maserati",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Ghost",
          "slug": "rolls_royce-ghost",
          "make_name": "Rolls-Royce",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Giulia",
          "slug": "alfa_romeo-giulia",
          "make_name": "Alfa Romeo",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Giulietta",
          "slug": "alfa_romeo-giulietta",
          "make_name": "Alfa Romeo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Gladiator",
          "slug": "jeep-gladiator",
          "make_name": "Jeep",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Golden Hawk",
          "slug": "studebaker-golden_hawk",
          "make_name": "Studebaker",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Golf",
          "slug": "volkswagen-golf",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Golf Alltrack",
          "slug": "volkswagen-golf_alltrack",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Golf GTI",
          "slug": "volkswagen-golf_gti",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Golf R",
          "slug": "volkswagen-golf_r",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Golf SportWagen",
          "slug": "volkswagen-golf_sportwagen",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Graduate",
          "slug": "alfa_romeo-graduate",
          "make_name": "Alfa Romeo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Gran Torino",
          "slug": "ford-gran_torino",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Gran Turismo Hawk",
          "slug": "studebaker-gran_turismo_hawk",
          "make_name": "Studebaker",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "GranSport",
          "slug": "buick-gransport",
          "make_name": "Buick",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "GranSport",
          "slug": "maserati-gransport",
          "make_name": "Maserati",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "GranSport Spyder",
          "slug": "maserati-gransport_spyder",
          "make_name": "Maserati",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "GranTurismo",
          "slug": "maserati-granturismo",
          "make_name": "Maserati",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Granada",
          "slug": "ford-granada",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Grand Am",
          "slug": "pontiac-grand_am",
          "make_name": "Pontiac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Grand Caravan",
          "slug": "dodge-grand_caravan",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Grand Cherokee",
          "slug": "jeep-grand_cherokee",
          "make_name": "Jeep",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Grand Cherokee 4xe",
          "slug": "jeep-grand_cherokee_4xe",
          "make_name": "Jeep",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Grand Cherokee L",
          "slug": "jeep-grand_cherokee_l",
          "make_name": "Jeep",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Grand Cherokee WK",
          "slug": "jeep-grand_cherokee_wk",
          "make_name": "Jeep",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Grand Highlander",
          "slug": "toyota-grand_highlander",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Grand Highlander Hybrid",
          "slug": "toyota-grand_highlander_hybrid",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Grand Marquis",
          "slug": "mercury-grand_marquis",
          "make_name": "Mercury",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Grand Prix",
          "slug": "pontiac-grand_prix",
          "make_name": "Pontiac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Grand Ville",
          "slug": "pontiac-grand_ville",
          "make_name": "Pontiac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Grand Vitara",
          "slug": "suzuki-grand_vitara",
          "make_name": "Suzuki",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Grand Voyager",
          "slug": "chrysler-grand_voyager",
          "make_name": "Chrysler",
          "stock_types": []
      },
      {
          "name": "Grand Voyager",
          "slug": "plymouth-grand_voyager",
          "make_name": "Plymouth",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Grand Wagoneer",
          "slug": "jeep-grand_wagoneer",
          "make_name": "Jeep",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Grand Wagoneer L",
          "slug": "jeep-grand_wagoneer_l",
          "make_name": "Jeep",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Grecale",
          "slug": "maserati-grecale",
          "make_name": "Maserati",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "H1",
          "slug": "hummer-h1",
          "make_name": "Hummer",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "H1 Alpha",
          "slug": "hummer-h1_alpha",
          "make_name": "Hummer",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "H2",
          "slug": "hummer-h2",
          "make_name": "Hummer",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "H3",
          "slug": "hummer-h3",
          "make_name": "Hummer",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "H3T",
          "slug": "hummer-h3t",
          "make_name": "Hummer",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "HHR",
          "slug": "chevrolet-hhr",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "HR-V",
          "slug": "honda-hr_v",
          "make_name": "Honda",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "HS 250h",
          "slug": "lexus-hs_250h",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "HUMMER EV",
          "slug": "gmc-hummer_ev",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "HUMMER EV SUV",
          "slug": "gmc-hummer_ev_suv",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Hardtop",
          "slug": "mini-hardtop",
          "make_name": "MINI",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Highlander",
          "slug": "toyota-highlander",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Highlander Hybrid",
          "slug": "toyota-highlander_hybrid",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Hombre",
          "slug": "isuzu-hombre",
          "make_name": "Isuzu",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Hornet",
          "slug": "dodge-hornet",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Hornet",
          "slug": "american_motors-hornet",
          "make_name": "American Motors",
          "stock_types": []
      },
      {
          "name": "Hornet",
          "slug": "hudson-hornet",
          "make_name": "Hudson",
          "stock_types": []
      },
      {
          "name": "Huayra",
          "slug": "pagani-huayra",
          "make_name": "Pagani",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Hummer",
          "slug": "am_general-hummer",
          "make_name": "Am General",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Huracan",
          "slug": "lamborghini-huracan",
          "make_name": "Lamborghini",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Huracan EVO",
          "slug": "lamborghini-huracan_evo",
          "make_name": "Lamborghini",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Huracan STO",
          "slug": "lamborghini-huracan_sto",
          "make_name": "Lamborghini",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Huracan Sterrato",
          "slug": "lamborghini-huracan_sterrato",
          "make_name": "Lamborghini",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Huracan Tecnica",
          "slug": "lamborghini-huracan_tecnica",
          "make_name": "Lamborghini",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "I-PACE",
          "slug": "jaguar-i_pace",
          "make_name": "Jaguar",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "I30",
          "slug": "infiniti-i30",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "I35",
          "slug": "infiniti-i35",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "ID.4",
          "slug": "volkswagen-id.4",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "ILX",
          "slug": "acura-ilx",
          "make_name": "Acura",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "ILX Hybrid",
          "slug": "acura-ilx_hybrid",
          "make_name": "Acura",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "IONIQ 5",
          "slug": "hyundai-ioniq_5",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "IONIQ 6",
          "slug": "hyundai-ioniq_6",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "IONIQ EV",
          "slug": "hyundai-ioniq_ev",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "IONIQ Hybrid",
          "slug": "hyundai-ioniq_hybrid",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "IONIQ Plug-In Hybrid",
          "slug": "hyundai-ioniq_plug_in_hybrid",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "IPL G",
          "slug": "infiniti-ipl_g",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "IS 200t",
          "slug": "lexus-is_200t",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "IS 250",
          "slug": "lexus-is_250",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "IS 250C",
          "slug": "lexus-is_250c",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "IS 300",
          "slug": "lexus-is_300",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "IS 350",
          "slug": "lexus-is_350",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "IS 350C",
          "slug": "lexus-is_350c",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "IS 500",
          "slug": "lexus-is_500",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "IS-F",
          "slug": "lexus-is_f",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Impala",
          "slug": "chevrolet-impala",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Impala Limited",
          "slug": "chevrolet-impala_limited",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Impreza",
          "slug": "subaru-impreza",
          "make_name": "Subaru",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Impreza WRX",
          "slug": "subaru-impreza_wrx",
          "make_name": "Subaru",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Impreza WRX STi",
          "slug": "subaru-impreza_wrx_sti",
          "make_name": "Subaru",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Independence",
          "slug": "chevrolet-independence",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Indy",
          "slug": "maserati-indy",
          "make_name": "Maserati",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Insight",
          "slug": "honda-insight",
          "make_name": "Honda",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Integra",
          "slug": "acura-integra",
          "make_name": "Acura",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Interceptor",
          "slug": "jensen-interceptor",
          "make_name": "Jensen",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Intrepid",
          "slug": "dodge-intrepid",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Intrigue",
          "slug": "oldsmobile-intrigue",
          "make_name": "Oldsmobile",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Ion",
          "slug": "saturn-ion",
          "make_name": "Saturn",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Islero",
          "slug": "lamborghini-islero",
          "make_name": "Lamborghini",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "J10",
          "slug": "jeep-j10",
          "make_name": "Jeep",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "J30",
          "slug": "infiniti-j30",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "JX35",
          "slug": "infiniti-jx35",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Jeep M715",
          "slug": "kaiser-jeep_m715",
          "make_name": "Kaiser",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Jeepster",
          "slug": "willys-jeepster",
          "make_name": "Willys",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Jeepster",
          "slug": "jeep-jeepster",
          "make_name": "Jeep",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Jensen-Healey",
          "slug": "jensen-jensen_healey",
          "make_name": "Jensen",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Jetta",
          "slug": "volkswagen-jetta",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Jetta GLI",
          "slug": "volkswagen-jetta_gli",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Jetta Hybrid",
          "slug": "volkswagen-jetta_hybrid",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Jetta SportWagen",
          "slug": "volkswagen-jetta_sportwagen",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Jimmy",
          "slug": "gmc-jimmy",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "John Cooper Works GP",
          "slug": "mini-john_cooper_works_gp",
          "make_name": "MINI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Journey",
          "slug": "dodge-journey",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Juke",
          "slug": "nissan-juke",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "K5",
          "slug": "kia-k5",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "K900",
          "slug": "kia-k900",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Karma",
          "slug": "fisker-karma",
          "make_name": "Fisker",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Karmann Ghia",
          "slug": "volkswagen-karmann_ghia",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Khamsin",
          "slug": "maserati-khamsin",
          "make_name": "Maserati",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Kicks",
          "slug": "nissan-kicks",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Kizashi",
          "slug": "suzuki-kizashi",
          "make_name": "Suzuki",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Kona",
          "slug": "hyundai-kona",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Kona EV",
          "slug": "hyundai-kona_ev",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Kona N",
          "slug": "hyundai-kona_n",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "L",
          "slug": "saturn-l",
          "make_name": "Saturn",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "LC 500",
          "slug": "lexus-lc_500",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "LC 500h",
          "slug": "lexus-lc_500h",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "LFA",
          "slug": "lexus-lfa",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "LR2",
          "slug": "land_rover-lr2",
          "make_name": "Land Rover",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "LR3",
          "slug": "land_rover-lr3",
          "make_name": "Land Rover",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "LR4",
          "slug": "land_rover-lr4",
          "make_name": "Land Rover",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "LS",
          "slug": "lincoln-ls",
          "make_name": "Lincoln",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "LS",
          "slug": "saturn-ls",
          "make_name": "Saturn",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "LS 400",
          "slug": "lexus-ls_400",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "LS 430",
          "slug": "lexus-ls_430",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "LS 460",
          "slug": "lexus-ls_460",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "LS 500",
          "slug": "lexus-ls_500",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "LS 500h",
          "slug": "lexus-ls_500h",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "LS 600h L",
          "slug": "lexus-ls_600h_l",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "LTD",
          "slug": "ford-ltd",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "LX 450",
          "slug": "lexus-lx_450",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "LX 470",
          "slug": "lexus-lx_470",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "LX 570",
          "slug": "lexus-lx_570",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "LX 600",
          "slug": "lexus-lx_600",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "LYRIQ",
          "slug": "cadillac-lyriq",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "LaCrosse",
          "slug": "buick-lacrosse",
          "make_name": "Buick",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "LaFerrari",
          "slug": "ferrari-laferrari",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Lafayette",
          "slug": "nash-lafayette",
          "make_name": "Nash",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Lagonda",
          "slug": "aston_martin-lagonda",
          "make_name": "Aston Martin",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Lancer",
          "slug": "dodge-lancer",
          "make_name": "Dodge",
          "stock_types": []
      },
      {
          "name": "Lancer",
          "slug": "mitsubishi-lancer",
          "make_name": "Mitsubishi",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Lancer Evolution",
          "slug": "mitsubishi-lancer_evolution",
          "make_name": "Mitsubishi",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Lancer Sportback",
          "slug": "mitsubishi-lancer_sportback",
          "make_name": "Mitsubishi",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Land Cruiser",
          "slug": "studebaker-land_cruiser",
          "make_name": "Studebaker",
          "stock_types": []
      },
      {
          "name": "Land Cruiser",
          "slug": "toyota-land_cruiser",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Landaulet",
          "slug": "maybach-landaulet",
          "make_name": "Maybach",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Lark",
          "slug": "studebaker-lark",
          "make_name": "Studebaker",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "LeBaron",
          "slug": "chrysler-lebaron",
          "make_name": "Chrysler",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "LeBaron",
          "slug": "packard-lebaron",
          "make_name": "Packard",
          "stock_types": []
      },
      {
          "name": "LeMans",
          "slug": "pontiac-lemans",
          "make_name": "Pontiac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "LeSabre",
          "slug": "buick-lesabre",
          "make_name": "Buick",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Leaf",
          "slug": "nissan-leaf",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Legacy",
          "slug": "subaru-legacy",
          "make_name": "Subaru",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Leganza",
          "slug": "daewoo-leganza",
          "make_name": "Daewoo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Legend",
          "slug": "acura-legend",
          "make_name": "Acura",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Levante",
          "slug": "maserati-levante",
          "make_name": "Maserati",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Liberty",
          "slug": "jeep-liberty",
          "make_name": "Jeep",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Limited",
          "slug": "oldsmobile-limited",
          "make_name": "Oldsmobile",
          "stock_types": []
      },
      {
          "name": "Limited",
          "slug": "buick-limited",
          "make_name": "Buick",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Loyale",
          "slug": "subaru-loyale",
          "make_name": "Subaru",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Lucerne",
          "slug": "buick-lucerne",
          "make_name": "Buick",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Lumina",
          "slug": "chevrolet-lumina",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "M",
          "slug": "bmw-m",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "M",
          "slug": "international-m",
          "make_name": "International",
          "stock_types": []
      },
      {
          "name": "M-Class",
          "slug": "mercedes_benz-m_class",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "M100",
          "slug": "mercury-m100",
          "make_name": "Mercury",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "M2",
          "slug": "bmw-m2",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "M235",
          "slug": "bmw-m235",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "M235 Gran Coupe",
          "slug": "bmw-m235_gran_coupe",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "M240",
          "slug": "bmw-m240",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "M3",
          "slug": "bmw-m3",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "M340",
          "slug": "bmw-m340",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "M35",
          "slug": "infiniti-m35",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "M35h",
          "slug": "infiniti-m35h",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "M35x",
          "slug": "infiniti-m35x",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "M37",
          "slug": "infiniti-m37",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "M37x",
          "slug": "infiniti-m37x",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "M4",
          "slug": "bmw-m4",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "M440",
          "slug": "bmw-m440",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "M440 Gran Coupe",
          "slug": "bmw-m440_gran_coupe",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "M45",
          "slug": "infiniti-m45",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "M5",
          "slug": "bmw-m5",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "M550",
          "slug": "bmw-m550",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "M56",
          "slug": "infiniti-m56",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "M56x",
          "slug": "infiniti-m56x",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "M6",
          "slug": "bmw-m6",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "M6 Gran Coupe",
          "slug": "bmw-m6_gran_coupe",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "M760",
          "slug": "bmw-m760",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "M8",
          "slug": "bmw-m8",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "M8 Gran Coupe",
          "slug": "bmw-m8_gran_coupe",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "M850",
          "slug": "bmw-m850",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "M850 Gran Coupe",
          "slug": "bmw-m850_gran_coupe",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "MC20",
          "slug": "maserati-mc20",
          "make_name": "Maserati",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "MDX",
          "slug": "acura-mdx",
          "make_name": "Acura",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "MDX Sport Hybrid",
          "slug": "acura-mdx_sport_hybrid",
          "make_name": "Acura",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "MGA",
          "slug": "mg-mga",
          "make_name": "MG",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "MGB",
          "slug": "mg-mgb",
          "make_name": "MG",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "MGC",
          "slug": "mg-mgc",
          "make_name": "MG",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "MKC",
          "slug": "lincoln-mkc",
          "make_name": "Lincoln",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "MKS",
          "slug": "lincoln-mks",
          "make_name": "Lincoln",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "MKT",
          "slug": "lincoln-mkt",
          "make_name": "Lincoln",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "MKX",
          "slug": "lincoln-mkx",
          "make_name": "Lincoln",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "MKZ",
          "slug": "lincoln-mkz",
          "make_name": "Lincoln",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "MKZ Hybrid",
          "slug": "lincoln-mkz_hybrid",
          "make_name": "Lincoln",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "MP4-12C",
          "slug": "mclaren-mp4_12c",
          "make_name": "McLaren",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "MPV",
          "slug": "mazda-mpv",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "MR2",
          "slug": "toyota-mr2",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "MX-30",
          "slug": "mazda-mx_30",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "MX-30 EV",
          "slug": "mazda-mx_30_ev",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "MX-5 Miata",
          "slug": "mazda-mx_5_miata",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "MX-5 Miata RF",
          "slug": "mazda-mx_5_miata_rf",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "MX-6",
          "slug": "mazda-mx_6",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Macan",
          "slug": "porsche-macan",
          "make_name": "Porsche",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Magnum",
          "slug": "dodge-magnum",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Malibu",
          "slug": "chevrolet-malibu",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Malibu Classic",
          "slug": "chevrolet-malibu_classic",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Malibu Hybrid",
          "slug": "chevrolet-malibu_hybrid",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Malibu Limited",
          "slug": "chevrolet-malibu_limited",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Malibu Maxx",
          "slug": "chevrolet-malibu_maxx",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Mangusta",
          "slug": "qvale-mangusta",
          "make_name": "Qvale",
          "stock_types": []
      },
      {
          "name": "Mangusta",
          "slug": "detomaso-mangusta",
          "make_name": "DeTomaso",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Marauder",
          "slug": "mercury-marauder",
          "make_name": "Mercury",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Mariner",
          "slug": "mercury-mariner",
          "make_name": "Mercury",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Mariner Hybrid",
          "slug": "mercury-mariner_hybrid",
          "make_name": "Mercury",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Mark II",
          "slug": "lincoln-mark_ii",
          "make_name": "Lincoln",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Mark II",
          "slug": "jaguar-mark_ii",
          "make_name": "Jaguar",
          "stock_types": []
      },
      {
          "name": "Mark III",
          "slug": "lincoln-mark_iii",
          "make_name": "Lincoln",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Mark IV",
          "slug": "lincoln-mark_iv",
          "make_name": "Lincoln",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Mark IX",
          "slug": "jaguar-mark_ix",
          "make_name": "Jaguar",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Mark LT",
          "slug": "lincoln-mark_lt",
          "make_name": "Lincoln",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Mark V",
          "slug": "lincoln-mark_v",
          "make_name": "Lincoln",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Mark V",
          "slug": "jaguar-mark_v",
          "make_name": "Jaguar",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Mark VI",
          "slug": "lincoln-mark_vi",
          "make_name": "Lincoln",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Mark VI",
          "slug": "bentley-mark_vi",
          "make_name": "Bentley",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Mark VIII",
          "slug": "lincoln-mark_viii",
          "make_name": "Lincoln",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Mark VIII",
          "slug": "jaguar-mark_viii",
          "make_name": "Jaguar",
          "stock_types": []
      },
      {
          "name": "Marquis",
          "slug": "mercury-marquis",
          "make_name": "Mercury",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Master",
          "slug": "buick-master",
          "make_name": "Buick",
          "stock_types": []
      },
      {
          "name": "Master",
          "slug": "chevrolet-master",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Master Deluxe",
          "slug": "chevrolet-master_deluxe",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Matador",
          "slug": "dodge-matador",
          "make_name": "Dodge",
          "stock_types": []
      },
      {
          "name": "Matador",
          "slug": "american_motors-matador",
          "make_name": "American Motors",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Matrix",
          "slug": "toyota-matrix",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Maverick",
          "slug": "willys-maverick",
          "make_name": "Willys",
          "stock_types": []
      },
      {
          "name": "Maverick",
          "slug": "ford-maverick",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Maxima",
          "slug": "datsun-maxima",
          "make_name": "Datsun",
          "stock_types": []
      },
      {
          "name": "Maxima",
          "slug": "nissan-maxima",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Maybach GLS 600",
          "slug": "mercedes_benz-maybach_gls_600",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Maybach S",
          "slug": "mercedes_benz-maybach_s",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Maybach S 560",
          "slug": "mercedes_benz-maybach_s_560",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Maybach S 580",
          "slug": "mercedes_benz-maybach_s_580",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Maybach S 650",
          "slug": "mercedes_benz-maybach_s_650",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Maybach S 680",
          "slug": "mercedes_benz-maybach_s_680",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Mazda2",
          "slug": "mazda-mazda2",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Mazda3",
          "slug": "mazda-mazda3",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Mazda5",
          "slug": "mazda-mazda5",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Mazda6",
          "slug": "mazda-mazda6",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "MazdaSpeed Miata MX-5",
          "slug": "mazda-mazdaspeed_miata_mx_5",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "MazdaSpeed3",
          "slug": "mazda-mazdaspeed3",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "MazdaSpeed6",
          "slug": "mazda-mazdaspeed6",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Medalist",
          "slug": "mercury-medalist",
          "make_name": "Mercury",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Metris",
          "slug": "mercedes_benz-metris",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Metro",
          "slug": "geo-metro",
          "make_name": "Geo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Metro",
          "slug": "chevrolet-metro",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Metropolitan",
          "slug": "american_motors-metropolitan",
          "make_name": "American Motors",
          "stock_types": []
      },
      {
          "name": "Metropolitan",
          "slug": "nash-metropolitan",
          "make_name": "Nash",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Microbus",
          "slug": "volkswagen-microbus",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Midget",
          "slug": "mg-midget",
          "make_name": "MG",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Milan",
          "slug": "mercury-milan",
          "make_name": "Mercury",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Milan Hybrid",
          "slug": "mercury-milan_hybrid",
          "make_name": "Mercury",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Millenia",
          "slug": "mazda-millenia",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Mirage",
          "slug": "mitsubishi-mirage",
          "make_name": "Mitsubishi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Mirage G4",
          "slug": "mitsubishi-mirage_g4",
          "make_name": "Mitsubishi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Mirai",
          "slug": "toyota-mirai",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Mistral",
          "slug": "maserati-mistral",
          "make_name": "Maserati",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Model 18",
          "slug": "packard-model_18",
          "make_name": "Packard",
          "stock_types": []
      },
      {
          "name": "Model 18",
          "slug": "ford-model_18",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Model 3",
          "slug": "tesla-model_3",
          "make_name": "Tesla",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Model 40",
          "slug": "buick-model_40",
          "make_name": "Buick",
          "stock_types": []
      },
      {
          "name": "Model 40",
          "slug": "ford-model_40",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Model 40",
          "slug": "hudson-model_40",
          "make_name": "Hudson",
          "stock_types": []
      },
      {
          "name": "Model A",
          "slug": "studebaker-model_a",
          "make_name": "Studebaker",
          "stock_types": []
      },
      {
          "name": "Model A",
          "slug": "auburn-model_a",
          "make_name": "Auburn",
          "stock_types": []
      },
      {
          "name": "Model A",
          "slug": "cadillac-model_a",
          "make_name": "Cadillac",
          "stock_types": []
      },
      {
          "name": "Model A",
          "slug": "essex-model_a",
          "make_name": "Essex",
          "stock_types": []
      },
      {
          "name": "Model A",
          "slug": "packard-model_a",
          "make_name": "Packard",
          "stock_types": []
      },
      {
          "name": "Model A",
          "slug": "oldsmobile-model_a",
          "make_name": "Oldsmobile",
          "stock_types": []
      },
      {
          "name": "Model A",
          "slug": "ford-model_a",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Model B",
          "slug": "studebaker-model_b",
          "make_name": "Studebaker",
          "stock_types": []
      },
      {
          "name": "Model B",
          "slug": "auburn-model_b",
          "make_name": "Auburn",
          "stock_types": []
      },
      {
          "name": "Model B",
          "slug": "cadillac-model_b",
          "make_name": "Cadillac",
          "stock_types": []
      },
      {
          "name": "Model B",
          "slug": "packard-model_b",
          "make_name": "Packard",
          "stock_types": []
      },
      {
          "name": "Model B",
          "slug": "oldsmobile-model_b",
          "make_name": "Oldsmobile",
          "stock_types": []
      },
      {
          "name": "Model B",
          "slug": "buick-model_b",
          "make_name": "Buick",
          "stock_types": []
      },
      {
          "name": "Model B",
          "slug": "ford-model_b",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Model H",
          "slug": "studebaker-model_h",
          "make_name": "Studebaker",
          "stock_types": []
      },
      {
          "name": "Model H",
          "slug": "auburn-model_h",
          "make_name": "Auburn",
          "stock_types": []
      },
      {
          "name": "Model H",
          "slug": "cadillac-model_h",
          "make_name": "Cadillac",
          "stock_types": []
      },
      {
          "name": "Model H",
          "slug": "hupmobile-model_h",
          "make_name": "Hupmobile",
          "stock_types": []
      },
      {
          "name": "Model H",
          "slug": "oldsmobile-model_h",
          "make_name": "Oldsmobile",
          "stock_types": []
      },
      {
          "name": "Model H",
          "slug": "franklin-model_h",
          "make_name": "Franklin",
          "stock_types": []
      },
      {
          "name": "Model H",
          "slug": "buick-model_h",
          "make_name": "Buick",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Model S",
          "slug": "auburn-model_s",
          "make_name": "Auburn",
          "stock_types": []
      },
      {
          "name": "Model S",
          "slug": "cadillac-model_s",
          "make_name": "Cadillac",
          "stock_types": []
      },
      {
          "name": "Model S",
          "slug": "hupmobile-model_s",
          "make_name": "Hupmobile",
          "stock_types": []
      },
      {
          "name": "Model S",
          "slug": "packard-model_s",
          "make_name": "Packard",
          "stock_types": []
      },
      {
          "name": "Model S",
          "slug": "oldsmobile-model_s",
          "make_name": "Oldsmobile",
          "stock_types": []
      },
      {
          "name": "Model S",
          "slug": "buick-model_s",
          "make_name": "Buick",
          "stock_types": []
      },
      {
          "name": "Model S",
          "slug": "tesla-model_s",
          "make_name": "Tesla",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Model S",
          "slug": "ford-model_s",
          "make_name": "Ford",
          "stock_types": []
      },
      {
          "name": "Model T",
          "slug": "auburn-model_t",
          "make_name": "Auburn",
          "stock_types": []
      },
      {
          "name": "Model T",
          "slug": "cadillac-model_t",
          "make_name": "Cadillac",
          "stock_types": []
      },
      {
          "name": "Model T",
          "slug": "ford-model_t",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Model X",
          "slug": "auburn-model_x",
          "make_name": "Auburn",
          "stock_types": []
      },
      {
          "name": "Model X",
          "slug": "oldsmobile-model_x",
          "make_name": "Oldsmobile",
          "stock_types": []
      },
      {
          "name": "Model X",
          "slug": "tesla-model_x",
          "make_name": "Tesla",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Model Y",
          "slug": "auburn-model_y",
          "make_name": "Auburn",
          "stock_types": []
      },
      {
          "name": "Model Y",
          "slug": "tesla-model_y",
          "make_name": "Tesla",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Monaco",
          "slug": "dodge-monaco",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Mondial",
          "slug": "ferrari-mondial",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Mondial t",
          "slug": "ferrari-mondial_t",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Montana",
          "slug": "pontiac-montana",
          "make_name": "Pontiac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Montana SV6",
          "slug": "pontiac-montana_sv6",
          "make_name": "Pontiac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Montclair",
          "slug": "mercury-montclair",
          "make_name": "Mercury",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Monte Carlo",
          "slug": "saab-monte_carlo",
          "make_name": "Saab",
          "stock_types": []
      },
      {
          "name": "Monte Carlo",
          "slug": "chevrolet-monte_carlo",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Montego",
          "slug": "mercury-montego",
          "make_name": "Mercury",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Monterey",
          "slug": "mercury-monterey",
          "make_name": "Mercury",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Montero",
          "slug": "mitsubishi-montero",
          "make_name": "Mitsubishi",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Montero Sport",
          "slug": "mitsubishi-montero_sport",
          "make_name": "Mitsubishi",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Mountaineer",
          "slug": "mercury-mountaineer",
          "make_name": "Mercury",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Mulsanne",
          "slug": "bentley-mulsanne",
          "make_name": "Bentley",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Murano",
          "slug": "nissan-murano",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Murano CrossCabriolet",
          "slug": "nissan-murano_crosscabriolet",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Murano Hybrid",
          "slug": "nissan-murano_hybrid",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Murcielago",
          "slug": "lamborghini-murcielago",
          "make_name": "Lamborghini",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Mustang",
          "slug": "ford-mustang",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Mustang Mach-E",
          "slug": "ford-mustang_mach_e",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Mustang Shelby GT",
          "slug": "ford-mustang_shelby_gt",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Mystique",
          "slug": "mercury-mystique",
          "make_name": "Mercury",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "NEXO",
          "slug": "hyundai-nexo",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "NSX",
          "slug": "acura-nsx",
          "make_name": "Acura",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "NV Cargo",
          "slug": "nissan-nv_cargo",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "NV Cargo NV1500",
          "slug": "nissan-nv_cargo_nv1500",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "NV Cargo NV2500 HD",
          "slug": "nissan-nv_cargo_nv2500_hd",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "NV Cargo NV3500 HD",
          "slug": "nissan-nv_cargo_nv3500_hd",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "NV Passenger",
          "slug": "nissan-nv_passenger",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "NV Passenger NV3500 HD",
          "slug": "nissan-nv_passenger_nv3500_hd",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "NV200",
          "slug": "nissan-nv200",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "NX",
          "slug": "nissan-nx",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "NX 200t",
          "slug": "lexus-nx_200t",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "NX 250",
          "slug": "lexus-nx_250",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "NX 300",
          "slug": "lexus-nx_300",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "NX 300h",
          "slug": "lexus-nx_300h",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "NX 350",
          "slug": "lexus-nx_350",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "NX 350h",
          "slug": "lexus-nx_350h",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "NX 450h+",
          "slug": "lexus-nx_450h_plus",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Nautilus",
          "slug": "lincoln-nautilus",
          "make_name": "Lincoln",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Navigator",
          "slug": "lincoln-navigator",
          "make_name": "Lincoln",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Navigator L",
          "slug": "lincoln-navigator_l",
          "make_name": "Lincoln",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Neon",
          "slug": "dodge-neon",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Neon",
          "slug": "plymouth-neon",
          "make_name": "Plymouth",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "New Beetle",
          "slug": "volkswagen-new_beetle",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "New Compass",
          "slug": "jeep-new_compass",
          "make_name": "Jeep",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "New Yorker",
          "slug": "chrysler-new_yorker",
          "make_name": "Chrysler",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Ninety-Eight",
          "slug": "oldsmobile-ninety_eight",
          "make_name": "Oldsmobile",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Niro",
          "slug": "kia-niro",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Niro EV",
          "slug": "kia-niro_ev",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Niro Plug-In Hybrid",
          "slug": "kia-niro_plug_in_hybrid",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Nitro",
          "slug": "dodge-nitro",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Nomad",
          "slug": "chevrolet-nomad",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Nova",
          "slug": "chevrolet-nova",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Ocean",
          "slug": "fisker-ocean",
          "make_name": "Fisker",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Odyssey",
          "slug": "honda-odyssey",
          "make_name": "Honda",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Optima",
          "slug": "kia-optima",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Optima Hybrid",
          "slug": "kia-optima_hybrid",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Optima Plug-In Hybrid",
          "slug": "kia-optima_plug_in_hybrid",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Outback",
          "slug": "subaru-outback",
          "make_name": "Subaru",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Outlander",
          "slug": "mitsubishi-outlander",
          "make_name": "Mitsubishi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Outlander PHEV",
          "slug": "mitsubishi-outlander_phev",
          "make_name": "Mitsubishi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Outlander Sport",
          "slug": "mitsubishi-outlander_sport",
          "make_name": "Mitsubishi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Outlook",
          "slug": "saturn-outlook",
          "make_name": "Saturn",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "P1",
          "slug": "mclaren-p1",
          "make_name": "McLaren",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "PT Cruiser",
          "slug": "chrysler-pt_cruiser",
          "make_name": "Chrysler",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Pacemaker",
          "slug": "hudson-pacemaker",
          "make_name": "Hudson",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Paceman",
          "slug": "mini-paceman",
          "make_name": "MINI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Pacer",
          "slug": "american_motors-pacer",
          "make_name": "American Motors",
          "stock_types": []
      },
      {
          "name": "Pacer",
          "slug": "edsel-pacer",
          "make_name": "Edsel",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Pacifica",
          "slug": "chrysler-pacifica",
          "make_name": "Chrysler",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Pacifica Hybrid",
          "slug": "chrysler-pacifica_hybrid",
          "make_name": "Chrysler",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Palisade",
          "slug": "hyundai-palisade",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Panamera",
          "slug": "porsche-panamera",
          "make_name": "Porsche",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Panamera E-Hybrid",
          "slug": "porsche-panamera_e_hybrid",
          "make_name": "Porsche",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Panamera E-Hybrid Sport Turismo",
          "slug": "porsche-panamera_e_hybrid_sport_turismo",
          "make_name": "Porsche",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Panamera Hybrid",
          "slug": "porsche-panamera_hybrid",
          "make_name": "Porsche",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Panamera Sport Turismo",
          "slug": "porsche-panamera_sport_turismo",
          "make_name": "Porsche",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Panel",
          "slug": "dodge-panel",
          "make_name": "Dodge",
          "stock_types": []
      },
      {
          "name": "Panel",
          "slug": "chevrolet-panel",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Panel",
          "slug": "willys-panel",
          "make_name": "Willys",
          "stock_types": []
      },
      {
          "name": "Panel",
          "slug": "gmc-panel",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Panel",
          "slug": "ford-panel",
          "make_name": "Ford",
          "stock_types": []
      },
      {
          "name": "Panel",
          "slug": "hudson-panel",
          "make_name": "Hudson",
          "stock_types": []
      },
      {
          "name": "Pantera",
          "slug": "detomaso-pantera",
          "make_name": "DeTomaso",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Parisienne",
          "slug": "pontiac-parisienne",
          "make_name": "Pontiac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Park Avenue",
          "slug": "buick-park_avenue",
          "make_name": "Buick",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Park Ward",
          "slug": "rolls_royce-park_ward",
          "make_name": "Rolls-Royce",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Parklane",
          "slug": "mercury-parklane",
          "make_name": "Mercury",
          "stock_types": []
      },
      {
          "name": "Parklane",
          "slug": "ford-parklane",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Passat",
          "slug": "volkswagen-passat",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Passport",
          "slug": "honda-passport",
          "make_name": "Honda",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Pathfinder",
          "slug": "nissan-pathfinder",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Pathfinder Hybrid",
          "slug": "nissan-pathfinder_hybrid",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Patriot",
          "slug": "jeep-patriot",
          "make_name": "Jeep",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Phaeton",
          "slug": "ford-phaeton",
          "make_name": "Ford",
          "stock_types": []
      },
      {
          "name": "Phaeton",
          "slug": "volkswagen-phaeton",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Phantom",
          "slug": "rolls_royce-phantom",
          "make_name": "Rolls-Royce",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Phantom Coupe",
          "slug": "rolls_royce-phantom_coupe",
          "make_name": "Rolls-Royce",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Phantom Drophead Coupe",
          "slug": "rolls_royce-phantom_drophead_coupe",
          "make_name": "Rolls-Royce",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Phantom V",
          "slug": "rolls_royce-phantom_v",
          "make_name": "Rolls-Royce",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Phantom VI",
          "slug": "rolls_royce-phantom_vi",
          "make_name": "Rolls-Royce",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Phoenix",
          "slug": "pontiac-phoenix",
          "make_name": "Pontiac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Phoenix",
          "slug": "dodge-phoenix",
          "make_name": "Dodge",
          "stock_types": []
      },
      {
          "name": "Pickup Truck",
          "slug": "isuzu-pickup_truck",
          "make_name": "Isuzu",
          "stock_types": []
      },
      {
          "name": "Pickup Truck",
          "slug": "studebaker-pickup_truck",
          "make_name": "Studebaker",
          "stock_types": []
      },
      {
          "name": "Pickup Truck",
          "slug": "dodge-pickup_truck",
          "make_name": "Dodge",
          "stock_types": []
      },
      {
          "name": "Pickup Truck",
          "slug": "mazda-pickup_truck",
          "make_name": "Mazda",
          "stock_types": []
      },
      {
          "name": "Pickup Truck",
          "slug": "mitsubishi-pickup_truck",
          "make_name": "Mitsubishi",
          "stock_types": []
      },
      {
          "name": "Pickup Truck",
          "slug": "chevrolet-pickup_truck",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Pickup Truck",
          "slug": "willys-pickup_truck",
          "make_name": "Willys",
          "stock_types": []
      },
      {
          "name": "Pickup Truck",
          "slug": "plymouth-pickup_truck",
          "make_name": "Plymouth",
          "stock_types": []
      },
      {
          "name": "Pickup Truck",
          "slug": "nissan-pickup_truck",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Pickup Truck",
          "slug": "international-pickup_truck",
          "make_name": "International",
          "stock_types": []
      },
      {
          "name": "Pickup Truck",
          "slug": "gmc-pickup_truck",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Pickup Truck",
          "slug": "ford-pickup_truck",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Pickup Truck",
          "slug": "toyota-pickup_truck",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Pickup Truck",
          "slug": "hudson-pickup_truck",
          "make_name": "Hudson",
          "stock_types": []
      },
      {
          "name": "Pilot",
          "slug": "honda-pilot",
          "make_name": "Honda",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Pinto",
          "slug": "ford-pinto",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Plus 4",
          "slug": "morgan-plus_4",
          "make_name": "Morgan",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Polara",
          "slug": "dodge-polara",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Portofino",
          "slug": "ferrari-portofino",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Portofino M",
          "slug": "ferrari-portofino_m",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Prelude",
          "slug": "honda-prelude",
          "make_name": "Honda",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "President",
          "slug": "studebaker-president",
          "make_name": "Studebaker",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Previa",
          "slug": "toyota-previa",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Prius",
          "slug": "toyota-prius",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Prius Plug-in",
          "slug": "toyota-prius_plug_in",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Prius Prime",
          "slug": "toyota-prius_prime",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Prius c",
          "slug": "toyota-prius_c",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Prius v",
          "slug": "toyota-prius_v",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Prizm",
          "slug": "geo-prizm",
          "make_name": "Geo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Prizm",
          "slug": "chevrolet-prizm",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "ProMaster 1500",
          "slug": "ram-promaster_1500",
          "make_name": "RAM",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "ProMaster 2500",
          "slug": "ram-promaster_2500",
          "make_name": "RAM",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "ProMaster 2500 Window Van",
          "slug": "ram-promaster_2500_window_van",
          "make_name": "RAM",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "ProMaster 3500",
          "slug": "ram-promaster_3500",
          "make_name": "RAM",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "ProMaster 3500 Window Van",
          "slug": "ram-promaster_3500_window_van",
          "make_name": "RAM",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "ProMaster City",
          "slug": "ram-promaster_city",
          "make_name": "RAM",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Protege",
          "slug": "mazda-protege",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Protege5",
          "slug": "mazda-protege5",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Prowler",
          "slug": "chrysler-prowler",
          "make_name": "Chrysler",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Prowler",
          "slug": "plymouth-prowler",
          "make_name": "Plymouth",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Q3",
          "slug": "audi-q3",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Q4 e-tron",
          "slug": "audi-q4_e_tron",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Q4 e-tron Sportback",
          "slug": "audi-q4_e_tron_sportback",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Q40",
          "slug": "infiniti-q40",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Q45",
          "slug": "infiniti-q45",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Q5",
          "slug": "audi-q5",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Q5 Sportback",
          "slug": "audi-q5_sportback",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Q5 e",
          "slug": "audi-q5_e",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Q5 hybrid",
          "slug": "audi-q5_hybrid",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Q50",
          "slug": "infiniti-q50",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Q50 Hybrid",
          "slug": "infiniti-q50_hybrid",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Q60",
          "slug": "infiniti-q60",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Q60 IPL",
          "slug": "infiniti-q60_ipl",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Q7",
          "slug": "audi-q7",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Q70",
          "slug": "infiniti-q70",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Q70L",
          "slug": "infiniti-q70l",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Q8",
          "slug": "audi-q8",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Q8 e-tron",
          "slug": "audi-q8_e_tron",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Q8 e-tron Sportback",
          "slug": "audi-q8_e_tron_sportback",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "QX30",
          "slug": "infiniti-qx30",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "QX4",
          "slug": "infiniti-qx4",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "QX50",
          "slug": "infiniti-qx50",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "QX55",
          "slug": "infiniti-qx55",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "QX56",
          "slug": "infiniti-qx56",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "QX60",
          "slug": "infiniti-qx60",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "QX60 Hybrid",
          "slug": "infiniti-qx60_hybrid",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "QX70",
          "slug": "infiniti-qx70",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "QX80",
          "slug": "infiniti-qx80",
          "make_name": "INFINITI",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Quattroporte",
          "slug": "maserati-quattroporte",
          "make_name": "Maserati",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Quest",
          "slug": "nissan-quest",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "R-Class",
          "slug": "mercedes_benz-r_class",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "R-Type",
          "slug": "bentley-r_type",
          "make_name": "Bentley",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "R1S",
          "slug": "rivian-r1s",
          "make_name": "Rivian",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "R1T",
          "slug": "rivian-r1t",
          "make_name": "Rivian",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "R32",
          "slug": "volkswagen-r32",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "R8",
          "slug": "renault-r8",
          "make_name": "Renault",
          "stock_types": []
      },
      {
          "name": "R8",
          "slug": "audi-r8",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "RAV4",
          "slug": "toyota-rav4",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "RAV4 EV",
          "slug": "toyota-rav4_ev",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "RAV4 Hybrid",
          "slug": "toyota-rav4_hybrid",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "RAV4 Prime",
          "slug": "toyota-rav4_prime",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "RC 200t",
          "slug": "lexus-rc_200t",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "RC 300",
          "slug": "lexus-rc_300",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "RC 350",
          "slug": "lexus-rc_350",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "RC F",
          "slug": "lexus-rc_f",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "RDX",
          "slug": "acura-rdx",
          "make_name": "Acura",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "RL",
          "slug": "acura-rl",
          "make_name": "Acura",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "RLX",
          "slug": "acura-rlx",
          "make_name": "Acura",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "RLX Sport Hybrid",
          "slug": "acura-rlx_sport_hybrid",
          "make_name": "Acura",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "RS 3",
          "slug": "audi-rs_3",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "RS 4",
          "slug": "audi-rs_4",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "RS 5",
          "slug": "audi-rs_5",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "RS 6 Avant",
          "slug": "audi-rs_6_avant",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "RS 7",
          "slug": "audi-rs_7",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "RS Q8",
          "slug": "audi-rs_q8",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "RS e-tron GT",
          "slug": "audi-rs_e_tron_gt",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "RS6",
          "slug": "audi-rs6",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "RSX",
          "slug": "acura-rsx",
          "make_name": "Acura",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "RX 300",
          "slug": "lexus-rx_300",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "RX 330",
          "slug": "lexus-rx_330",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "RX 350",
          "slug": "lexus-rx_350",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "RX 350L",
          "slug": "lexus-rx_350l",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "RX 350h",
          "slug": "lexus-rx_350h",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "RX 400h",
          "slug": "lexus-rx_400h",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "RX 450h",
          "slug": "lexus-rx_450h",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "RX 450h+",
          "slug": "lexus-rx_450h_plus",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "RX 450hL",
          "slug": "lexus-rx_450hl",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "RX 500h",
          "slug": "lexus-rx_500h",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "RX-7",
          "slug": "mazda-rx_7",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "RX-8",
          "slug": "mazda-rx_8",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "RZ 450e",
          "slug": "lexus-rz_450e",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Rabbit",
          "slug": "volkswagen-rabbit",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Raider",
          "slug": "dodge-raider",
          "make_name": "Dodge",
          "stock_types": []
      },
      {
          "name": "Raider",
          "slug": "mitsubishi-raider",
          "make_name": "Mitsubishi",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Rainier",
          "slug": "buick-rainier",
          "make_name": "Buick",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Rally",
          "slug": "gmc-rally",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Ram 1500",
          "slug": "dodge-ram_1500",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Ram 2500",
          "slug": "dodge-ram_2500",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Ram 3500",
          "slug": "dodge-ram_3500",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Ram Van",
          "slug": "dodge-ram_van",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Ram Wagon",
          "slug": "dodge-ram_wagon",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Rambler",
          "slug": "american_motors-rambler",
          "make_name": "American Motors",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Rambler",
          "slug": "nash-rambler",
          "make_name": "Nash",
          "stock_types": []
      },
      {
          "name": "Ramcharger",
          "slug": "dodge-ramcharger",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Ranchero",
          "slug": "ford-ranchero",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Range Rover",
          "slug": "land_rover-range_rover",
          "make_name": "Land Rover",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Range Rover Evoque",
          "slug": "land_rover-range_rover_evoque",
          "make_name": "Land Rover",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Range Rover Sport",
          "slug": "land_rover-range_rover_sport",
          "make_name": "Land Rover",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Range Rover Velar",
          "slug": "land_rover-range_rover_velar",
          "make_name": "Land Rover",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Ranger",
          "slug": "edsel-ranger",
          "make_name": "Edsel",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Ranger",
          "slug": "ford-ranger",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Rapide",
          "slug": "aston_martin-rapide",
          "make_name": "Aston Martin",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Rapide S",
          "slug": "aston_martin-rapide_s",
          "make_name": "Aston Martin",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Reatta",
          "slug": "buick-reatta",
          "make_name": "Buick",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Regal",
          "slug": "studebaker-regal",
          "make_name": "Studebaker",
          "stock_types": []
      },
      {
          "name": "Regal",
          "slug": "buick-regal",
          "make_name": "Buick",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Regal Sportback",
          "slug": "buick-regal_sportback",
          "make_name": "Buick",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Regal TourX",
          "slug": "buick-regal_tourx",
          "make_name": "Buick",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Regency",
          "slug": "oldsmobile-regency",
          "make_name": "Oldsmobile",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Regera",
          "slug": "koenigsegg-regera",
          "make_name": "Koenigsegg",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Relay",
          "slug": "saturn-relay",
          "make_name": "Saturn",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Rendezvous",
          "slug": "buick-rendezvous",
          "make_name": "Buick",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Renegade",
          "slug": "jeep-renegade",
          "make_name": "Jeep",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Reno",
          "slug": "suzuki-reno",
          "make_name": "Suzuki",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Revero",
          "slug": "karma-revero",
          "make_name": "Karma",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Revero GT",
          "slug": "karma-revero_gt",
          "make_name": "Karma",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Ridgeline",
          "slug": "honda-ridgeline",
          "make_name": "Honda",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Rio",
          "slug": "kia-rio",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Rio5",
          "slug": "kia-rio5",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Riviera",
          "slug": "buick-riviera",
          "make_name": "Buick",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Roadmaster",
          "slug": "buick-roadmaster",
          "make_name": "Buick",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Roadrunner",
          "slug": "plymouth-roadrunner",
          "make_name": "Plymouth",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Roadster",
          "slug": "panoz-roadster",
          "make_name": "Panoz",
          "stock_types": []
      },
      {
          "name": "Roadster",
          "slug": "morgan-roadster",
          "make_name": "Morgan",
          "stock_types": []
      },
      {
          "name": "Roadster",
          "slug": "tesla-roadster",
          "make_name": "Tesla",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Roadster",
          "slug": "ford-roadster",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Roadster",
          "slug": "mini-roadster",
          "make_name": "MINI",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Rodeo",
          "slug": "isuzu-rodeo",
          "make_name": "Isuzu",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Rogue",
          "slug": "american_motors-rogue",
          "make_name": "American Motors",
          "stock_types": []
      },
      {
          "name": "Rogue",
          "slug": "nissan-rogue",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Rogue Hybrid",
          "slug": "nissan-rogue_hybrid",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Rogue Select",
          "slug": "nissan-rogue_select",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Rogue Sport",
          "slug": "nissan-rogue_sport",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Roma",
          "slug": "ferrari-roma",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Rondo",
          "slug": "kia-rondo",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Routan",
          "slug": "volkswagen-routan",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Royal",
          "slug": "dodge-royal",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "S-10",
          "slug": "chevrolet-s_10",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "S-10 Blazer",
          "slug": "chevrolet-s_10_blazer",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "S-Class",
          "slug": "mercedes_benz-s_class",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "S-Type",
          "slug": "jaguar-s_type",
          "make_name": "Jaguar",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "S1",
          "slug": "bentley-s1",
          "make_name": "Bentley",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "S2000",
          "slug": "honda-s2000",
          "make_name": "Honda",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "S3",
          "slug": "bentley-s3",
          "make_name": "Bentley",
          "stock_types": []
      },
      {
          "name": "S3",
          "slug": "audi-s3",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "S4",
          "slug": "audi-s4",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "S40",
          "slug": "volvo-s40",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "S5",
          "slug": "audi-s5",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "S6",
          "slug": "audi-s6",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "S60",
          "slug": "volvo-s60",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "S60 Cross Country",
          "slug": "volvo-s60_cross_country",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "S60 Hybrid",
          "slug": "volvo-s60_hybrid",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "S60 Inscription",
          "slug": "volvo-s60_inscription",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "S60 Recharge Plug-In Hybrid",
          "slug": "volvo-s60_recharge_plug_in_hybrid",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "S7",
          "slug": "saleen-s7",
          "make_name": "Saleen",
          "stock_types": []
      },
      {
          "name": "S7",
          "slug": "audi-s7",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "S70",
          "slug": "volvo-s70",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "S8",
          "slug": "audi-s8",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "S80",
          "slug": "volvo-s80",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "S90",
          "slug": "porsche-s90",
          "make_name": "Porsche",
          "stock_types": []
      },
      {
          "name": "S90",
          "slug": "volvo-s90",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "S90 Recharge Plug-In Hybrid",
          "slug": "volvo-s90_recharge_plug_in_hybrid",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "SC",
          "slug": "desoto-sc",
          "make_name": "Desoto",
          "stock_types": []
      },
      {
          "name": "SC",
          "slug": "saturn-sc",
          "make_name": "Saturn",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "SC 300",
          "slug": "lexus-sc_300",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "SC 400",
          "slug": "lexus-sc_400",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "SC 430",
          "slug": "lexus-sc_430",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "SE Countryman",
          "slug": "mini-se_countryman",
          "make_name": "MINI",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "SE Hardtop",
          "slug": "mini-se_hardtop",
          "make_name": "MINI",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "SF90 Spider",
          "slug": "ferrari-sf90_spider",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "SF90 Stradale",
          "slug": "ferrari-sf90_stradale",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "SL",
          "slug": "saturn-sl",
          "make_name": "Saturn",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "SL 450",
          "slug": "mercedes_benz-sl_450",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "SL 550",
          "slug": "mercedes_benz-sl_550",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "SL-Class",
          "slug": "mercedes_benz-sl_class",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "SLC 300",
          "slug": "mercedes_benz-slc_300",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "SLK-Class",
          "slug": "mercedes_benz-slk_class",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "SLR McLaren",
          "slug": "mercedes_benz-slr_mclaren",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "SLS AMG",
          "slug": "mercedes_benz-sls_amg",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "SQ5",
          "slug": "audi-sq5",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "SQ5 Sportback",
          "slug": "audi-sq5_sportback",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "SQ7",
          "slug": "audi-sq7",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "SQ8",
          "slug": "audi-sq8",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "SQ8 e-tron",
          "slug": "audi-sq8_e_tron",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "SQ8 e-tron Sportback",
          "slug": "audi-sq8_e_tron_sportback",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "SRT Viper",
          "slug": "dodge-srt_viper",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "SRX",
          "slug": "cadillac-srx",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "SS",
          "slug": "chevrolet-ss",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "SSR",
          "slug": "chevrolet-ssr",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "STI S209",
          "slug": "subaru-sti_s209",
          "make_name": "Subaru",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "STS",
          "slug": "cadillac-sts",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "SW",
          "slug": "saturn-sw",
          "make_name": "Saturn",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "SX4",
          "slug": "suzuki-sx4",
          "make_name": "Suzuki",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Sable",
          "slug": "mercury-sable",
          "make_name": "Mercury",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Safari",
          "slug": "pontiac-safari",
          "make_name": "Pontiac",
          "stock_types": []
      },
      {
          "name": "Safari",
          "slug": "gmc-safari",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Samurai",
          "slug": "suzuki-samurai",
          "make_name": "Suzuki",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Santa Cruz",
          "slug": "hyundai-santa_cruz",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Santa Fe",
          "slug": "hyundai-santa_fe",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Santa Fe HEV",
          "slug": "hyundai-santa_fe_hev",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Santa Fe Plug-In Hybrid",
          "slug": "hyundai-santa_fe_plug_in_hybrid",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Santa Fe Sport",
          "slug": "hyundai-santa_fe_sport",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Santa Fe XL",
          "slug": "hyundai-santa_fe_xl",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Satellite",
          "slug": "plymouth-satellite",
          "make_name": "Plymouth",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Savana 1500",
          "slug": "gmc-savana_1500",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Savana 2500",
          "slug": "gmc-savana_2500",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Savana 3500",
          "slug": "gmc-savana_3500",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Savoy",
          "slug": "plymouth-savoy",
          "make_name": "Plymouth",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Scirocco",
          "slug": "volkswagen-scirocco",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Scout",
          "slug": "international-scout",
          "make_name": "International",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Scout II",
          "slug": "international-scout_ii",
          "make_name": "International",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Scrambler",
          "slug": "jeep-scrambler",
          "make_name": "Jeep",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Sebring",
          "slug": "chrysler-sebring",
          "make_name": "Chrysler",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Sebring",
          "slug": "plymouth-sebring",
          "make_name": "Plymouth",
          "stock_types": []
      },
      {
          "name": "Sebring",
          "slug": "maserati-sebring",
          "make_name": "Maserati",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Sedan Delivery",
          "slug": "chevrolet-sedan_delivery",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Sedan Delivery",
          "slug": "plymouth-sedan_delivery",
          "make_name": "Plymouth",
          "stock_types": []
      },
      {
          "name": "Sedan Delivery",
          "slug": "ford-sedan_delivery",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Sedan Police Interceptor",
          "slug": "ford-sedan_police_interceptor",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Sedona",
          "slug": "kia-sedona",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Seltos",
          "slug": "kia-seltos",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Senna",
          "slug": "mclaren-senna",
          "make_name": "McLaren",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Sentra",
          "slug": "datsun-sentra",
          "make_name": "Datsun",
          "stock_types": []
      },
      {
          "name": "Sentra",
          "slug": "nissan-sentra",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Sephia",
          "slug": "kia-sephia",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Sequoia",
          "slug": "toyota-sequoia",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Series 60",
          "slug": "cadillac-series_60",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Series 60",
          "slug": "buick-series_60",
          "make_name": "Buick",
          "stock_types": []
      },
      {
          "name": "Series 62",
          "slug": "cadillac-series_62",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Series 70",
          "slug": "cadillac-series_70",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Series II",
          "slug": "land_rover-series_ii",
          "make_name": "Land Rover",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Series III",
          "slug": "land_rover-series_iii",
          "make_name": "Land Rover",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Seville",
          "slug": "cadillac-seville",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Shadow",
          "slug": "dodge-shadow",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Shelby Cobra",
          "slug": "ac-shelby_cobra",
          "make_name": "AC",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Shelby GT350",
          "slug": "ford-shelby_gt350",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Shelby GT350R",
          "slug": "ford-shelby_gt350r",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Shelby GT500",
          "slug": "ford-shelby_gt500",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Sidekick",
          "slug": "suzuki-sidekick",
          "make_name": "Suzuki",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Sienna",
          "slug": "toyota-sienna",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Sierra 1500",
          "slug": "gmc-sierra_1500",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Sierra 1500 Hybrid",
          "slug": "gmc-sierra_1500_hybrid",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Sierra 1500 Limited",
          "slug": "gmc-sierra_1500_limited",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Sierra 2500",
          "slug": "gmc-sierra_2500",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Sierra 3500",
          "slug": "gmc-sierra_3500",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Silhouette",
          "slug": "oldsmobile-silhouette",
          "make_name": "Oldsmobile",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Silhouette",
          "slug": "lamborghini-silhouette",
          "make_name": "Lamborghini",
          "stock_types": []
      },
      {
          "name": "Silver Cloud II",
          "slug": "rolls_royce-silver_cloud_ii",
          "make_name": "Rolls-Royce",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Silver Cloud III",
          "slug": "rolls_royce-silver_cloud_iii",
          "make_name": "Rolls-Royce",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Silver Dawn",
          "slug": "rolls_royce-silver_dawn",
          "make_name": "Rolls-Royce",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Silver Seraph",
          "slug": "rolls_royce-silver_seraph",
          "make_name": "Rolls-Royce",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Silver Shadow",
          "slug": "rolls_royce-silver_shadow",
          "make_name": "Rolls-Royce",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Silver Shadow II",
          "slug": "rolls_royce-silver_shadow_ii",
          "make_name": "Rolls-Royce",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Silver Spirit",
          "slug": "rolls_royce-silver_spirit",
          "make_name": "Rolls-Royce",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Silver Spur",
          "slug": "rolls_royce-silver_spur",
          "make_name": "Rolls-Royce",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Silver Spur II",
          "slug": "rolls_royce-silver_spur_ii",
          "make_name": "Rolls-Royce",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Silver Wraith",
          "slug": "rolls_royce-silver_wraith",
          "make_name": "Rolls-Royce",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Silverado 1500",
          "slug": "chevrolet-silverado_1500",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Silverado 1500 Hybrid",
          "slug": "chevrolet-silverado_1500_hybrid",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Silverado 1500 LD",
          "slug": "chevrolet-silverado_1500_ld",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Silverado 1500 Limited",
          "slug": "chevrolet-silverado_1500_limited",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Silverado 2500",
          "slug": "chevrolet-silverado_2500",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Silverado 3500",
          "slug": "chevrolet-silverado_3500",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Silverado EV",
          "slug": "chevrolet-silverado_ev",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Sky",
          "slug": "saturn-sky",
          "make_name": "Saturn",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Skylark",
          "slug": "hupmobile-skylark",
          "make_name": "Hupmobile",
          "stock_types": []
      },
      {
          "name": "Skylark",
          "slug": "buick-skylark",
          "make_name": "Buick",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Solstice",
          "slug": "pontiac-solstice",
          "make_name": "Pontiac",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Solterra",
          "slug": "subaru-solterra",
          "make_name": "Subaru",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Sonata",
          "slug": "hyundai-sonata",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Sonata Hybrid",
          "slug": "hyundai-sonata_hybrid",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Sonata Plug-In Hybrid",
          "slug": "hyundai-sonata_plug_in_hybrid",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Sonic",
          "slug": "chevrolet-sonic",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Sonoma",
          "slug": "gmc-sonoma",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Sorento",
          "slug": "kia-sorento",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Sorento Hybrid",
          "slug": "kia-sorento_hybrid",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Sorento Plug-In Hybrid",
          "slug": "kia-sorento_plug_in_hybrid",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Soul",
          "slug": "kia-soul",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Soul EV",
          "slug": "kia-soul_ev",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Spark",
          "slug": "chevrolet-spark",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Spark EV",
          "slug": "chevrolet-spark_ev",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Special",
          "slug": "kaiser-special",
          "make_name": "Kaiser",
          "stock_types": []
      },
      {
          "name": "Special",
          "slug": "dodge-special",
          "make_name": "Dodge",
          "stock_types": []
      },
      {
          "name": "Special",
          "slug": "oldsmobile-special",
          "make_name": "Oldsmobile",
          "stock_types": []
      },
      {
          "name": "Special",
          "slug": "buick-special",
          "make_name": "Buick",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Special",
          "slug": "nash-special",
          "make_name": "Nash",
          "stock_types": []
      },
      {
          "name": "Special DeLuxe",
          "slug": "plymouth-special_deluxe",
          "make_name": "Plymouth",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Special Deluxe",
          "slug": "chevrolet-special_deluxe",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Spectra",
          "slug": "kia-spectra",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Spectra5",
          "slug": "kia-spectra5",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Spider",
          "slug": "alfa_romeo-spider",
          "make_name": "Alfa Romeo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Spitfire",
          "slug": "triumph-spitfire",
          "make_name": "Triumph",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Sport Fury",
          "slug": "plymouth-sport_fury",
          "make_name": "Plymouth",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Sportage",
          "slug": "kia-sportage",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Sportage Hybrid",
          "slug": "kia-sportage_hybrid",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Sportage Plug-In Hybrid",
          "slug": "kia-sportage_plug_in_hybrid",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Sprint",
          "slug": "alfa_romeo-sprint",
          "make_name": "Alfa Romeo",
          "stock_types": []
      },
      {
          "name": "Sprint",
          "slug": "chevrolet-sprint",
          "make_name": "Chevrolet",
          "stock_types": []
      },
      {
          "name": "Sprint",
          "slug": "gmc-sprint",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Sprinter",
          "slug": "mercedes_benz-sprinter",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Sprinter",
          "slug": "dodge-sprinter",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Sprinter 1500",
          "slug": "mercedes_benz-sprinter_1500",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Sprinter 2500",
          "slug": "mercedes_benz-sprinter_2500",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Sprinter 3500",
          "slug": "mercedes_benz-sprinter_3500",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Sprinter 3500XD",
          "slug": "mercedes_benz-sprinter_3500xd",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Sprinter 4500",
          "slug": "mercedes_benz-sprinter_4500",
          "make_name": "Mercedes-Benz",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Sprite",
          "slug": "austin_healey-sprite",
          "make_name": "Austin-Healey",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Spyder",
          "slug": "maserati-spyder",
          "make_name": "Maserati",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Standard",
          "slug": "dodge-standard",
          "make_name": "Dodge",
          "stock_types": []
      },
      {
          "name": "Standard",
          "slug": "packard-standard",
          "make_name": "Packard",
          "stock_types": []
      },
      {
          "name": "Standard",
          "slug": "buick-standard",
          "make_name": "Buick",
          "stock_types": []
      },
      {
          "name": "Standard",
          "slug": "chevrolet-standard",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Standard",
          "slug": "plymouth-standard",
          "make_name": "Plymouth",
          "stock_types": []
      },
      {
          "name": "Standard",
          "slug": "nash-standard",
          "make_name": "Nash",
          "stock_types": []
      },
      {
          "name": "Standard",
          "slug": "hudson-standard",
          "make_name": "Hudson",
          "stock_types": []
      },
      {
          "name": "Star Chief",
          "slug": "pontiac-star_chief",
          "make_name": "Pontiac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Starfire",
          "slug": "oldsmobile-starfire",
          "make_name": "Oldsmobile",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Starion",
          "slug": "mitsubishi-starion",
          "make_name": "Mitsubishi",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Stealth",
          "slug": "dodge-stealth",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Stelvio",
          "slug": "alfa_romeo-stelvio",
          "make_name": "Alfa Romeo",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Stinger",
          "slug": "kia-stinger",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Stratus",
          "slug": "dodge-stratus",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Streamliner",
          "slug": "pontiac-streamliner",
          "make_name": "Pontiac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Styleline",
          "slug": "chevrolet-styleline",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Stylemaster",
          "slug": "chevrolet-stylemaster",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Suburban",
          "slug": "dodge-suburban",
          "make_name": "Dodge",
          "stock_types": []
      },
      {
          "name": "Suburban",
          "slug": "chevrolet-suburban",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Suburban",
          "slug": "plymouth-suburban",
          "make_name": "Plymouth",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Suburban",
          "slug": "gmc-suburban",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Sunbird",
          "slug": "pontiac-sunbird",
          "make_name": "Pontiac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Sunfire",
          "slug": "pontiac-sunfire",
          "make_name": "Pontiac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Sunliner",
          "slug": "ford-sunliner",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Super",
          "slug": "packard-super",
          "make_name": "Packard",
          "stock_types": []
      },
      {
          "name": "Super",
          "slug": "buick-super",
          "make_name": "Buick",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Super",
          "slug": "nash-super",
          "make_name": "Nash",
          "stock_types": []
      },
      {
          "name": "Super",
          "slug": "hudson-super",
          "make_name": "Hudson",
          "stock_types": []
      },
      {
          "name": "Super 88",
          "slug": "oldsmobile-super_88",
          "make_name": "Oldsmobile",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Super Bee",
          "slug": "dodge-super_bee",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Super Beetle",
          "slug": "volkswagen-super_beetle",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Super Deluxe",
          "slug": "pontiac-super_deluxe",
          "make_name": "Pontiac",
          "stock_types": []
      },
      {
          "name": "Super Deluxe",
          "slug": "ford-super_deluxe",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Super Eight",
          "slug": "packard-super_eight",
          "make_name": "Packard",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Super Eight",
          "slug": "hudson-super_eight",
          "make_name": "Hudson",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Superamerica",
          "slug": "ferrari-superamerica",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Supra",
          "slug": "toyota-supra",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "T1",
          "slug": "bentley-t1",
          "make_name": "Bentley",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "T100",
          "slug": "toyota-t100",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "T1000",
          "slug": "pontiac-t1000",
          "make_name": "Pontiac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "TC Roadster",
          "slug": "mg-tc_roadster",
          "make_name": "MG",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "TC by Maserati",
          "slug": "chrysler-tc_by_maserati",
          "make_name": "Chrysler",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "TD",
          "slug": "mg-td",
          "make_name": "MG",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "TF",
          "slug": "mg-tf",
          "make_name": "MG",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "TL",
          "slug": "acura-tl",
          "make_name": "Acura",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "TLX",
          "slug": "acura-tlx",
          "make_name": "Acura",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "TR250",
          "slug": "triumph-tr250",
          "make_name": "Triumph",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "TR3",
          "slug": "triumph-tr3",
          "make_name": "Triumph",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "TR4",
          "slug": "triumph-tr4",
          "make_name": "Triumph",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "TR6",
          "slug": "triumph-tr6",
          "make_name": "Triumph",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "TR7",
          "slug": "triumph-tr7",
          "make_name": "Triumph",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "TSX",
          "slug": "acura-tsx",
          "make_name": "Acura",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "TT",
          "slug": "audi-tt",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "TT RS",
          "slug": "audi-tt_rs",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "TTS",
          "slug": "audi-tts",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "TX 350",
          "slug": "lexus-tx_350",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "TX 500h",
          "slug": "lexus-tx_500h",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Tacoma",
          "slug": "toyota-tacoma",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Tahoe",
          "slug": "chevrolet-tahoe",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Tahoe Hybrid",
          "slug": "chevrolet-tahoe_hybrid",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Talon",
          "slug": "eagle-talon",
          "make_name": "Eagle",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Taos",
          "slug": "volkswagen-taos",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Taurus",
          "slug": "ford-taurus",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Taurus X",
          "slug": "ford-taurus_x",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Taycan",
          "slug": "porsche-taycan",
          "make_name": "Porsche",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Taycan Cross Turismo",
          "slug": "porsche-taycan_cross_turismo",
          "make_name": "Porsche",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Telluride",
          "slug": "kia-telluride",
          "make_name": "Kia",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Tempest",
          "slug": "pontiac-tempest",
          "make_name": "Pontiac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Tempo",
          "slug": "ford-tempo",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Tercel",
          "slug": "toyota-tercel",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Terrain",
          "slug": "gmc-terrain",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Terraza",
          "slug": "buick-terraza",
          "make_name": "Buick",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Testarossa",
          "slug": "ferrari-testarossa",
          "make_name": "Ferrari",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Thing",
          "slug": "volkswagen-thing",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Thunderbird",
          "slug": "ford-thunderbird",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Tiburon",
          "slug": "hyundai-tiburon",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Tiger",
          "slug": "sunbeam-tiger",
          "make_name": "Sunbeam",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Tiguan",
          "slug": "volkswagen-tiguan",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Tiguan Limited",
          "slug": "volkswagen-tiguan_limited",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Titan",
          "slug": "nissan-titan",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Titan XD",
          "slug": "nissan-titan_xd",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Tonale",
          "slug": "alfa_romeo-tonale",
          "make_name": "Alfa Romeo",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Torino",
          "slug": "ford-torino",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Toronado",
          "slug": "oldsmobile-toronado",
          "make_name": "Oldsmobile",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Torrent",
          "slug": "pontiac-torrent",
          "make_name": "Pontiac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Touareg",
          "slug": "volkswagen-touareg",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Touareg 2",
          "slug": "volkswagen-touareg_2",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Touareg Hybrid",
          "slug": "volkswagen-touareg_hybrid",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Town &amp; Country",
          "slug": "chrysler-town_and_country",
          "make_name": "Chrysler",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Town Car",
          "slug": "lincoln-town_car",
          "make_name": "Lincoln",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Tracker",
          "slug": "geo-tracker",
          "make_name": "Geo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Tracker",
          "slug": "chevrolet-tracker",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "TrailBlazer EXT",
          "slug": "chevrolet-trailblazer_ext",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Trailblazer",
          "slug": "chevrolet-trailblazer",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Trans Sport",
          "slug": "pontiac-trans_sport",
          "make_name": "Pontiac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Transit Connect",
          "slug": "ford-transit_connect",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Transit-150",
          "slug": "ford-transit_150",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Transit-250",
          "slug": "ford-transit_250",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Transit-350",
          "slug": "ford-transit_350",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Traveler",
          "slug": "hudson-traveler",
          "make_name": "Hudson",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Traverse",
          "slug": "chevrolet-traverse",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Trax",
          "slug": "chevrolet-trax",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Tribeca",
          "slug": "subaru-tribeca",
          "make_name": "Subaru",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Tribute",
          "slug": "mazda-tribute",
          "make_name": "Mazda",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Trooper",
          "slug": "isuzu-trooper",
          "make_name": "Isuzu",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Tucson",
          "slug": "hyundai-tucson",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Tucson Hybrid",
          "slug": "hyundai-tucson_hybrid",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Tucson Plug-In Hybrid",
          "slug": "hyundai-tucson_plug_in_hybrid",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Tundra",
          "slug": "toyota-tundra",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Tundra Hybrid",
          "slug": "toyota-tundra_hybrid",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Type 3",
          "slug": "volkswagen-type_3",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Type 57",
          "slug": "cadillac-type_57",
          "make_name": "Cadillac",
          "stock_types": []
      },
      {
          "name": "Type 57",
          "slug": "maybach-type_57",
          "make_name": "Maybach",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Type 62",
          "slug": "maybach-type_62",
          "make_name": "Maybach",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "UX 200",
          "slug": "lexus-ux_200",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "UX 250h",
          "slug": "lexus-ux_250h",
          "make_name": "Lexus",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Uplander",
          "slug": "chevrolet-uplander",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Urus",
          "slug": "lamborghini-urus",
          "make_name": "Lamborghini",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Utility Police Interceptor",
          "slug": "ford-utility_police_interceptor",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "V12 Vanquish",
          "slug": "aston_martin-v12_vanquish",
          "make_name": "Aston Martin",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "V12 Vantage",
          "slug": "aston_martin-v12_vantage",
          "make_name": "Aston Martin",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "V12 Vantage S",
          "slug": "aston_martin-v12_vantage_s",
          "make_name": "Aston Martin",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "V50",
          "slug": "volvo-v50",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "V60",
          "slug": "volvo-v60",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "V60 Cross Country",
          "slug": "volvo-v60_cross_country",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "V60 Recharge Plug-In Hybrid",
          "slug": "volvo-v60_recharge_plug_in_hybrid",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "V70",
          "slug": "volvo-v70",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "V8 Vantage",
          "slug": "aston_martin-v8_vantage",
          "make_name": "Aston Martin",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "V8 Vantage S",
          "slug": "aston_martin-v8_vantage_s",
          "make_name": "Aston Martin",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "V90",
          "slug": "volvo-v90",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "V90 Cross Country",
          "slug": "volvo-v90_cross_country",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Valiant",
          "slug": "plymouth-valiant",
          "make_name": "Plymouth",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Van",
          "slug": "dodge-van",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Van",
          "slug": "chevrolet-van",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Van",
          "slug": "plymouth-van",
          "make_name": "Plymouth",
          "stock_types": []
      },
      {
          "name": "Van",
          "slug": "nissan-van",
          "make_name": "Nissan",
          "stock_types": []
      },
      {
          "name": "Van",
          "slug": "gmc-van",
          "make_name": "GMC",
          "stock_types": []
      },
      {
          "name": "Van",
          "slug": "ford-van",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Van",
          "slug": "toyota-van",
          "make_name": "Toyota",
          "stock_types": []
      },
      {
          "name": "Van",
          "slug": "volkswagen-van",
          "make_name": "Volkswagen",
          "stock_types": []
      },
      {
          "name": "Vanagon",
          "slug": "volkswagen-vanagon",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Vanden Plas",
          "slug": "jaguar-vanden_plas",
          "make_name": "Jaguar",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Vandura",
          "slug": "gmc-vandura",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Vanquish",
          "slug": "aston_martin-vanquish",
          "make_name": "Aston Martin",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Vantage",
          "slug": "aston_martin-vantage",
          "make_name": "Aston Martin",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Vantage GT",
          "slug": "aston_martin-vantage_gt",
          "make_name": "Aston Martin",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Vega",
          "slug": "chevrolet-vega",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "VehiCROSS",
          "slug": "isuzu-vehicross",
          "make_name": "Isuzu",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Veloster",
          "slug": "hyundai-veloster",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Veloster N",
          "slug": "hyundai-veloster_n",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Ventura",
          "slug": "pontiac-ventura",
          "make_name": "Pontiac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Venture",
          "slug": "chevrolet-venture",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Venue",
          "slug": "hyundai-venue",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Venza",
          "slug": "toyota-venza",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Veracruz",
          "slug": "hyundai-veracruz",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Verano",
          "slug": "buick-verano",
          "make_name": "Buick",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Verona",
          "slug": "suzuki-verona",
          "make_name": "Suzuki",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Versa",
          "slug": "nissan-versa",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Versa Note",
          "slug": "nissan-versa_note",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Veyron",
          "slug": "bugatti-veyron",
          "make_name": "Bugatti",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Veyron 16.4",
          "slug": "bugatti-veyron_16.4",
          "make_name": "Bugatti",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Vibe",
          "slug": "pontiac-vibe",
          "make_name": "Pontiac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Vigor",
          "slug": "acura-vigor",
          "make_name": "Acura",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Villager",
          "slug": "mercury-villager",
          "make_name": "Mercury",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Villager",
          "slug": "edsel-villager",
          "make_name": "Edsel",
          "stock_types": []
      },
      {
          "name": "Viper",
          "slug": "dodge-viper",
          "make_name": "Dodge",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "Virage",
          "slug": "aston_martin-virage",
          "make_name": "Aston Martin",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Vista Cruiser",
          "slug": "oldsmobile-vista_cruiser",
          "make_name": "Oldsmobile",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Vitara",
          "slug": "suzuki-vitara",
          "make_name": "Suzuki",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Volante",
          "slug": "aston_martin-volante",
          "make_name": "Aston Martin",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Volt",
          "slug": "chevrolet-volt",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Voyager",
          "slug": "chrysler-voyager",
          "make_name": "Chrysler",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Voyager",
          "slug": "plymouth-voyager",
          "make_name": "Plymouth",
          "stock_types": []
      },
      {
          "name": "Voyager",
          "slug": "mercury-voyager",
          "make_name": "Mercury",
          "stock_types": []
      },
      {
          "name": "Vue",
          "slug": "saturn-vue",
          "make_name": "Saturn",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Vue Green Line",
          "slug": "saturn-vue_green_line",
          "make_name": "Saturn",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Vue Hybrid",
          "slug": "saturn-vue_hybrid",
          "make_name": "Saturn",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "WRX",
          "slug": "subaru-wrx",
          "make_name": "Subaru",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "WRX STI",
          "slug": "subaru-wrx_sti",
          "make_name": "Subaru",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Wagoneer",
          "slug": "jeep-wagoneer",
          "make_name": "Jeep",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Wagoneer L",
          "slug": "jeep-wagoneer_l",
          "make_name": "Jeep",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Wildcat",
          "slug": "buick-wildcat",
          "make_name": "Buick",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Windstar",
          "slug": "ford-windstar",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Wraith",
          "slug": "rolls_royce-wraith",
          "make_name": "Rolls-Royce",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Wrangler",
          "slug": "jeep-wrangler",
          "make_name": "Jeep",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Wrangler 4xe",
          "slug": "jeep-wrangler_4xe",
          "make_name": "Jeep",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Wrangler JK",
          "slug": "jeep-wrangler_jk",
          "make_name": "Jeep",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Wrangler JK Unlimited",
          "slug": "jeep-wrangler_jk_unlimited",
          "make_name": "Jeep",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Wrangler Unlimited",
          "slug": "jeep-wrangler_unlimited",
          "make_name": "Jeep",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Wrangler Unlimited 4xe",
          "slug": "jeep-wrangler_unlimited_4xe",
          "make_name": "Jeep",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "X-Type",
          "slug": "jaguar-x_type",
          "make_name": "Jaguar",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "X1",
          "slug": "bmw-x1",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "X1/9",
          "slug": "fiat-x1_9",
          "make_name": "FIAT",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "X2",
          "slug": "bmw-x2",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "X3",
          "slug": "bmw-x3",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "X3 M",
          "slug": "bmw-x3_m",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "X3 PHEV",
          "slug": "bmw-x3_phev",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "X4",
          "slug": "bmw-x4",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "X4 M",
          "slug": "bmw-x4_m",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "X5",
          "slug": "bmw-x5",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "X5 M",
          "slug": "bmw-x5_m",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "X5 PHEV",
          "slug": "bmw-x5_phev",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "X5 eDrive",
          "slug": "bmw-x5_edrive",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "X6",
          "slug": "bmw-x6",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "X6 M",
          "slug": "bmw-x6_m",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "X7",
          "slug": "bmw-x7",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "XC40",
          "slug": "volvo-xc40",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "XC40 Recharge Pure Electric",
          "slug": "volvo-xc40_recharge_pure_electric",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "XC60",
          "slug": "volvo-xc60",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "XC60 Recharge Plug-In Hybrid",
          "slug": "volvo-xc60_recharge_plug_in_hybrid",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "XC70",
          "slug": "volvo-xc70",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "XC90",
          "slug": "volvo-xc90",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "XC90 Hybrid",
          "slug": "volvo-xc90_hybrid",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "XC90 Recharge Plug-In Hybrid",
          "slug": "volvo-xc90_recharge_plug_in_hybrid",
          "make_name": "Volvo",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "XE",
          "slug": "jaguar-xe",
          "make_name": "Jaguar",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "XE SV",
          "slug": "jaguar-xe_sv",
          "make_name": "Jaguar",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "XF",
          "slug": "jaguar-xf",
          "make_name": "Jaguar",
          "stock_types": [
              "all",
              "used",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "XG350",
          "slug": "hyundai-xg350",
          "make_name": "Hyundai",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "XJ",
          "slug": "jaguar-xj",
          "make_name": "Jaguar",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "XJ12",
          "slug": "jaguar-xj12",
          "make_name": "Jaguar",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "XJ6",
          "slug": "jaguar-xj6",
          "make_name": "Jaguar",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "XJ8",
          "slug": "jaguar-xj8",
          "make_name": "Jaguar",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "XJR",
          "slug": "jaguar-xjr",
          "make_name": "Jaguar",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "XJR-S",
          "slug": "jaguar-xjr_s",
          "make_name": "Jaguar",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "XJS",
          "slug": "jaguar-xjs",
          "make_name": "Jaguar",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "XK",
          "slug": "jaguar-xk",
          "make_name": "Jaguar",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "XK 120",
          "slug": "jaguar-xk_120",
          "make_name": "Jaguar",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "XK 140",
          "slug": "jaguar-xk_140",
          "make_name": "Jaguar",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "XK 150",
          "slug": "jaguar-xk_150",
          "make_name": "Jaguar",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "XK8",
          "slug": "jaguar-xk8",
          "make_name": "Jaguar",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "XKE",
          "slug": "jaguar-xke",
          "make_name": "Jaguar",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "XKR",
          "slug": "jaguar-xkr",
          "make_name": "Jaguar",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "XL",
          "slug": "ford-xl",
          "make_name": "Ford",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "XL7",
          "slug": "suzuki-xl7",
          "make_name": "Suzuki",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "XLR",
          "slug": "cadillac-xlr",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "XM",
          "slug": "bmw-xm",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "XT4",
          "slug": "cadillac-xt4",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "XT5",
          "slug": "cadillac-xt5",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "XT6",
          "slug": "cadillac-xt6",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "XTS",
          "slug": "cadillac-xts",
          "make_name": "Cadillac",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "XV Crosstrek",
          "slug": "subaru-xv_crosstrek",
          "make_name": "Subaru",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "XV Crosstrek Hybrid",
          "slug": "subaru-xv_crosstrek_hybrid",
          "make_name": "Subaru",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Xterra",
          "slug": "nissan-xterra",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "YT",
          "slug": "mg-yt",
          "make_name": "MG",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Yaris",
          "slug": "toyota-yaris",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Yaris Sedan",
          "slug": "toyota-yaris_sedan",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Yaris iA",
          "slug": "toyota-yaris_ia",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "Yeoman",
          "slug": "chevrolet-yeoman",
          "make_name": "Chevrolet",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Yukon",
          "slug": "gmc-yukon",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Yukon Hybrid",
          "slug": "gmc-yukon_hybrid",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Yukon XL",
          "slug": "gmc-yukon_xl",
          "make_name": "GMC",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Z",
          "slug": "nissan-z",
          "make_name": "Nissan",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Z3",
          "slug": "bmw-z3",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Z4",
          "slug": "bmw-z4",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "Z4 M",
          "slug": "bmw-z4_m",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Z8",
          "slug": "bmw-z8",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "ZDX",
          "slug": "acura-zdx",
          "make_name": "Acura",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Zephyr",
          "slug": "lincoln-zephyr",
          "make_name": "Lincoln",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "Zephyr",
          "slug": "mercury-zephyr",
          "make_name": "Mercury",
          "stock_types": []
      },
      {
          "name": "allroad",
          "slug": "audi-allroad",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "bZ4X",
          "slug": "toyota-bz4x",
          "make_name": "Toyota",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "del Sol",
          "slug": "honda-del_sol",
          "make_name": "Honda",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "e-Golf",
          "slug": "volkswagen-e_golf",
          "make_name": "Volkswagen",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "e-tron",
          "slug": "audi-e_tron",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "e-tron GT",
          "slug": "audi-e_tron_gt",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "e-tron S",
          "slug": "audi-e_tron_s",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "e-tron Sportback",
          "slug": "audi-e_tron_sportback",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "i-290",
          "slug": "isuzu-i_290",
          "make_name": "Isuzu",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "i-350",
          "slug": "isuzu-i_350",
          "make_name": "Isuzu",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "i-MiEV",
          "slug": "mitsubishi-i_miev",
          "make_name": "Mitsubishi",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "i3",
          "slug": "bmw-i3",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "i4 Gran Coupe",
          "slug": "bmw-i4_gran_coupe",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "i5",
          "slug": "bmw-i5",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "new",
              "new_cpo"
          ]
      },
      {
          "name": "i7",
          "slug": "bmw-i7",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "i8",
          "slug": "bmw-i8",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo"
          ]
      },
      {
          "name": "iA",
          "slug": "scion-ia",
          "make_name": "Scion",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "iM",
          "slug": "scion-im",
          "make_name": "Scion",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "iQ",
          "slug": "scion-iq",
          "make_name": "Scion",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "iX",
          "slug": "bmw-ix",
          "make_name": "BMW",
          "stock_types": [
              "all",
              "used",
              "cpo",
              "new_cpo",
              "new"
          ]
      },
      {
          "name": "quattro",
          "slug": "audi-quattro",
          "make_name": "Audi",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "tC",
          "slug": "scion-tc",
          "make_name": "Scion",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "xA",
          "slug": "scion-xa",
          "make_name": "Scion",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "xB",
          "slug": "scion-xb",
          "make_name": "Scion",
          "stock_types": [
              "all",
              "used"
          ]
      },
      {
          "name": "xD",
          "slug": "scion-xd",
          "make_name": "Scion",
          "stock_types": [
              "all",
              "used"
          ]
      }
  ]
  }
  
}

