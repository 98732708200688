export const environment = {
  testnet: false,
  deleted_ids: [8, 51, 138, 164, 174, 187, 190, 191, 181, 185, 193, 194, 97, 212, 213, 170, 164,],
  featuredIds: [14, 49, 54],
  // PRIMARY BLOCKCHAIN INFO
  chainName: 'Polygon Mainnet',
  nativeCurrency_name: 'MATIC',
  nativeSymbol: 'MATIC',
  nativeDecimals: 18,
  rpc: 'https://polygon-rpc.com/',
  chainId: 137,
  mongoAPI:'mongodb+srv://digible:fUxsBR3Lsiy51ocx@cluster0.v4wxa.mongodb.net/digible',

  // offchainApi: 'https://api.digible.io',
  offchainApi: 'https://digible-offchain-backend.herokuapp.com',
  // mintFee: {
  //   enthusiast: 39.95,
  //   collector: 79.95,
  //   professional: 149.95
  // },
  // for stripe test
  mintFee: {
    free:0,
    enthusiast: 0, // 1
    collector: 5,
    professional: 10,
  },
  updateFee: 0,

  // REFCODES MUST MATCH CODES IN SMART CONTRACT.
  refCodes: ['clk350', 'gembala', 'genesis', 'topstar', 'danny', 'serge', 'BossMode', 'pro13', 'pro16', 'pro3'],

  // $ off Mint Fee Enthusiast, Collector x2, Pro x 3
  refCodeValues: [4, 6, 8, 8, 8, 8, 39, 13, 16,3],

  //vinNFTMAddressMatic: '0x041315230078090e02B145D7Bf876Dfb4F684047',
  vinNFTMAddressMatic: '0x48bf221496B8f1f55aE64b01909c16c3a80B018b', // NEW 6Dec
  feeCurrencyAddressMatic: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', // TUSDC6 owner VINNFT Deployer: 0x65388CcD781d054EabE707ddC38Fb52d264008D0
  feeCurrencyDecimals: 6,

  InfuraProjectID: '2DVUUVDToFVAEa2dnSZ9QeWV9hl',
  InfuraProjectSecret: '8b0217da0979acbfa587452ed21c340a',

  wyreInfo: {
    SECRET_KEY: '44c37ccda296dd5f5ad75dba87568a8fc3df91001eb4c4f432',
    ID: 'AC_WQWULR7ZPVQ',
    API_URL: 'https://api.testwyre.com',
  },
  stripeInfo: {
    PUBLISHABLE_KEY:
      'pk_live_51LpZAZJy1WsUEYJlPDuozuJGBwtzKdtcXXwfn3vOF1L8yHPezXNgMQCxSdg7M8QhACzETYFro1OHtOeISpUqKGE200AXJy2jnt',
  },
  vinMintActionWallet: '0xa1d27EB664a0DF5aa81E32AA0fb9D43647609635',

  maticCoinContractAddresses: {
    maticCoinAddress: '0x0000000000000000000000000000000000001010',
    digiAddressMatic: '0x4d8181f051E617642e233Be09Cea71Cc3308ffD4',
    usdcAddressMatic: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    linkAddressMatic: '0xb0897686c545045aFc77CF20eC7A532E3120E0F1',
    usdtAddressMatic: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    wethAddressMatic: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    wbtcAddressMatic: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
  },

  bscCoinContractAddresses: {
    digiBSCAddress: '0x82cD5A3342ccc1329E7022857d8DB73A52dfEbAb',
    busdBSCAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    wbnbBSCAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    usdcBSCAddress: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    soulsBSCAddress: '0x6545cAC09cC84fa234C2e421D2EedEECaC8Eb2D9',
  },

  ethCoinContractAddresses: {
    ethCoinAddress: '',
    digiEthAddress: '0x3cbf23c081faa5419810ce0f6bc1ecb73006d848',
    usdcEthAddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    usdtEthAddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    maticEthAddress: '',
  },

  PINATA_DIGIBLE_URL: 'https://digible.mypinata.cloud/ipfs',
  PINATA_API_KEY: 'ddb276f2fc9f08c9d643',
  PINATA_API_SECRET:
    '6538f099ac20fcbccb1af5b381ddc4d97abfc7636db50c2311bdb579d48ddf7c',
  PINATA_API_JWT:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiJlYzk0OGEzZC01YjMzLTQ5MDQtOTcwNy03NjFlYWE3ZGY0NjEiLCJlbWFpbCI6Im9wc0BkaWdpYmxlLmlvIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsInBpbl9wb2xpY3kiOnsicmVnaW9ucyI6W3siaWQiOiJOWUMxIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9XSwidmVyc2lvbiI6MX0sIm1mYV9lbmFibGVkIjpmYWxzZSwic3RhdHVzIjoiQUNUSVZFIn0sImF1dGhlbnRpY2F0aW9uVHlwZSI6InNjb3BlZEtleSIsInNjb3BlZEtleUtleSI6ImRkYjI3NmYyZmM5ZjA4YzlkNjQzIiwic2NvcGVkS2V5U2VjcmV0IjoiNjUzOGYwOTlhYzIwZmNiY2NiMWFmNWIzODFkZGM0ZDk3YWJmYzc2MzZkYjUwYzIzMTFiZGI1NzlkNDhkZGY3YyIsImlhdCI6MTY2NDI2Nzc1Mn0.DzXBzW1Uhb2BljvG-WPLkLls83L2MdLnEF9ysUYzZVc',

  
    ai_apiEndpoint: 'https://h3t1sy1oyj.execute-api.us-east-1.amazonaws.com/Prod',
    
    ai_productID: 'TAPIxSCXNkqwTG',
    ai_clientID: 'SVTWyFAWQfQcy',

  instructions: {

    
    is_dealer: "Owner is a well-respected automotive dealer. Using owner's records above tell me in three paragraphs about the history of the 'model' in the 'vehicle name' brand. Provide a detailed paragraph about the history of the specified 'model' from the 'vehicle name' brand. Include key milestones, technological advancements, and its significance in automotive history. at least one paragrpah devoted to information supplied in JSON about this specific car. Do not highlight or hedge negatives and do not make assumptions. Highlight any specific features from the dealers sticker information. Do not assume any condition unless its specified. If the records are missing information it doesn't meant its affirmative, instead  ignore and skip. If it's Bay Ridge Honda, Bay Ridge Honda stands out for its dedicated and detail-oriented sales team. Their sales representatives are frequently lauded for their commitment to finding vehicles that align with customers' budgets and preferences. They exemplify our dealership's dedication to honesty, transparency, and customer satisfaction",


    is_owner_is_for_sale: "Using owner's records above tell me in three paragraphs about the history of the 'model' in the 'vehicle name' brand. Provide a detailed paragraph about the history of the specified 'model' from the 'vehicle name' brand. Include key milestones, technological advancements, and its significance in automotive history. at least one paragrpah devoted to information supplied in JSON about this specific car. Do not highlight or hedge negatives and do not make assumptions. Do not assume any condition unless its specified. If the records are missing information it doesn't meant its affirmative, instead  ignore and skip.",

    is_owner_not_for_sale: "Using owner's records above tell me in three paragraphs about the history of the 'model' in the 'vehicle name' brand. Provide a detailed paragraph about the history of the specified 'model' from the 'vehicle name' brand. Include key milestones, technological advancements, and its significance in automotive history. at least one paragrpah devoted to information supplied in JSON about this specific car. Do not highlight or hedge negatives and do not make assumptions. Do not assume any condition unless its specified. If the records are missing information it doesn't meant its affirmative, instead  ignore and skip. ",

    service_provider: "Use infromation supplied by the Service Provider to write a classy description and any infromation supplied by the service provider. This is a way to help them communicate the great job they did. They could be detailers, or service shops. This information is under selectedServices.",

    professional_photographer: "Use infromation supplied by the Professional Photographer to write a classy description and any infromation supplied by the photographer. This is a way to help them communicate the great job they did. This information is under selectedServices.  If location is presented and significant, note it. If its important, devote more to it and how it relates to the car and its pictures.",

    spotted_fan: "This is spotted or fan submitted content, write a very short description unless rich user content is supplied. Info under selectedServices. If location is presented and significant, note it. If its important, devote more to it and how it relates to the car and its pictures.",

    gift: "This is a gift of the vin-tracker that helps do, write a very short description unless rich user content is supplied. Info under selectedServices.",


  },
};
