import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { math } from '../types/math';

@Injectable()
export class MathService {
  toHumanValue(amount: any, decimals?: number): number {
    if (!decimals) {
      decimals = environment.feeCurrencyDecimals;
    }

    return parseFloat(
      this.toFixedNoRounding(
        3,
        math
          .chain(math.bignumber(amount))
          .divide(math.bignumber(10).pow(math.bignumber(decimals)))
          .done()
      )
    );
  }

  toBlockchainValue(amount: any, decimals?: number): string {
    if (!decimals) {
      decimals = environment.feeCurrencyDecimals;
    }
    console.log('AMOUNT', amount);
    let number;
    try {
      number = math.format(
        math
          .chain(math.bignumber(amount))
          .multiply(math.bignumber(10).pow(math.bignumber(decimals)))
          .done(),
        { notation: 'fixed' }
      );
    } catch (e) {
      console.log('ERROR::', e);
    }
    return number as string;
  }

  public toFixedNoRounding(n: any, d: any) {
    const reg = new RegExp(`^-?\\d+(?:\\.\\d{0,${n}})?`, 'g');
    const a = d.toString().match(reg)[0];
    const dot = a.indexOf('.');

    if (dot === -1) {
      return a + '.' + '0'.repeat(n);
    }

    const b = n - (a.length - dot) + 1;

    return b > 0 ? a + '0'.repeat(b) : a;
  }

  public arrayBufferToBase64(buffer: ArrayBuffer): string {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;

    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }

    return btoa(binary);
  }
}
