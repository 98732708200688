import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  showScrollButton = false;
  constructor() {}

  ngOnInit(): void {}
  @HostListener('window:scroll')
  onWindowScroll() {
    const yOffset = window.pageYOffset;
    const scrollTopThreshold = 200; // Adjust this threshold as per your requirements

    this.showScrollButton = yOffset > scrollTopThreshold;
  }
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
