import { Component, OnInit } from '@angular/core';

interface ICommentsData {
  name: string;
  date: Date;
  comment: string;
}
@Component({
  selector: 'app-comment-section',
  templateUrl: './comment-section.component.html',
  styleUrls: ['./comment-section.component.scss'],
})
export class CommentSectionComponent implements OnInit {
  commentsData: ICommentsData[] = [];
  formData: any = {};
  constructor() {}

  ngOnInit(): void {
  }
  onClickSubmit(data: any) {
    if (data.name && data.comment) {
      const updatedData = {
        ...data,
        date: new Date(),
      };

      this.commentsData = [...this.commentsData, updatedData];
      this.formData = { name: '', comment: '', date: '' };
    }
  }
}
