import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { OffchainService } from 'src/app/services/offchain.service';

@Component({
  selector: 'app-single-item-card',
  templateUrl: './single-item-card.component.html',
  styleUrls: ['./single-item-card.component.scss'],
})
export class SingleItemCardComponent implements OnInit {
  @Input() singleItem: VinNftType;
  @Input() fixedHeight: Boolean = false;

  listPrice: string;
  gallerySlides: any[] = [];
  isFavorited:boolean = false;
  itemId: any;
  processing: boolean = false;

  constructor(
    private readonly toastr: ToastrService,
    public readonly auth: AuthService,
    private readonly offchain: OffchainService,
  ) { }

  ngOnInit() {
    this.loadData();
  }

  async loadData() {
    this.itemId = this.singleItem.id;
    if (this.singleItem.vinSn && this.singleItem.vinSn.length == 17) {
      this.listPrice = "CERTIFIED"
    }

    if (this.singleItem && this.singleItem.attributes) {

      this.singleItem.attributes.some(element => {
        if (element.trait_type === "Asking Price") {
          this.listPrice = element.value;
        }
      });
    }
    if(this.singleItem.price){
      this.listPrice = this.singleItem.price;
    }

    this.gallerySlides[0] = {
      content: this.singleItem?.image,
      isVideo: this.singleItem?.isVideo
    };
    this.singleItem?.extras.map((extra) => {
      this.gallerySlides.push({
        content: extra.content,
        isVideo: extra.isVideo
      });
    });
    if(this.auth.isLogin) {
      const favids = this.auth.curUser.favids;
      if(favids.includes(this.itemId)) this.isFavorited = true;
      else this.isFavorited = false;
    }
  }

  async toggleFavorite() {
    if (!this.auth.isLogin) {
      this.toastr.error(
        'You have to login add favorite'
      );
      return;
    }
    this.processing = true;
    if(this.isFavorited){
      await this.removeFromFavorites();
    }else{
      await this.addToFavorites();
    }
    this.toastr.success('Saved!');
    this.processing = false;
  }

  async addToFavorites(){
    const res = await this.offchain.addToFavorites(
      this.itemId,
      this.auth.curUser._id
    );
    this.isFavorited = true;
  }

  async removeFromFavorites(){
    await this.offchain.removeFromFavorites(
      this.itemId,
      this.auth.curUser._id
    )
    this.isFavorited = false;
  }
}
