<div class="afont" style="margin-left:25%; margin-right:25%">
    <br>
    <div class="mt-3 text-3xl font-bold text-center">Welcome to the Vin-Tracker&trade; Dealer & Concierge Services.
    </div>

    <br>
    <ul class="timeline">
        <li class="timeline-item">
            <div class="flex flex-col items-center item">
                <div class="timeline-panel text-center flex-col ">
                    <div class="mt-5 text-2xl font-bold">Concierge Services:</div>

                    <div class="mt-5 font-semibold">* Buyer/Seller procurement, vetting, and negotiation</div>
                    <div class="mt-5  font-semibold">* Document organization, certification, CARFAX&#174; registration,
                        and titling</div>
                    <div class="mt-5 font-semibold">* Escrow services with 3rd party buyers such as from
                        bringatrailer.com</div>
                    <div class="mt-5  font-semibold">* Professional photography & videography </div>
                   

                    <br>

                    <button type="button" class="writeButton bg-redLeather"
                        (click)="contactUs('Vehicle Concierge Inquiry')">
                        Contact Concierge Services
                    </button>

                </div>
            </div>
        </li>

        <br>
        <!-- <li class="timeline-item">
            <div class="flex flex-col items-center item">
                <div class="timeline-panel text-center flex-col ">
                    <div class="mt-5  text-2xl font-bold">Vehicle Buying & Leasing Services:</div>

                    <div class="mt-5  font-semibold">* Escrow services with 3rd party sellers such as from
                        bringatrailer.com</div>
                    <div class="mt-5  font-semibold">* Vehicle procurement, seller vetting, and negotiation</div>
                    <div class="mt-5  font-semibold">* Document organization, certification, registration, and titling
                    </div>
                    <div class="mt-5  font-semibold">* Inspections, transport</div>

                    <br>

                    <button class="writeButton bg-redLeather"
                        (click)="contactUs('Vehicle Buying/Leasing Services Inquiry')">
                        Contact Vehicle Buying Services
                    </button>

                    <br>
                </div>
            </div>
        </li> -->
        <br>
        <!-- <li class="timeline-item">
            <div class="flex flex-col items-center item">
                <div class="timeline-panel text-center flex-col ">
                    <div class="mt-5 text-2xl font-bold">Vehicle Care Services:</div>

                    <div class="mt-5  font-semibold">* Document organization, certification, registration, and titling
                    </div>
                    <div class="mt-5  font-semibold">* Professional photography, videography, drone-footage, 3D vehicle modeling and rendering</div>
                    <div class="mt-5  font-semibold">* Detailing, servicing, body-shop work, special parts</div>
                    <div class="mt-5  font-semibold">* Parts, upgrades, customization</div>
                    <div class="mt-5  font-semibold">* National and global transportation, storage</div>

                    <br>

                    <button class="writeButton bg-redLeather"
                        (click)="contactUs('Vehicle Care Services')">
                        Contact Vehicle Services
                    </button>


                    <br>
                </div>
            </div>
        </li> -->
        <br>
        <li class="timeline-item">
            <div class="flex flex-col items-center item">
                <div class="timeline-panel text-center flex-col ">
                    <div class="mt-5 text-2xl font-bold">Partnerships</div>

                    <div class="mt-5  font-semibold"> Vin-Tracker&trade; partners with leaders in the automative
                        industry.</div>

                    <div class="mt-5 font-semibold" style="line-height: 150%;"> If you are are a service provider
                        interested in joining the Vin-Tracker&trade;
                        authorized partner network to provide services to our customers, please reach out to us.
                        We work with dealerships, car experts and personalities, automotive servicing companies such as
                        pro-shops,
                        storage providers, shippers, and automotive enthusiasts.
                    </div>

                    <br>

                    <!-- <div class="mt-5  text-2xl  font-bold">Authorized Distributors & Vendors</div>

                    <div class="mt-5  font-semibold">If you are currently selling products or services in the automotive
                        industry,
                        we invite you to apply to become an authorized Vin-Tracker&trade; Distributor.</div>

                    <br> -->

                    <button class="writeButton bg-redLeather"
                        (click)="contactUsPartner('Partnership with Vin-Tracker')">
                        Contact Partnerships
                    </button>
                </div>
            </div>
        </li>

    </ul>

</div>