import { Component, OnInit, ViewChild } from '@angular/core';
import { OffchainService } from 'src/app/services/offchain.service';
import { NgxMasonryComponent } from 'ngx-masonry';

const SHOW_COUNT = 9;

@Component({
  selector: 'app-explorer',
  templateUrl: './explorer.component.html',
  styleUrls: ['./explorer.component.scss'],
})
export class ExplorerComponent implements OnInit {
  @ViewChild(NgxMasonryComponent, { static: false })
  masonry: NgxMasonryComponent;
  unfilteredList: VinNftType[] = [];
  list: VinNftType[] = [];
  showCount: number = SHOW_COUNT;
  profileAddress: string;
  searchText: string = '';
  loading: boolean = true;
  sortDirection: number = -1;
  sortType: string = 'created_date';
  updateMasonryLayout: any;

  constructor(private readonly offchain: OffchainService) {}

  ngOnInit(): void {
    this.loadData();
  }

  async loadData() {
    const allNfts = await this.offchain.getAllNfts();
    const allComments = await this.offchain.getAllComments();
    for (let idx = 0; idx < allNfts.length; idx++) {
      const fItem = allNfts[idx];
      const comments = allComments.filter(
        (_cmt: any) => _cmt.token_id === fItem.id
      );
      allNfts[idx] = {
        ...fItem,
        commentCount: comments.length,
      };
    }

    this.unfilteredList = allNfts;
    if (this.sortType === 'comment_count')
      this.unfilteredList = this.unfilteredList.sort((a: any, b: any) =>
        a.commentCount > b.commentCount
          ? this.sortDirection
          : -this.sortDirection
      );
    else if (this.sortType === 'created_date')
      this.unfilteredList = this.unfilteredList.sort((a: any, b: any) =>
        a.id > b.id ? this.sortDirection : -this.sortDirection
      );
    this.list = this.unfilteredList;
    console.log(this.list, this.unfilteredList, 'all vins');
    this.loading = false;
    setTimeout(() => {
      this.checkVideoLoaded();
    }, 1000);
    this.unfilteredList.forEach(element => {
      
      console.log(element.image);
      
    });

  }

  async checkVideoLoaded() {
    const pageVideos = document.getElementsByTagName('video');
    console.log(pageVideos);
    const instance = this;
    instance.updateMasonryLayout = Date.now().toString();
    for (let i = 0; i < pageVideos.length; i++) {
      pageVideos[i].addEventListener('canplay', function () {
        console.log('can play', i);
        instance.updateMasonryLayout = Date.now().toString();
      });
    }
  }

  sortItems() {
    if (this.sortType === 'comment_count')
      this.unfilteredList = this.unfilteredList.sort((a: any, b: any) =>
        a.commentCount > b.commentCount
          ? this.sortDirection
          : -this.sortDirection
      );
    else if (this.sortType === 'created_date')
      this.unfilteredList = this.unfilteredList.sort((a: any, b: any) =>
        a.id > b.id ? this.sortDirection : -this.sortDirection
      );
    this.searchItems();
  }

  searchItems() {
    this.list = this.unfilteredList.filter((_nft) => {
      const sPattern = this.searchText.toLowerCase();
      if (_nft.name.toLowerCase().search(sPattern) > -1) return true;
      if (_nft.description.toLowerCase().search(sPattern) > -1) return true;
      if (_nft.vinSn.toLowerCase().search(sPattern) > -1) return true;
      let attrSearchFlag = false;
      for (let idx = 0; idx < _nft.attributes?.length; idx++) {
        const _attr = _nft.attributes[idx];
        if (_attr.value.toLowerCase().search(sPattern) > -1) {
          attrSearchFlag = true;
          break;
        }
      }
      return attrSearchFlag;
    });
    this.showCount = SHOW_COUNT;
    setTimeout(() => this.masonry.reloadItems());
    this.updateMasonryLayout = Date.now().toString();
  }

  showMore() {
    this.showCount += SHOW_COUNT;
  }
}
