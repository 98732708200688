import { Component, OnInit, Input } from '@angular/core';
import { OffchainService } from 'src/app/services/offchain.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.scss']
})
export class MarketplaceComponent implements OnInit {
  @Input() recent: boolean;
  products: any = [];
  loading: boolean = false;
  isFavorited:boolean = false;
  itemId: any;
  processing: boolean = false;
  constructor(
    private readonly toastr: ToastrService,
    public readonly auth: AuthService,
    private readonly offchain: OffchainService) { }

  ngOnInit(): void {
    if(!this.recent) this.loading = true;
    this.loadData();
  }

  async loadData() {
    const allNfts = await this.offchain.getAllNfts();
    const data: any[] = [];
    allNfts.forEach((item: any, idx:number) => {
      item?.attributes?.some((element: any, index: number) => {
        if(this.recent){
          if (element.trait_type === "Asking Price" && data.length < 8) {
            data.push({
              name: item.name,
              image: item.image,
              id: item.id,
              price: element.value.indexOf('$') > -1 ? element.value : `$${element.value}`,
            })
          }
        } else {
          if (element.trait_type === "Asking Price") {
            data.push({
              name: item.name,
              image: item.image,
              id: item.id,
              price: element.value.indexOf('$') > -1 ? element.value : `$${element.value}`,
            })
          }
        }
      });

      if(item?.price){
        if(this.recent){
          if (data.length < 8) {
            data.push({
              name: item.name,
              image: item.image,
              id: item.id,
              price: item.price.indexOf('$') > -1 ? item.price : `$${item.price}`,
            })
          }
        } else {
            data.push({
              name: item.name,
              image: item.image,
              id: item.id,
              price: item.price.indexOf('$') > -1 ? item.price : `$${item.price}`,
            })
        }
      }
    });
    this.products = data;
    if(!this.recent) this.loading = false;
  }

  async toggleFavorite() {
    if (!this.auth.isLogin) {
      this.toastr.error(
        'You have to login add favorite'
      );
      return;
    }
    this.processing = true;
    if(this.isFavorited){
      await this.removeFromFavorites();
    }else{
      await this.addToFavorites();
    }
    this.toastr.success('Saved!');
    this.processing = false;
  }

  async addToFavorites(){
    const res = await this.offchain.addToFavorites(
      this.itemId,
      this.auth.curUser._id
    );
    this.isFavorited = true;
  }

  async removeFromFavorites(){
    await this.offchain.removeFromFavorites(
      this.itemId,
      this.auth.curUser._id
    )
    this.isFavorited = false;
  }
}
