import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

    //  ################## PRICING PLANS
    _pricePlan: string = 'enthusiast';
    priceEnthusiast = environment.mintFee.enthusiast;
    priceCollector = environment.mintFee.collector;
    priceProfessional = environment.mintFee.professional;
  
  constructor() { }

  ngOnInit(): void {
    
  }

}
