<head>
  <!-- Google tag (gtag.js) -->
  <script async src="https://www.googletagmanager.com/gtag/js?id=G-G4EFZRR20D"></script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-G4EFZRR20D");
  </script>
</head>
<div class="relative pt-8 mt-16">
  <div class="xl:w-[1156px] mx-auto lg:w-[900px] w-full relative">
    <div class="flex flex-col loadingBox absolute inset-0 items-center justify-center rounded-lg z-10 mt-20"
      *ngIf="loading || processing">
      <app-vin-spinner></app-vin-spinner>
      <div class="mt-2 text-2xl font-bold afont">Opening Garage Gate...</div>
    </div>
    <div *ngIf="!loading" class="px-6 md:px-0">
      <!-- Main Info -->
      <div class="flex flex-col md:flex-row gap-8">
        <div class="item-image">
          <img src="{{ singleItem.image }}" alt="item" *ngIf="!singleItem?.isVideo" (click)="openZoom(0)" />
          <video loop controlsList="nodownload" class="object-contain rounded-xl" controls *ngIf="singleItem?.isVideo"
            (click)="openZoom(0)">
            <source type="video/webm" [src]="singleItem.image + '\#t=0.001'" />
            <source type="video/mp4" [src]="singleItem.image + '\#t=0.001'" />
          </video>
        </div>
        <div class="item-content">

          <h1 class="item-content-title h1">{{ singleItem.name }}</h1>
          <div class="item-content-title flex items-center gap-4 w-full">
            <div class="item-content-buy flex flex-col md:flex-row gap-4 items-center">
              <button (click)="aprove_vintracker()" *ngIf="isYours && (walletAddress || auth.isLogin)"
                class="secondary-btn hover:opacity-90 active:opacity-75 w-full text-center md:w-auto">
                Approve
              </button>
              <button (click)="deny_vintracker()" *ngIf="isYours && (walletAddress || auth.isLogin)"
                class="secondary-btn hover:opacity-90 active:opacity-75 w-full text-center md:w-auto">
                Deny
              </button>

              <button *ngIf="!walletAddress && !auth.isLogin" class="login-button" (click)="openModal('login-modal')">
                Please login or sign up
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>