import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-market-place',
  templateUrl: './view-market-place.component.html',
  styleUrls: ['./view-market-place.component.scss']
})
export class ViewMarketPlaceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
