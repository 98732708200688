import { Component, OnInit } from '@angular/core';
import { WalletService } from 'src/app/services/wallet.service';
import { AuthService } from 'src/app/services/auth.service';
import { ModalService } from '../_modal';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  overlayClass: string = 'overlay';
  navClass: string = '';
  headerClass: string = '';
  iconClass: string = 'fas fa-bars';
  changeIcon: boolean = false;

  address: any;
  constructor(
    private readonly wallet: WalletService,
    public readonly auth: AuthService,
    private readonly modalService: ModalService,
  ) {}
  ngOnInit() {
    this.setAddress();
  }

  OpenNav() {
    if (this.changeIcon) {
      this.overlayClass = 'overlay';
      this.navClass = '';
      this.headerClass = '';
      this.iconClass = 'fas fa-bars';
      this.changeIcon = false;
    } else {
      this.overlayClass = 'overlay menu-open';
      this.iconClass = 'fas fa-times';
      this.navClass = 'menu-open';
      this.headerClass = 'header-opened-mobile';
      this.changeIcon = true;
    }
  }

  CloseNav() {
    this.changeIcon = true;
    this.OpenNav();
  }

  async setAddress() {
    if (!window.ethereum) return;
    const account = await window.ethereum.request({ method: 'eth_accounts' });
    this.address = account[0];
  }
  async connectWallet(): Promise<void> {
    this.address = await this.wallet.connectWallet();
  }
  logout() {
    this.auth.logout();
  }

  openLogin() {
    this.modalService.open('login-modal');
  }
}
