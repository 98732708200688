<div *ngIf="noAccess" class="w-full pt-16 font-semibold text-2xl md:text-3xl my-12 lg:px-0 text-center raceFont1">
  You have no access to update this item... :(
</div>
<div class="xl:w-[1156px] mx-auto lg:w-[900px] w-full pt-8" *ngIf="!noAccess">
  <h1
    class="px-4 font-semibold text-2xl md:text-3xl my-12 lg:px-0 text-center raceFont1"
  >
    Update Records for your <span class="color-crimson">V</span>I<span
      class="color-brand-blue"
      >N</span
    >-Tracker&trade;
  </h1>
  <div class="p-4 md:px-0 create relative">
    <div
      class="flex flex-col loadingBox absolute inset-0 items-center pt-16 md:pt-32 rounded-lg z-10"
      *ngIf="loading"
    >
      <app-vin-spinner></app-vin-spinner>
      <div class="mt-2 text-2xl font-bold text-center px-2">Engine is Running, this could take a few minutes to upload to the blockchain</div>
    </div>
    <div class="create-container bg-">
      <div
        class="writeForm grid grid-cols-1 md:grid-cols-2 md:gap-8"
        autoComplete="off"
      >
        <div class="col-span-2 md:col-span-1">
          <p class="upload-file">
            Please Upload Main Photo/Video for VIN-Tracker
          </p>
          <input
            type="file"
            id="upload"
            (change)="onFileChanged($event)"
            #fileInput
            class="custom-file-input hidden"
            accept="image/*, video/*"
          />
          <div class="upload-img-show">
            <label for="upload" class="cursor-pointer">Upload Again</label>
            <img
              src="{{ nft_image_content }}"
              class="w-[500px] h-[150px] object-scale-down"
              *ngIf="!isVideo"
            />
            <video
              loop
              controlsList="nodownload"
              class="w-[500px] object-contain"
              controls
              *ngIf="isVideo"
              [src]="nft_image_content"
            >
              <source type="video/webm" [src]="nft_image_content" />
              <source type="video/mp4" [src]="nft_image_content" />
            </video>
          </div>
          <div class="formGroup">
            <label
              class="text-lg font-semibold cursor-pointer"
              *ngIf="additionalMediaCount > currentMediaCount"
              for="uploadAdditional"
              >+ Add More ({{ currentMediaCount }} /
              {{ additionalMediaCount }})</label
            >
            <label
              class="text-lg font-semibold text-gray-500"
              *ngIf="additionalMediaCount <= currentMediaCount"
              >Cannot add more for this pricing plan</label
            >
            <input
              type="file"
              multiple="multiple"
              id="uploadAdditional"
              (change)="addExtraFile($event)"
              #fileInput
              class="custom-file-input hidden"
              accept="image/*, video/*"
            />
            <div
              class="flex flex-row flex-wrap gap-4"
              *ngIf="currentMediaCount > 0"
            >
              <div
                *ngFor="let _extra of additionMedias; let i = index"
                class="additionalMedia relative"
              >
                <img
                  src="{{ _extra.content }}"
                  class="h-28 object-contain rounded-xl"
                  *ngIf="!_extra.isVideo"
                />
                <video
                  loop
                  controlsList="nodownload"
                  class="h-28 object-contain rounded-xl"
                  controls
                  *ngIf="_extra.isVideo"
                >
                  <source type="video/webm" [src]="_extra.content" />
                  <source type="video/mp4" [src]="_extra.content" />
                </video>
                <i
                  class="far fa-times-circle absolute -top-2 -right-2 bg-white rounded-full text-xl leading-4 hover:opacity-90 active:opacity-75 cursor-pointer"
                  (click)="closeExtra(i)"
                ></i>
              </div>
            </div>
          </div>

          <div class="formGroup">
            <label>Vehicle Name</label>
            <input
              type="text"
              placeholder="Vehicle Name (20239.9 Porsche 911 Targa 4 GTS)"
              autoFocus="{true}"
              required
              [(ngModel)]="name"
            />
          </div>
          <div class="formGroup">
            <label>Vehicle Description</label>
            <textarea
              type="text"
             rows = "12"
              placeholder="Decription of your vehicle"
              [(ngModel)]="description"
              style="height: fit-content;"
            ></textarea>
          </div>
          <div class="formGroup">
            <label>Links</label>
            <textarea type="text" rows="2" placeholder=""
              [(ngModel)]="links"></textarea>
          </div>
          <!-- <div class="formGroup" *ngIf="!_receiver.startsWith('0x')">
            <label>Email</label>
            <span class="vin-input">{{ _receiver }}</span>
          </div> -->

          <div class="hidden md:block">
            <label class="custom-label flex p-2">
              <div
                class="bg-white shadow w-6 h-6 p-1 flex justify-center items-center mr-2"
              >
                <input type="checkbox" class="hidden" checked />
                <svg
                  class="hidden w-4 h-4 text-purple pointer-events-none"
                  viewBox="0 0 172 172"
                >
                  <g
                    fill="none"
                    stroke-width="none"
                    stroke-miterlimit="10"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style="mix-blend-mode: normal"
                  >
                    <path d="M0 172V0h172v172z" />
                    <path
                      d="M145.433 37.933L64.5 118.8658 33.7337 88.0996l-10.134 10.1341L64.5 139.1341l91.067-91.067z"
                      fill="blue"
                      stroke-width="1"
                    />
                  </g>
                </svg>
              </div>

              <span class="select-none"
                >Allow Email address notifications of comments and offers</span
              >
            </label>
            <label class="custom-label flex p-2">
              <div
                class="bg-white shadow w-6 h-6 p-1 flex justify-center items-center mr-2"
              >
                <input type="checkbox" class="hidden" checked />
                <svg
                  class="hidden w-4 h-4 text-purple pointer-events-none"
                  viewBox="0 0 172 172"
                >
                  <g
                    fill="none"
                    stroke-width="none"
                    stroke-miterlimit="10"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style="mix-blend-mode: normal"
                  >
                    <path d="M0 172V0h172v172z" />
                    <path
                      d="M145.433 37.933L64.5 118.8658 33.7337 88.0996l-10.134 10.1341L64.5 139.1341l91.067-91.067z"
                      fill="blue"
                      stroke-width="1"
                    />
                  </g>
                </svg>
              </div>
              <span class="select-none"
                >Allow Make Offers on Vin-Tracker details page
              </span>
            </label>
            <label class="custom-label flex p-2">
              <div
                class="bg-white shadow w-6 h-6 p-1 flex justify-center items-center mr-2"
              >
                <input type="checkbox" class="hidden" checked />
                <svg
                  class="hidden w-4 h-4 text-purple pointer-events-none"
                  viewBox="0 0 172 172"
                >
                  <g
                    fill="none"
                    stroke-width="none"
                    stroke-miterlimit="10"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style="mix-blend-mode: normal"
                  >
                    <path d="M0 172V0h172v172z" />
                    <path
                      d="M145.433 37.933L64.5 118.8658 33.7337 88.0996l-10.134 10.1341L64.5 139.1341l91.067-91.067z"
                      fill="blue"
                      stroke-width="1"
                    />
                  </g>
                </svg>
              </div>
              <span class="select-none"
                >Allow comments section on Vin-Tracker details page
              </span>
            </label>
          </div>
        </div>

        <div>
          <div class="formGroup" id = "details">
            <label>To Sell Your Vehicle</label>
            <div *ngIf="attributes">
              <!-- <button (click)="openModal('vehicle-detail-modal')"> -->
              <button (click)="openAttributeForm()">Set Asking Price & Details</button>
            </div>
          </div>

          <div class="formGroup">
            <label>VIN-Tracker Package</label>
            <div class="vin-input">
              {{ planList[_pricePlan].name }}
            </div>
          </div>

          <div class="formGroup">
            <label>VIN #</label>
            <input
              type="text"
              min="1"
              placeholder="17-character VIN."
              [(ngModel)]="vin"
              (change)="decodeMake()"
            />
            <div>{{ decodedMake }}</div>
          </div>

          <div class="formGroup">
            <div class="grid grid-cols-2 md:grid-cols-2 md:gap-8 mt-6 mb-6">
              <div class="form-check flex items-center">
                <input type="radio" style="width: 32px; margin-bottom: 20px;" id="yesRadio" [(ngModel)]="isForSale" value="Yes" name="listingOption">
                <label for="yesRadio" class="form-check-label">List for Sale</label>
              </div>
              <div class="form-check flex items-center">
                <input type="radio" style="width: 32px; margin-bottom: 20px;" id="noRadio" [(ngModel)]="isForSale" value="No" name="listingOption">
                <label for="noRadio" class="form-check-label">Not For Sale</label>
              </div>
            </div>
            <div class="formGroup" *ngIf="isForSale === 'Yes'">
              <label for="price">Price (Fill in Box $)</label>
              <input type="text" id="price" [(ngModel)]="price">
            </div>
          </div>
          
          <div *ngIf="_pricePlan == 'enthusiast'">
            <label
              >Digital Storage Vault is unavailable with this package.</label
            >
            <!-- <button
            type="button"
            (click)="openMintModal()"
            class="writeButton bg-redLeather"
          >
            Create VIN-Tracker 
          </button> -->
          </div>
          

          <div *ngIf="_pricePlan !== 'enthusiast'">
            <div class="formGroup" id = "vault">
              
            <div class="formGroup">
              <label> Service Records</label>
              <label
                for="upload_service_record"
                class="label-input cursor-pointer"
                >Upload Files</label
              >
              <input
                type="file"
                (change)="onFileChanged($event, 'service_record')"
                class="custom-file-input hidden"
                id="upload_service_record"
                #fileInput
              />
              <div class="flex flex-wrap gap-3">
                <div
                  *ngFor="
                    let serviceRecord of uploadData.service_record;
                    let i = index
                  "
                  class="relative w-fit-content bg-surface_blue rounded-lg py-1 px-5"
                >
                  <span class="text-white font-bold">{{
                    serviceRecord.type
                  }}</span>
                  <i
                    class="far fa-times-circle absolute -top-1 -right-1 bg-white rounded-full leading-4 hover:opacity-90 active:opacity-75 cursor-pointer"
                    (click)="closeRecord(i, 'service_record')"
                  ></i>
                </div>
              </div>
              <div>
                (Service records, mileage logs, receipts of parts purchased).
              </div>
            </div>

            <div class="formGroup">
              <label>Ownership & Registration</label>
              <label
                for="upload_ownership_record"
                class="label-input cursor-pointer"
                >Upload Files</label
              >
              <input
                type="file"
                (change)="onFileChanged($event, 'ownership')"
                class="custom-file-input hidden"
                id="upload_ownership_record"
                #fileInput
              />
              <div class="flex flex-wrap gap-3">
                <div
                  *ngFor="
                    let ownershipItem of uploadData.ownership;
                    let i = index
                  "
                  class="relative w-fit-content bg-surface_blue rounded-lg py-1 px-5"
                >
                  <span class="text-white font-bold">{{
                    ownershipItem.type
                  }}</span>
                  <i
                    class="far fa-times-circle absolute -top-1 -right-1 bg-white rounded-full leading-4 hover:opacity-90 active:opacity-75 cursor-pointer"
                    (click)="closeRecord(i, 'ownership')"
                  ></i>
                </div>
              </div>
              <div>
                (Title, registration, bills of sale, other provenance
                information).
              </div>
            </div>

            <label> Other Documents</label>
              <label for="upload_ext_record" class="label-input cursor-pointer"
                >Upload Files</label
              >
              <input
                type="file"
                (change)="onFileChanged($event, 'ext_record')"
                class="custom-file-input hidden"
                id="upload_ext_record"
                #fileInput
              />
              <div class="flex flex-wrap gap-3">
                <div
                  *ngFor="let extRecord of uploadData.ext_record; let i = index"
                  class="relative w-fit-content bg-surface_blue rounded-lg py-1 px-5"
                >
                  <span class="text-white font-bold">{{ extRecord.type }}</span>
                  <i
                    class="far fa-times-circle absolute -top-1 -right-1 bg-white rounded-full leading-4 hover:opacity-90 active:opacity-75 cursor-pointer"
                    (click)="closeRecord(i, 'ext_record')"
                  ></i>
                </div>
              </div>
              <div>
                (Build sheets, CARFAX&#174; reports, original sales brochures, and
                other important docs).
              </div>
            </div>
          </div>

          <div class="block md:hidden">
            <label class="custom-label flex p-2">
              <div
                class="bg-white shadow w-6 h-6 p-1 flex justify-center items-center mr-2"
              >
                <input type="checkbox" class="hidden" checked />
                <svg
                  class="hidden w-4 h-4 text-purple pointer-events-none"
                  viewBox="0 0 172 172"
                >
                  <g
                    fill="none"
                    stroke-width="none"
                    stroke-miterlimit="10"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style="mix-blend-mode: normal"
                  >
                    <path d="M0 172V0h172v172z" />
                    <path
                      d="M145.433 37.933L64.5 118.8658 33.7337 88.0996l-10.134 10.1341L64.5 139.1341l91.067-91.067z"
                      fill="blue"
                      stroke-width="1"
                    />
                  </g>
                </svg>
              </div>
              <span class="select-none"
                >Allow email notifications of comments and offers</span
              >
            </label>
            <label class="custom-label flex p-2">
              <div
                class="bg-white shadow w-6 h-6 p-1 flex justify-center items-center mr-2"
              >
                <input type="checkbox" class="hidden" checked />
                <svg
                  class="hidden w-4 h-4 text-purple pointer-events-none"
                  viewBox="0 0 172 172"
                >
                  <g
                    fill="none"
                    stroke-width="none"
                    stroke-miterlimit="10"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style="mix-blend-mode: normal"
                  >
                    <path d="M0 172V0h172v172z" />
                    <path
                      d="M145.433 37.933L64.5 118.8658 33.7337 88.0996l-10.134 10.1341L64.5 139.1341l91.067-91.067z"
                      fill="blue"
                      stroke-width="1"
                    />
                  </g>
                </svg>
              </div>
              <span class="select-none">Allow Make Offers on VIN Details</span>
            </label>
            <label class="custom-label flex p-2">
              <div
                class="bg-white shadow w-6 h-6 p-1 flex justify-center items-center mr-2"
              >
                <input type="checkbox" class="hidden" checked />
                <svg
                  class="hidden w-4 h-4 text-purple pointer-events-none"
                  viewBox="0 0 172 172"
                >
                  <g
                    fill="none"
                    stroke-width="none"
                    stroke-miterlimit="10"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style="mix-blend-mode: normal"
                  >
                    <path d="M0 172V0h172v172z" />
                    <path
                      d="M145.433 37.933L64.5 118.8658 33.7337 88.0996l-10.134 10.1341L64.5 139.1341l91.067-91.067z"
                      fill="blue"
                      stroke-width="1"
                    />
                  </g>
                </svg>
              </div>
              <span class="select-none"
                >Allow Comments on VIN-Tracker details page</span
              >
            </label>
          </div>
          <div class="formGroup" *ngIf="isAdmin">
            <label>Receiver Wallet</label>
            <input
              class="border border-dashed border-black px-4 py-2 rounded-lg w-100"
              type="text"
              placeholder="Receiver Wallet"
              autoFocus="{true}"
              required
              [(ngModel)]="_receiver"
            />
          </div>
        </div>
        <div class="flex items-center justify-end col-span-2 gap-4">
          <button
            type="button"
            (click)="openModal('update-password-modal')"
            class="writeButton bg-pedal"
          >
            Update Password
          </button>
          <!-- <button
            type="button"
            (click)="openMintModal()"
            class="writeButton bg-redLeather"
          >
            Update VIN-Tracker
          </button> -->
          <button
            type="button"
            (click)="onSubmit()"
            class="writeButton bg-redLeather"
          >
            Update VIN-Tracker
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Vehicle Details -->
<jw-modal id="vehicle-detail-modal">
  <form
    (ngSubmit)="setTraits()"
    [formGroup]="addAttributesForm"
    class="flex items-center justify-center w-full flex-col min-h-[27vh]"
  >
    <h2 class="text-2xl font-semibold mb-4">Vehicle Details</h2>

    <div class="grid grid-cols-1 md:grid-cols-2 px-10 md:px-0">
      <div
        class="attributes flex items-center justify-center"
        formArrayName="attributes"
        *ngFor="let attribute of attributes?.controls; let i = index"
      >
        <div [formGroupName]="i" class="mb-4">
          <div
            class="font-semibold mr-2 cursor-pointer"
            (click)="removeTrait(i)"
          >
            X
          </div>
          <div class="formGroup mr-2">
            <input
              type="text"
              placeholder="Attribute Type"
              class="font-semibold mb-2"
              formControlName="trait_type"
              id="{{ 'trait_type' + i }}"
              data-testid="Input"
              [value]="attribute.value.trait_type"
            />
            <input
              type="text"
              placeholder="Input {{ attribute.value.trait_type }}"
              formControlName="value"
              id="{{ 'value' + i }}"
              data-testid="Input"
              value=""
            />
          </div>
        </div>
      </div>
    </div>

    <div>
      <button class="writeButton bg-vin-blue mr-2" (click)="addTrait()">
        Add More
      </button>
      <button
        type="submit"
        (click)="closeModal('vehicle-detail-modal')"
        class="writeButton bg-gray-500"
      >
        Save Details
      </button>
    </div>
  </form>
</jw-modal>

<!-- Stripe Checkout -->
<jw-modal id="stripe-checkout-modal">
  <div
    class="relative flex flex-col items-center justify-between md:gap-6 pt-6"
  >
    <i
      class="fas fa-times-circle absolute right-0 top-0 cursor-pointer"
      (click)="closeModal('stripe-checkout-modal')"
    ></i>
    <form
      #checkout="ngForm"
      (ngSubmit)="onSubmit()"
      class="checkout py-4 px-2 md:px-8"
    >
      <div class="form-row">
        <label for="card-info" class="text-xl font-semibold">Card Info</label>
        <div id="card-info" class="mt-5" #cardInfo></div>
        <div
          id="card-errors"
          role="alert"
          class="mt-1 text-sm text-red-800"
          *ngIf="stripeError"
        >
          {{ stripeError }}
        </div>
      </div>
      <div class="flex justify-center">
        <button
          type="submit"
          class="writeButton bg-redLeather mt-8"
          *ngIf="!stripeProcessing"
        >
          Pay ${{ mintFee }}
        </button>
        <button class="writeButton bg-redLeather mt-8" *ngIf="stripeProcessing">
          Processing...
        </button>
      </div>
    </form>
  </div>
</jw-modal>

<!-- Select Mint Way -->
<!-- <jw-modal id="select-mint-mode-modal">
  <div
    class="relative flex flex-col items-center justify-between gap-3 md:gap-6 pt-6"
  >
    <i
      class="fas fa-times-circle absolute right-0 top-0 cursor-pointer"
      (click)="closeModal('select-mint-mode-modal')"
    ></i>
    <img src="../../../assets/images/cc.png" width="35" />
    <button
      class="primary-btn hover:opacity-90 active:opacity-75"
      (click)="mintPrimary()"
    >
      Use Credit / Debit Card
    </button>
    <hr />
    <p class="raceFont1">Advanced Web3</p>
    <img src="../../../assets/images/polygon.png" width="35" />
    <p>
      Web3 requires 2 transactions on the Polygon Matic blockchain for USDC.
    </p>
    <p>Step 1: To approve the USDC spend.</p>
    <p>
      <i
        >(Approval is for the package amount, thus in case of multiple
        VIN-Tracker purchases, each purchase requires a separate approval).</i
      >
    </p>
    <p>Step 2. To Purchase.</p>

    <button
      class="primary-btn hover:opacity-90 active:opacity-75"
      (click)="closeModal('select-mint-mode-modal'); approveToken()"
      *ngIf="mintFee > allowedAmount && !isAdmin"
    >
      Use WEB3 USDC on POLYGON (STEP 1 of 2: Approve USDC)
    </button>
    <button
      class="primary-btn hover:opacity-90 active:opacity-75"
      (click)="closeModal('select-mint-mode-modal'); updateVin()"
      *ngIf="mintFee <= allowedAmount || isAdmin"
    >
      Use WEB3 USDC on POLYGON (2/2 Mint Your VIN-Tracker)
    </button>
    <img src="../../../assets/images/usdc.svg" width="35" />
  </div>
</jw-modal> -->
<jw-modal id="select-mint-mode-modal">
  <div class="relative flex flex-col items-center justify-between pt-6">
    <i
      class="fas fa-times-circle absolute right-0 top-0 cursor-pointer"
      (click)="closeModal('select-mint-mode-modal')"
    ></i>
    <img src="../../../assets/images/cc.png" width="35" />
    <button
      class="primary-btn hover:opacity-90 active:opacity-75 mt-6"
      (click)="mintPrimary()"
    >
      Use Credit / Debit Card
    </button>
    <hr />
    <br />
    <div *ngIf="!showWeb3Payment">
      <button
        class="primary-btn hover:opacity-90 active:opacity-75"
        (click)="toggleWeb3()"
      >
        Show Advanced Web3 Payment Options.
      </button>
    </div>

    <!-- ##### WEB3 PAYMENT SCREEN -->

    <div *ngIf="showWeb3Payment" class="text-center">
      <span class="raceFont3"
        >Advanced Web3 - USE USDC on POLYGON Blockchain</span
      >
      <div
        class="relative flex flex-col items-center justify-between md:gap-6 pt-6"
      >
        <img
          src="../../../assets/images/polygon.png"
          width="35"
          class="text-center"
        />
      </div>

      <p>Web3 requires 2 transactions.</p>
      <br />
      <br />

      <div *ngIf="mintFee <= allowedAmount || isAdmin">
        <b>Step 1 of 2. COMPLETE. Approved USDC Spend </b>
      </div>

      <button
        class="warning-btn hover:opacity-90 active:opacity-75 bg-surface_blue"
        (click)="approveToken()"
        *ngIf="mintFee > allowedAmount && !isAdmin"
      >
        Step 1 of 2. Push to Approve USDC Spend
      </button>

      <p class="text-sm" *ngIf="mintFee > allowedAmount && !isAdmin">
        <i
          >(Approval is for the package amount, thus in case of multiple
          VIN-Tracker purchases, each purchase requires a separate approval).</i
        >
      </p>

      <br />
      <div
        class="relative flex flex-col items-center justify-between md:gap-6 pt-6"
      >
        <img
          src="../../../assets/images/usdc.svg"
          width="35"
          class="text-center"
        />
      </div>
      <br />
      <br />

      <!-- this button doesn't do anything on purpose -->
      <button
        class="disabled-btn hover:opacity-90 active:opacity-75"
        *ngIf="mintFee > allowedAmount && !isAdmin"
      >
        Step 2 of 2. Waiting for approval.
      </button>

      <button
        class="success-btn hover:opacity-90 active:opacity-75"
        (click)="closeModal('select-mint-mode-modal'); updateVin()"
        *ngIf="mintFee <= allowedAmount || isAdmin"
      >
        Step 2 of 2. Push to Create VIN-Tracker
      </button>

      <br />
      <div *ngIf="showWeb3Payment">
        <br />
        <br />
        <button
          class="primary-btn hover:opacity-90 active:opacity-75"
          (click)="toggleWeb3()"
        >
          Hide Advanced Web3 Payment Options.
        </button>
      </div>
    </div>
    <!-- ## END OF WEB3 PAYMENT  -->
  </div>
  <!-- ## END OF SELECT MINT/PAYMENT  -->
</jw-modal>

<!-- Upload External -->
<jw-modal id="upload-external-modal">
  <div class="flex flex-col items-center">
    <h2 class="text-2xl font-semibold text-center mb-6">
      Upload {{ getUploadTypeText() }}
    </h2>
    <input
      type="text"
      placeholder="Choose doc type below or type in your own label"
      required
      [(ngModel)]="tmpDataType"
      class="vin-input"
    />
    <div class="mt-4 flex items-center gap-3 w-full flex-wrap">
      <button
        *ngFor="let item of docType[uploadType]"
        class="w-fit-content bg-surface_blue rounded-lg py-1 px-5 text-white font-semibold"
        (click)="tmpDataType = item.name"
      >
        {{ item.name }}
      </button>
    </div>
    <!-- <ng-select
      [items]="docType[uploadType]"
      bindLabel="name"
      bindValue="name"
      placeholder="Choose document type"
      [(ngModel)]="tmpDataType"
      class="w-full"
    >
    </ng-select> -->
    <div class="vin-input mt-4">{{ tmpUploadFile?.name }}</div>
    <button class="writeButton bg-vin-blue mt-6" (click)="confirmUpload()">
      Upload
    </button>
  </div>
</jw-modal>

<!-- Update Password -->
<jw-modal id="update-password-modal">
  <div class="flex flex-col items-center">
    <h2 class="text-2xl font-semibold text-center mb-6">
      Update Vault Password
    </h2>
    <input *ngIf = "curPassword.length>0"
      type="password"
      placeholder="Enter vault password or leave blank if never set"
      required
      [(ngModel)]="formOldPassword"
      class="vin-input"
    />
    <input
      type="password"
      placeholder="Enter new password for vault..."
      required
      [(ngModel)]="formNewPassword"
      class="vin-input mt-4"
    />
    <input
      type="password"
      placeholder="Repeat password..."
      required
      [(ngModel)]="formNewConfirmPassword"
      class="vin-input mt-2"
    />
    <div class="flex justify-center">
      <button
        class="writeButton bg-vin-blue mt-6"
        *ngIf="!isUpdatingPassword"
        (click)="updatePassword()"
      >
        Update
      </button>
      <button
        class="writeButton bg-vin-blue mt-6"
        *ngIf="isUpdatingPassword"
      >
        Securing Vault Access...
      </button>
    </div>
    <br>
    <h2 class="text-xl font-semibold text-center mb-6">
      You may leave the new password blank to make records available publically.
    </h2>

  </div>
</jw-modal>
