import { Component, OnInit, Input } from '@angular/core';
import { VindataserviceService } from 'src/app/services/vindataservice.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-featured',
  templateUrl: './featured.component.html',
  styleUrls: ['./featured.component.scss']
})
export class FeaturedComponent implements OnInit {
  @Input() vin: VinNftType[];
  @Input() allFlag: boolean;
  currentIndex: number = 0;  // Index of currently displayed vin
  vinEstimate = "0";

  constructor(
    private readonly vindata: VindataserviceService,
    private router: Router, // Inject the Router
  ) { }
  
  // Navigate to the item details page when a vin is clicked
  onVinClick(vin: VinNftType) {
    this.router.navigate(['/item', vin.id]);
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    const currentId = this.vin[this.currentIndex]?.id;
    if (typeof currentId !== 'undefined') {
      this.vinEstimate = this.vindata.getVinEstimateById(currentId);
    }
  }
  

  nextVin() {
    // Increment currentIndex, and wrap around if at the end of the array
    this.currentIndex = (this.currentIndex + 1) % this.vin.length;
    this.loadData();
  }

  previousVin() {
    // Decrement currentIndex, and wrap around if at the start of the array
    this.currentIndex = (this.currentIndex - 1 + this.vin.length) % this.vin.length;
    this.loadData();
  }
}
