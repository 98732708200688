import { Component, OnInit } from '@angular/core';
import { e } from 'mathjs';
import { IPricingData, PricingData } from 'src/assets/data/pricing-data';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
})
export class PricingComponent implements OnInit {
  data: IPricingData[] = PricingData;
  constructor() {}

  priceProfessional = environment.mintFee.professional.toString();

  ngOnInit(): void {


  }

  contactUs() {
    window.open('mailto:info@vin-tracker.com');
  }
}
