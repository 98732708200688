import { Component, Input, OnInit } from '@angular/core';
import { IHowItWorksData } from 'src/assets/data/how-it-works-data';

@Component({
  selector: 'app-single-card-gradient',
  templateUrl: './single-card-gradient.component.html',
  styleUrls: ['./single-card-gradient.component.scss'],
})
export class SingleCardGradientComponent implements OnInit {
  @Input() singleItem: IHowItWorksData;

  constructor() {}

  ngOnInit(): void {}
}
