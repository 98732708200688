import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { AuthService } from 'src/app/services/auth.service';
import { OffchainService } from 'src/app/services/offchain.service';
import { ModalService } from 'src/app/components/_modal';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  articles: any[] | null = null;
  isLoading: boolean = false;
  article: any | null = null;
  articleDate: string | null = null;
  articleFile: any | null = null;
  articleId: string | null = null;
  comment: string | null = null;
  user: string;

  constructor(
    private meta: Meta,
    private route: ActivatedRoute,
    private authService: AuthService,
    private offchain: OffchainService,
    private readonly toastr: ToastrService,
    private modalService: ModalService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      this.isLoading = true;
      this.articleId = params.id;
      this.user = this.authService.curUser;
      this.getArticleById();
      this.getRecentArticles();
    });
  }

  async createComment() {
    this.isLoading = true;
    if (!this.authService.isLogin) {
      this.isLoading = false;
      this.toastr.error('Please login first to submit your comment.');
      this.modalService.open('login-modal');
      return;
    }
    if (!this.comment && this.comment?.trim() !== '') {
      this.toastr.error('Please fill out comment feild.');
      this.isLoading = false;
      return;
    }
    const user = this.authService.curUser;
    delete user.hash;
    const data = {
      id: this.articleId,
      user: user,
      comment: this.comment,
    }
    const res = await this.offchain.createCommentOfArticle(data);
    if (res) {
      this.getArticleById();
    }
  }

  async getArticleById() {
    this.article = await this.offchain.getArticle(this.articleId);
    if (this.article.mimeType && this.article.mimeType !== 'null' && this.article.mimeType !== '') {
      // this.article.imageUrl = `${environment.offchainApi}/${this.article.imageUrl}`;
    }
    const image_url = this.article.imageUrl ? this.article.imageUrl : this.article.videolink;
    console.log(image_url, 'image_url')
    this.meta.updateTag({
      name: 'title',
      content: this.article.title,
    });
    this.meta.updateTag({
      name: 'description',
      content: this.article.story,
    });
    this.meta.addTags([
      {
        property: 'og:url',
        content: 'https://vin-tracker.com/',
      },
      {
        property: 'og:site_name',
        content: 'Vin-Tracker.com',
      },
      {
        property: 'og:title',
        content: this.article.title,
      },
      {
        property: 'og:description',
        content: this.article.story,
      },
      {
        property: 'og:image',
        content: image_url,
      },
    ]);
    this.article.story = this.renderLinks(this.article.story);
    this.articleDate = this.convertDateString(this.article.createdAt);
    this.isLoading = false;
  }

  async doLikeComment(commentIndex: any) {
    this.isLoading = true;
    if (!this.authService.isLogin) {
      this.isLoading = false;
      this.toastr.error('Please login first.');
      this.modalService.open('login-modal');
      return;
    }
    const data = {
      id: this.articleId,
      commentIndex,
      user: this.user
    }
    try {
      const res = await this.offchain.likeComment(data);
      if (res.result === 'your comment') {
        this.toastr.warning('You can not recommend your comment.');
        this.isLoading = false;
        return;
      }
      if (res.result === 'your like') {
        this.toastr.warning('You already recommend this comment.');
        this.isLoading = false;
        return;
      }
      this.getArticleById();
    } catch (error: any) {
      this.toastr.error(`Unexpected error happened. ${error}`);
      this.isLoading = false;
    }
  }

  async getRecentArticles() {
    this.articles = await this.offchain.getRelevantArticles(this.articleId);
    this.articles?.map((article: any) => {
      if (article.imageUrl) {
        // article.imageUrl = `${environment.offchainApi}/${article.imageUrl}`;
        return article;
      }
    })
  }

  convertDateString(dateStr: string) {
    const date = new Date(dateStr);
    const year = date.getUTCFullYear();
    const month = date.toLocaleString('default', { month: 'long' });
    const day = date.getUTCDate();
    return `Posted on ${month} ${day}, ${year}`;
  }

  renderLinks(text: string): string {
    if (text) {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      text = text.replace(urlRegex, '<a href="$1" class="text-blue-500" title="Please click here to view more" target="_blank">$1</a>');
      text = text.replace(/\n/g, '<br>');
    }
    return text;
  }
}
