import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { WalletService } from 'src/app/services/wallet.service';
import { OffchainService } from 'src/app/services/offchain.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  list: VinNftType[] = [];
  profileAddress: string;
  loading: boolean = true;
  walletAddress: any;
  isYours: boolean = false;
  nftOwnerUsername: string;
  updateMasonryLayout: any;

  constructor(
    private route: ActivatedRoute,
    private readonly offchain: OffchainService,
    public readonly auth: AuthService,
    private readonly wallet: WalletService
  ) {}

  ngOnInit(): void {
    this.profileAddress = this.route.snapshot.paramMap.get('id') || '0x';
    this.loadData();
  }

  async loadData() {
    this.list = await this.offchain.getNftbyOwner(this.profileAddress);
    this.walletAddress = await this.wallet.getAccount();
    if (
      this.walletAddress?.toLowerCase() ===
        this.profileAddress?.toLowerCase() ||
      this.auth.curUser?._id === this.profileAddress
    )
      this.isYours = true;
    if (
      this.auth.isLogin &&
      this.isYours &&
      this.profileAddress.startsWith('0x')
    ) {
      console.log('load my email nfts');
      const emailList = await this.offchain.getNftbyOwner(
        this.auth.curUser._id
      );
      this.list = this.list.concat(emailList);
    }
    if (!this.isYours && !this.profileAddress.startsWith('0x')) {
      this.nftOwnerUsername = await this.offchain.getUsernameById(
        this.profileAddress
      );
    }
    console.log(this.list, 'vins by owner');
    this.loading = false;
    setTimeout(() => {
      this.checkVideoLoaded();
    }, 1000);
  }

  async checkVideoLoaded() {
    const pageVideos = document.getElementsByTagName('video');
    const instance = this;
    instance.updateMasonryLayout = Date.now().toString();
    for (let i = 0; i < pageVideos.length; i++) {
      pageVideos[i].addEventListener('canplay', function () {
        console.log('can play', i);
        instance.updateMasonryLayout = Date.now().toString();
      });
    }
  }

  getUserName() {
    if (this.isYours && this.auth.isLogin) return this.auth.curUser.username;
    if (!this.isYours) return this.nftOwnerUsername;
  }
}
