


    <div class="xl:w-[1156px] mx-auto lg:w-[900px] w-full pt-8">
    <div  *ngIf="!isPasswordCorrect" class="container">
        <h1>Password Protected Page ACCESS IS LOGGED</h1>
        <input type="password" [(ngModel)]="password">
        
        <button (click)="checkPassword()">Submit</button>
    </div>



    <div *ngIf="isPasswordCorrect">
        <h1>ADMIN PANEL ACCESS IS LOGGED</h1>
        <div>
            CREATE NEW USER:
        <form>
            <div>
              <label for="email">Email:</label>
              <input type="email" id="email" name="email">
            </div>
            <div>
              <label for="username">Username:</label>
              <input type="text" id="username" name="username">
            </div>
            <div>
              <label for="password">Password:</label>
              <input type="password" id="password" name="password">
            </div>
            <button type="button" onclick="createNewUser(document.getElementById('email').value, document.getElementById('username').value, document.getElementById('password').value)">CREATE NEW USER</button>
          </form>
        </div>
          

        
        <table>
            <tr>
              <th>ID</th>
              <th>VIN-NAME</th>
              <th>VIN-OWNER</th>
              <th>OWNERS U/N</th>

              <th>Action</th>
            </tr>
            <tr *ngFor="let vin of vins; let i = index">
              <td>{{ vin.id }}</td>
              <td>{{ vin.name }}</td>
              <td>{{ vin.owner }}</td>
              <td>{{ usernames[i]}}</td>
              <td>
                <button (click)="openModal(i)">Change Owner</button>
                <div *ngIf="modalVisible && modalIndex === i">
                  <select [(ngModel)]="selectedOwner">
                    <option *ngFor="let owner of owners">{{ owner }}</option>
                  </select>
                  <input type="text" [(ngModel)]="customOwner" *ngIf="selectedOwner === 'Custom Owner'"/>
                  <button (click)="changeOwner(i)">Save</button>
                  <button (click)="closeModal()">Cancel</button>
                </div>
              </td>
            </tr>
          </table>
          
    </div>

    </div>


