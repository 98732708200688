<div class="w-full px-0 home-container">
  <div class="flex flex-col loadingBox absolute inset-0 items-center justify-center rounded-lg z-10" *ngIf="loading">
    <app-spinner2></app-spinner2>
    <div class="mt-2 text-2xl font-bold text-center">{{ loadText }}</div>
  </div>
  <div *ngIf="!loading" class="color-black">
    <div class="text-center afont font-bold">
      <div class="h-[50vh] sm:h-[60vh] md:h-[80vh] lg:h-screen banner text-white relative">
        <div
          class="absolute bottom-0 py-4 lg:py-6 2xl:py-10 left-6 md:-24 flex flex-col items-center justify-between h-[75%] 2xl:h-full w-auto">
          <div class="hidden xl:block"></div>
          <div class="ml-0 lg:ml-20 mb-0 lg:mb-24 text-3xl sm:text-4xl md:text-5xl text-white default-font leading-12 tracking-wider banner-text text-center md:text-left">
            Document Your Vehicle<br /> On The Blockchain
          </div>
          <div class="h-20 mt-8"></div>
        </div>

        <div class="absolute bottom-4 md:bottom-10 left-0 flex items-center justify-between w-full px-4 md:px-8 lg:px-24">
          <button [routerLink]="['/create/enthusiast']"
            class="list-btn default-font hover:bg-white py-2 px-2 sm:py-3 sm:px-3 md:py-4 md:px-6 rounded-full text-sm md:text-base">
            START Documenting
          </button>
          <button [routerLink]="['/create/enthusiast']"
            class="list-btn default-font hover:bg-white py-2 px-2 sm:py-3 sm:px-3 md:py-4 md:px-6 rounded-full text-sm md:text-base">
            START Selling
          </button>
        </div>
      </div>
      <app-featured [vin]=featureVins></app-featured>
      <app-marketplace [recent]="true"></app-marketplace>
      <div class="mt-20" *ngIf="featureVins.length > 0">
        <div class="layout-main">
          <h1 class="px-4 font-semibold text-3xl md:text-4xl mb-12 raceFont3 color-black items-center">
          </h1>
        </div>
      </div>
      <div class="mt-20 text-center">
        <app-explorer></app-explorer>
      </div>
    </div>
  </div>