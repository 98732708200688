<div class="limiter">
  <div class="container-table100">
    <div class="wrap-table100">
      <div class="table100">
        <table>
          <thead>
            <tr class="table100-head">
              <th class="column1">Type</th>
              <th class="column2">Item No</th>
              <th class="column3">Price</th>
              <th class="column4">From</th>
              <th class="column5">To</th>
              <th class="column6">Date</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of RowsData">
              <td class="column1">{{ item.type }}</td>
              <td class="column2">#{{ item.itemNo }}</td>
              <td class="column3">{{ item.price }} ETH</td>
              <td class="column4">
                {{ item.from.slice(0, 3) }}...{{
                  item.from.slice(item.from.length - 3)
                }}
              </td>
              <td class="column5">
                {{ item.to.slice(0, 3) }}...{{
                  item.to.slice(item.to.length - 3)
                }}
              </td>
              <td class="column6">{{ item.date }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
