import { NgModule } from '@angular/core';
import { DatePipe, CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { QRCodeModule } from 'angularx-qrcode';
import { TagInputModule } from 'ngx-chips';
import { NgxMasonryModule } from 'ngx-masonry';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ModalModule } from './components/_modal';
import { NgSelectModule } from '@ng-select/ng-select';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { AppComponent } from './app.component';
import { LayoutComponent } from './components/layout/layout.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SingleItemCardComponent } from './components/single-item-card/single-item-card.component';
import { SingleMarketplaceItemCardComponent } from './components/single-marketplace-item-card/single-marketplace-item-card.component';
import { PricingComponent } from './screens/pricing/pricing.component';
import { ItemDetailsComponent } from './screens/item-details/item-details.component';
import { ItemTransferComponent } from './screens/itemTransfer/itemTransfer.component';
import { HomeComponent } from './screens/home/home.component';
import { HistoryTableComponent } from './components/history-table/history-table.component';
import { FooterComponent } from './components/footer/footer.component';
import { CreateComponent } from './screens/create/create.component';
import { UpdateComponent } from './screens/update/update.component';
import { ProfileComponent } from './screens/profile/profile.component';
import { ExplorerComponent } from './screens/explorer/explorer.component';
import { SingleCardGradientComponent } from './components/single-card-gradient/single-card-gradient.component';
import { CommentSectionComponent } from './components/comment-section/comment-section.component';
import { DateAsAgoPipe } from './pipes/date-as-ago.pipe';
import { AboutComponent } from './components/about/about.component';
import { VinSpinnerComponent } from './components/spinner/spinner.component';
import { ToastrModule } from 'ngx-toastr';
import { WalletService } from './services/wallet.service';
import { VinNFTService } from './services/vinNFT.service';
import { MathService } from './services/math.service';
import { OffchainService } from './services/offchain.service';
import { AuthService } from './services/auth.service';
import { CoinListService } from './services/coinlist.service';
import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
} from '@angular/platform-browser/animations';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { FaqComponent } from './faq/faq.component';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { BrowseComponent } from './screens/browse/browse.component';
import { TestComponent } from './components/test/test.component';
import { Spinner2Component } from './components/spinner2/spinner2.component';
import { StartVinButtonComponent } from './components/start-vin-button/start-vin-button.component';
import { ConciergeComponent } from './screens/concierge/concierge.component';
import { AdminComponent } from './screens/admin/admin.component';
import { ListingsComponent } from './screens/listings/listings.component';
import { GameComponent } from './screens/game/game.component';
import { CookieService } from 'ngx-cookie-service';
import { HowitworksComponent } from './components/howitworks/howitworks.component';
import { FeaturedComponent } from './components/featured/featured.component';
import { ArticlesSectionComponent } from './articles-section/articles-section.component';
import { ArticleSectionComponent } from './article-section/article-section.component';
import { ViewArticleSectionComponent } from './view-article-section/view-article-section.component';
import { ViewAllArticlesComponent } from './view-all-articles/view-all-articles.component';
import { MarketplaceComponent } from './components/marketplace/marketplace.component';
import { BlogComponent } from './screens/blog/blog.component';
import { ViewMarketPlaceComponent } from './screens/view-market-place/view-market-place.component';
import { CreateWizardFormComponent } from './create-wizard-form/create-wizard-form.component';
import { PredictiveMakeModelTextComponent } from './components/predictive-make-model-text/predictive-make-model-text.component';
import { PredictiveYearTextComponent } from './components/predictive-year-text/predictive-year-text.component';
import { ContactUsComponent } from './screens/contact-us/contact-us.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LayoutComponent,
    SingleItemCardComponent,
    SingleMarketplaceItemCardComponent,
    ItemDetailsComponent,
    ItemTransferComponent,
    HomeComponent,
    HistoryTableComponent,
    FooterComponent,
    CreateComponent,
    UpdateComponent,
    ProfileComponent,
    ExplorerComponent,
    SingleCardGradientComponent,
    PricingComponent,
    CommentSectionComponent,
    DateAsAgoPipe,
    AboutComponent,
    VinSpinnerComponent,
    PrivacyComponent,
    FaqComponent,
    BrowseComponent,
    TestComponent,
    Spinner2Component,
    StartVinButtonComponent,
    ConciergeComponent,
    AdminComponent,
    ListingsComponent,
    GameComponent,
    HowitworksComponent,
    FeaturedComponent,
    ArticlesSectionComponent,
    ArticleSectionComponent,
    ViewArticleSectionComponent,
    ViewAllArticlesComponent,
    MarketplaceComponent,
    BlogComponent,
    ViewMarketPlaceComponent,
    CreateWizardFormComponent,
    PredictiveMakeModelTextComponent,
    PredictiveYearTextComponent,
    ContactUsComponent,
  ],
  imports: [
    CommonModule,
    NgxMasonryModule,
    NgSelectModule,
    QRCodeModule,
    BrowserModule,
    AppRoutingModule,
    TagInputModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ModalModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      easeTime: 300,
      progressBar: true
    }),
    IvyCarouselModule,
    LazyLoadImageModule
  ],
  providers: [
    DatePipe,
    WalletService,
    MathService,
    VinNFTService,
    OffchainService,
    AuthService,
    CoinListService,
    CookieService,
  ],
  bootstrap: [AppComponent],
  exports: [DateAsAgoPipe],
})
export class AppModule {}
