<div class="container">
    <div class="footer flex flex-col md:flex-row items-center justify-between text-center md:text-left">
    
        <!-- copyright and branded company name -->
        <div class="mb-2 md:mb-0 md:flex items-center justify-center raceFont1">
            <div class="text-white">
                &copy; 2022 - 2023 &nbsp;&nbsp;
            </div>
            <a routerLink="/">
                <img src="../../../assets/images/vin-track-new.png" alt="VIN-Tracker logo"  width="200px" />
            </a>
        </div>
    
        <!-- links to the various pages -->
        <div class="mb-2 md:mb-0">
            <ul
                class="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-8 text-[#808080] text-sm font-bold footerUl">
                <li>
                    <a routerLink="/faq">FAQ</a>
                </li>
                <li>
                    <a routerLink="/privacy">Privacy</a>
                </li>
            </ul>
        </div>
    
        <!-- disclaimer and attribution -->
        <div class="text-xs">
            <p class="mb-1 text-white">
                <a routerLink="/tos">BY USING THIS SITE YOU AGREE TO OUR TERMS OF SERVICE & COOKIE POLICY. CLICK TO VIEW AND
                    SET COOKIE OPTIONS</a>
            </p>
            <p class="text-white">
                *Zillow is a registered trademark of Zillow Group and is not affiliated with Vin-Tracker&trade; v.12.2.23
            </p>
         
        </div>
    
    </div>
    <button *ngIf="showScrollButton" (click)="scrollToTop()" class="bottom-to-up-button">🔝</button>
</div>