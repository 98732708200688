<div class="flex flex-col loadingBox absolute inset-0 items-center pt-32 rounded-lg z-10" *ngIf="loading">
    <app-vin-spinner></app-vin-spinner>
    <div class="mt-2 text-2xl font-bold">Loading featured...</div>
</div>
<div class="flex flex-col">
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4 sm:px-2 md:px-8">
        <div *ngFor="let article of articles">
            <a [routerLink]="'/featured/'+ article._id">
                <div class="mt-2 bg-white rounded-lg shadow-md overflow-hidden">
                    <div *ngIf="!article.imageUrl">
                        <img [src]="article.videolink" alt="Article Image" *ngIf="!article?.isvideo" class="w-full h-48 object-cover">
                        <video loop controlsList="nodownload" class="w-full h-48" controls *ngIf="article?.isvideo">
                            <source type="video/webm" [src]="article.videolink" />
                            <source type="video/mp4" [src]="article.videolink" />
                        </video>
                    </div>
                    <div *ngIf="article.imageUrl">
                        <img [src]="article.imageUrl" alt="Article Image" *ngIf="!article?.isvideo" class="w-full h-48 object-cover">
                        <video loop controlsList="nodownload" class="w-full h-48" controls *ngIf="article?.isvideo">
                            <source type="video/webm" [src]="article.imageUrl" />
                            <source type="video/mp4" [src]="article.imageUrl" />
                        </video>
                    </div>
                    <div class="p-4">
                        <h2 class="text-xl text-left font-semibold mb-2 line-clamp-1">{{ article.title }}</h2>
                        <p class="text-left text-gray-700 mb-4 line-clamp-3">{{ article.story}}</p>
                    </div>
                </div>
            </a>
        </div>
    </div>
    <!-- <div class="mx-auto container mt-5">
        <a *ngIf="!allFlag" routerLink="/featured" id="btn-view" class="bg-blue-500 text-white text-2xl px-4 py-2 rounded-lg">View All Featured</a>
    </div> -->
</div>