
<div class='loading'>
    <div class='loading__square'></div>
    <div class='loading__square'></div>
    <div class='loading__square'></div>
    <div class='loading__square'></div>
    <div class='loading__square'></div>
    <div class='loading__square'></div>
    <div class='loading__square'></div>
  </div>

