import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MathService } from '../services/math.service';
import { OffchainService } from '../services/offchain.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-article-section',
  templateUrl: './article-section.component.html',
  styleUrls: ['./article-section.component.scss']
})
export class ArticleSectionComponent implements OnInit {
  @Input() article: any;
  @Output() removeEvent = new EventEmitter<void>();
  @Output() updateEvent = new EventEmitter<void>();

  constructor(
    private readonly toastr: ToastrService,
    private readonly offchain: OffchainService,
  ) { }

  ngOnInit(): void {
    // if (this.article.imageUrl){
    //   // const base64Data = this.mathService.arrayBufferToBase64(this.article.file.data);
    //   // const mimeType = this.article.mimeType;
    //   this.article.imageUrl = `${environment.offchainApi}/${this.article.imageUrl}`;
    // }
  }

  removeArticle() {
    if (window.confirm('Do you really want to remove this article?') === true) {
      this.removeEvent.emit(this.article._id);
    }
  }

  updateArticle() {
    this.updateEvent.emit(this.article._id);
  }

  async toggleEnable() {
    this.article.status = !this.article.status;
    const article = this.article;
    const data = {
      articleId: article._id,
      status: article.status,
    }
    await this.offchain.updateArticle(data);
    this.toastr.success('Changed Status');
  }

  async selectPriority(optionValue: any) {
    const article = this.article;
    article.priority = article.priority * 1;
    const data = {
      articleId: article._id,
      priority: article.priority,
    }
    await this.offchain.updateArticle(data);
    this.toastr.success('Changed Priority');
  }
}
