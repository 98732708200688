import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-concierge',
  templateUrl: './concierge.component.html',
  styleUrls: ['./concierge.component.scss']
})
export class ConciergeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  contactUs(emailSub:string) {

    location.href = "mailto:info@vin-tracker.com?subject="+emailSub+'&body=This is what I need:';
  }

  
  contactUsPartner(emailSub:string) {
    location.href = "mailto:info@vin-tracker.com?subject="+emailSub;

    
  }


}
