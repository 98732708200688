import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { env } from 'process';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MongoDBService {
  private apiUrl = environment.mongoAPI;

  constructor(private http: HttpClient) {}

  getTables() {
    return this.http.get(`${this.apiUrl}/digible/tables?filter={name: {$regex: /^VIN/}}`);
  }

  selectData(tableName: string) {
    return this.http.get(`${this.apiUrl}/digible/${tableName}`);
  }

  updateData(tableName: string, data: any) {
    return this.http.put(`${this.apiUrl}/digible/${tableName}`, data);
  }

  createData(tableName: string, data: any) {
    return this.http.post(`${this.apiUrl}/digible/${tableName}`, data);
  }
}
