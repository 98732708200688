import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss']
})
export class GameComponent implements OnInit {

  @ViewChild('gameCanvas', { static: true }) gameCanvas: ElementRef;

  private ctx: CanvasRenderingContext2D;
  private car: Car;
  private lanes: Lane[];
  private obstacles: Obstacle[];
  private level: number;
  private levelStartTime:number;
  private gameOver: boolean;

  constructor() {}

  ngOnInit() {
    this.initGame();
    this.gameLoop();
    setInterval(() => this.spawnObstacle(), 2000);
  }

  initGame() {
    this.gameOver = false;
    this.ctx = this.gameCanvas.nativeElement.getContext('2d');
    const canvasWidth = this.gameCanvas.nativeElement.width = 800;
    const canvasHeight = this.gameCanvas.nativeElement.height = 600;
  
    const laneWidth = canvasWidth * 0.25;
    this.lanes = [
      new Lane(0, laneWidth, 'up'),
      new Lane(laneWidth, laneWidth, 'up'),
      new Lane(laneWidth * 2, laneWidth, 'down'),
      new Lane(laneWidth * 3, laneWidth, 'down'),
    ];
  
    const carWidth = canvasWidth * 0.1;
    const carHeight = canvasHeight * 0.15;
    const carSpeed = canvasWidth * 0.01;
    this.car = new Car(canvasWidth * 0.375, canvasHeight * 0.8, carWidth, carHeight, carSpeed);
    this.obstacles = [];
    this.level = 1;
  
    window.addEventListener('keydown', (e) => {
      if (e.key === 'ArrowLeft') {
        this.moveCar('left');
      } else if (e.key === 'ArrowRight') {
        this.moveCar('right');
      }
    });
    this.levelStartTime = Date.now();
  }
  

  moveCar(direction: 'left' | 'right') {
    this.car.updatePosition(direction, this.lanes[0].width);
  }
  

  displayGameOver() {
    this.ctx.font = '48px Arial';
    this.ctx.fillStyle = 'white';
    this.ctx.fillText('Game Over', this.gameCanvas.nativeElement.width / 2 - 100, this.gameCanvas.nativeElement.height / 2);
  }
  gameLoop() {
    if (this.gameOver) {
      this.displayGameOver();
      return;
    }
    this.clearCanvas();
  
    // Render lanes
    for (const lane of this.lanes) {
      lane.render(this.ctx);
    }
  
    // Update and render the player's car
    this.car.render(this.ctx);
  
    // Update and render obstacles
    for (const obstacle of this.obstacles) {
      obstacle.updatePosition();
      obstacle.render(this.ctx);
    }
  
    // Check for collisions and level completion
    for (const obstacle of this.obstacles) {
      // ...
      if (this.checkCollision(obstacle)) {
        this.gameOver = true;
        break;
      }
    }
    // Update the level and difficulty if needed
  
    requestAnimationFrame(() => this.gameLoop());
    if (this.levelComplete()) {
      this.increaseDifficulty();
    }
    this.displayStatus();
  }
  

  clearCanvas() {
    this.ctx.clearRect(0, 0, this.gameCanvas.nativeElement.width, this.gameCanvas.nativeElement.height);
  }

  spawnObstacle() {
    const laneIndex = Math.floor(Math.random() * 4);
    const lane = this.lanes[laneIndex];
    const width = this.gameCanvas.nativeElement.width * 0.1;
    const height = this.gameCanvas.nativeElement.height * 0.1;
    const speed = this.level * (lane.direction === 'up' ? -2 : 2);
  
    const y = lane.direction === 'up' ? this.gameCanvas.nativeElement.height : -height;
    const obstacle = new Obstacle(lane.x, y, width, height, speed);
    this.obstacles.push(obstacle);
}

  
  checkCollision(obstacle: Obstacle): boolean {
    // Check if the player's car collides with the given obstacle
    // Return true if there's a collision, false otherwise
    const carLeft = this.car.x;
  const carRight = this.car.x + this.car.width;
  const carTop = this.car.y;
  const carBottom = this.car.y + this.car.height;

  const obstacleLeft = obstacle.x;
  const obstacleRight = obstacle.x + obstacle.width;
  const obstacleTop = obstacle.y;
  const obstacleBottom = obstacle.y + obstacle.height;

  return (
    carLeft < obstacleRight &&
    carRight > obstacleLeft &&
    carTop < obstacleBottom &&
    carBottom > obstacleTop
  );
  }
  
  levelComplete(): boolean {
    // Determine the condition for level completion
    // Return true if the level is complete, false otherwise
     // Example: Complete a level every 30 seconds
  const levelDuration = 30 * 1000;
  const elapsedTime = Date.now() - this.levelStartTime;
  return elapsedTime >= levelDuration;
  }
  
  increaseDifficulty() {
    // Increase the difficulty based on the current level
    // For example, increase the number or speed of obstacles
    this.level += 1;
    this.levelStartTime = Date.now();
  }
  
  displayStatus() {
    // Display the current level, score, and game status on the screen
    this.ctx.font = '24px Arial';
    this.ctx.fillStyle = 'white';
    this.ctx.fillText(`Level: ${this.level}`, 10, 30);
  }

  // Add Car, Obstacle, and Lane classes, and any other necessary helper functions
}

class Car {
  constructor(
    public x: number,
    public y: number,
    public width: number,
    public height: number,
    public speed: number
  ) {}

  // Car rendering and position update methods
  render(ctx: CanvasRenderingContext2D) {
    ctx.fillStyle = 'blue';
    ctx.fillRect(this.x, this.y, this.width, this.height);
  }

  updatePosition(direction: 'left' | 'right', laneWidth: number) {
    if (direction === 'left' && this.x > 0) {
      this.x -= laneWidth;
    } else if (direction === 'right' && this.x < (laneWidth * 3)) {
      this.x += laneWidth;
    }
  }
}

class Obstacle {
  constructor(
    public x: number,
    public y: number,
    public width: number,
    public height: number,
    public speed: number
  ) {}

  // Obstacle rendering and position update methods
  
  render(ctx: CanvasRenderingContext2D) {
    ctx.fillStyle = 'red';
    ctx.fillRect(this.x, this.y, this.width, this.height);
  }

  updatePosition() {
    this.y += this.speed;
  }
}

class Lane {
  constructor(
    public x: number,
    public width: number,
    public direction: 'up' | 'down'
  ) {}

  // Lane rendering method
  render(ctx: CanvasRenderingContext2D) {
    ctx.strokeStyle = 'white';
    ctx.lineWidth = 4;
    ctx.setLineDash([10, 10]);
    ctx.beginPath();
    ctx.moveTo(this.x + (this.width / 2), 0);
    ctx.lineTo(this.x + (this.width / 2), ctx.canvas.height);
    ctx.stroke();
  }

  
}
