<a [routerLink]="singleItem.link">
  <div class="card">
    <div class="face face1">
      <div class="content">
        <span class="stars"></span>
        <p class="text">{{ singleItem.text }}</p>
      </div>
    </div>
    <div class="face face2">
      <h2>{{ singleItem.title }}</h2>
    </div>
  </div>
</a>
