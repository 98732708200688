import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  constructor() { }

 

  ngOnInit(): void {
  }

  copyEmail() {
    navigator.clipboard.writeText('info@vin-tracker.com').then(() => {
      // Handle successful copy here, e.g., display a notification
      alert('Email address copied to clipboard!');
    }, (err) => {
      // Handle errors here
      console.error('Could not copy text: ', err);
    });
  }

}
