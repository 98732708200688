<head>
<link href="https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap" rel="stylesheet">
</head>

<header class="default-font flex flex-col sm:flex-row justify-center items-center mt-12 text-2xl sm:text-3xl" [routerLink]="'/featured'">
  <img src="../../../assets/images/vin-track-new-black.png" class="w-[250px] md:w-[300px]"/>
  Spotlight
</header>
<br>
<div class="sm:px-2 md:px-8 w-[90%] md:w-[80%] mx-auto">
  <app-view-article-section [allFlag]=false></app-view-article-section>
  <!-- <ng-container *ngFor="let vinItem of vin; let i = index">
    <div class="feature-vin" (click)="onVinClick(vin[i])">
      <a [routerLink]="['/item', vin.id]"></a>
      <ng-container *ngIf="vinItem.isVideo; else imageBlock">
        <video #videoPlayer class="feature-vin__video" [src]="vinItem.image" controls>
          Your browser does not support the video tag.
        </video>
        <button (click)="videoPlayer.muted = !videoPlayer.muted">Toggle Mute</button>
      </ng-container>
      <ng-template #imageBlock>
        <img [src]="vinItem.image" alt="{{vinItem.name}}" class="feature-vin__image">
      </ng-template>
      <div class="feature-vin__info">
        <h2 class="feature-vin__name vin-name default-font">{{vinItem.name}}</h2>
        <p class="feature-vin__description default-font">{{vinItem.description}}</p>
  
        <p *ngIf="vinEstimate > 0" class = "feature-vin__vin-estimate default-font">VIN Estimate: ${{vinEstimate}}</p>
      </div>
    </div>
  </ng-container> -->
</div>
