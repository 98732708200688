import { Injectable } from '@angular/core';
import { WalletService } from 'src/app/services/wallet.service';
import { OffchainService } from './offchain.service';
import { environment } from 'src/environments/environment';
import { Receipt } from '../types/mint.types';

@Injectable()
export class VinNFTService {
  constructor(
    private readonly wallet: WalletService,
    private readonly offchain: OffchainService
  ) {
    this.setVinNFTContract();
  }
  vinNFTAddress = environment.vinNFTMAddressMatic;
  vinNFTContract: any;

  currentAccount: string | null;

  async mint(
    receiver: string,
    vinName: string,
    vinImage: string,
    vin: string,
    tokenUri: string,
    tokenPrivateUri: string,
    promoCode: string,
    pkg: string,
    metadata: string,
    privateMetadata: string,
    email: string,
    password: string
  ): Promise<Receipt> {
    console.log(metadata);
    const from = await this.getAccount();
    console.log(receiver, vinName, vinImage, vin, tokenUri, tokenPrivateUri, pkg, promoCode);
    let estimateGas = await this.vinNFTContract.methods
      .mint(receiver, vinName, vinImage, vin, tokenUri, tokenPrivateUri, pkg, promoCode)
      .estimateGas({ from: from });
    estimateGas += 4500000;
    const gasPrice = await this.wallet.getGasPrice();
    console.log(gasPrice, 'gasPrice');
    const response = await this.vinNFTContract.methods
      .mint(receiver, vinName, vinImage, vin, tokenUri, tokenPrivateUri, pkg, promoCode)
      .send({ from: from, gas: estimateGas, gasPrice })
      .once('receipt', async (receipt: any) => {
        console.log(receipt, 'mint receipt');
        await this.offchain.createNft(
          receipt.events?.NewVin?.returnValues?.tokenId,
          receiver,
          tokenUri,
          metadata,
          privateMetadata,
          promoCode,
          email,
          password,
        );
      });

    return response;
  }

  async multiMint(
    receiver: string,
    vinNames: string[],
    vinImages: string[],
    vins: string[],
    tokenBaseUri: string,
    tokenUriSuffixes: string[]
  ): Promise<Receipt> {
    const from = await this.getAccount();

    const response = await this.vinNFTContract.methods
      .multiMint(
        receiver,
        vinNames,
        vinImages,
        vins,
        tokenBaseUri,
        tokenUriSuffixes
      )
      .send({ from: from });

    return response;
  }

  async getVinName(tokenId: string): Promise<string> {
    const from = await this.getAccount();
    return await this.vinNFTContract.methods.vinName(tokenId).call({ from });
  }

  async getVinImage(tokenId: string): Promise<string> {
    const from = await this.getAccount();
    return await this.vinNFTContract.methods.vinImage(tokenId).call({ from });
  }

  async getVin(tokenId: string): Promise<string> {
    const from = await this.getAccount();
    return await this.vinNFTContract.methods.vin(tokenId).call({ from });
  }

  async getVinUri(tokenId: number): Promise<string> {
    const from = await this.getAccount();
    return await this.vinNFTContract.methods.tokenURI(tokenId).call({ from });
  }

  getMintFee(plan: string): number {
    // const from = await this.getAccount();
    // return await this.vinNFTContract.methods._mintFees(plan).call({ from });
    const mintFees = environment.mintFee;
    // @ts-ignore
    return mintFees[plan];
  }

  async getUpdateImageFee(): Promise<string> {
    const from = await this.getAccount();
    return await this.vinNFTContract.methods.updateImageFee().call({ from });
  }

  async getUpdateMetaDataFee(): Promise<string> {
    const from = await this.getAccount();
    return await this.vinNFTContract.methods.updateMetaDataFee().call({ from });
  }

  async getfeeTokenAddress(): Promise<string> {
    const from = await this.getAccount();
    return await this.vinNFTContract.methods.feeTokenAddress().call({ from });
  }

  async getFeeWallet(): Promise<string> {
    const from = await this.getAccount();
    return await this.vinNFTContract.methods.feeWallet().call({ from });
  }

  async updateVinImage(tokenId: number, newVinImage: string): Promise<string> {
    const from = await this.getAccount();
    return await this.vinNFTContract.methods
      .updateVinImage(tokenId, newVinImage)
      .send({ from });
  }

  async updateVinUri(tokenId: number, newVinUri: string, privateUri: string, owner: string, metadata: string, privateMetadata: string): Promise<string> {
    const from = await this.getAccount();
    return await this.vinNFTContract.methods
      .updateVinUri(tokenId, newVinUri, privateUri)
      .send({ from })
      .once('receipt', async (receipt: any) => {
        console.log(receipt, 'mint receipt');
        await this.offchain.updateNft(
          tokenId,
          owner,
          newVinUri,
          metadata,
          privateMetadata,
        );
      });
  }

  async setFees(
    _feeToken: string,
    _mintFee: string,
    _imageFee: string,
    _metadataFee: string,
    _feeWallet: string
  ): Promise<string> {
    const from = await this.getAccount();
    return await this.vinNFTContract.methods
      .setFees(_feeToken, _mintFee, _imageFee, _metadataFee, _feeWallet)
      .send({ from });
  }

  async burn(network: string, nftAddress: string, tokenId: string) {
    const from = await this.getAccount();
    return await this.vinNFTContract.methods
      .transferFrom(from, '0x000000000000000000000000000000000000dEaD', tokenId)
      .send({ from });
  }

  async transfer(tokenId: number, receiver: string): Promise<void> {
    const _from = await this.getAccount();
    await this.vinNFTContract.methods
      .transferFrom(_from, receiver, tokenId)
      .send({ _from });
  }

  async isAdmin(account?: string): Promise<boolean> {
    let from;
    if (account) {
      from = account;
    } else {
      from = await this.getAccount();
    }
    if (!from) {
      return false;
    }
    const adminRole =
      '0x0000000000000000000000000000000000000000000000000000000000000000';
    return await this.vinNFTContract.methods.hasRole(adminRole, from).call();
  }

  async isFreeMinter(account?: string): Promise<boolean> {
    let from;
    if (account) {
      from = account;
    } else {
      from = await this.getAccount();
    }
    if (!from) {
      return false;
    }
    const freeMinterRole =
      '0xad5901a8f44264acf63b249526e15cd042c40411b29d20f77dc9361da8677691';
    return await this.vinNFTContract.methods
      .hasRole(freeMinterRole, from)
      .call();
  }

  async grantFreeMinterRole(account: string): Promise<void> {
    const freeMinterRole =
      '0xad5901a8f44264acf63b249526e15cd042c40411b29d20f77dc9361da8677691';
    return await this.vinNFTContract.methods
      .grantRole(freeMinterRole, account)
      .send({ from: await this.getAccount() });
  }

  async revokeFreeMinterRole(account: string): Promise<void> {
    const freeMinterRole =
      '0xad5901a8f44264acf63b249526e15cd042c40411b29d20f77dc9361da8677691';
    return await this.vinNFTContract.methods
      .revokeRole(freeMinterRole, account)
      .send({ from: await this.getAccount() });
  }

  async getTotalSupply() {
    return await this.vinNFTContract.methods.totalSupply().call();
  }

  async getOwnerOf(_tokenId: number) {
    return await this.vinNFTContract.methods.ownerOf(_tokenId).call();
  }

  async getPackage(_tokenId: number) {
    return await this.vinNFTContract.methods._addrPkg(_tokenId).call();
  }

  async getVinItem(index: number) {
    let _url = await this.getVinUri(index);
    const _owner = await this.getOwnerOf(index);
    if (_url && _url.indexOf('https://ipfs.moralis.io:2053/ipfs') != -1) {
      _url = _url.replace(
        'https://ipfs.moralis.io:2053/ipfs',
        'https://ipfs.io/ipfs'
      );
    }
    let _vinItem = {};
    try {
      // const controller = new AbortController();
      // const abortFetch = setTimeout(() => controller.abort(), 1500);
      await fetch(_url)
      .then((res) => res.json())
      .then((result) => {
        _vinItem = {
          ...result,
          owner: _owner,
          id: index,
          image: result.image.replace(
            'https://ipfs.moralis.io:2053/ipfs',
            'https://ipfs.io/ipfs'
          ),
        };
      });
      // clearTimeout(abortFetch);
    } catch (error) {
      return null;
    }
    
    return _vinItem as VinNftType;
  }

  async getVinsByOwner(_owner: string) {
    const _total = await this.getTotalSupply();
    const _vins: VinNftType[] = [];
    for (let idx = 1; idx <= _total; idx++) {
      const _vinOwner = await this.getOwnerOf(idx);
      console.log(_vinOwner);
      if (_vinOwner.toLowerCase() !== _owner.toLowerCase()) continue;
      const _vinItem = await this.getVinItem(idx);
      if (_vinItem)
        _vins.push(_vinItem);
    }
    return _vins;
  }

  async getLatestVins(_limit = 3) {
    const _total = await this.getTotalSupply();
    const _vins: VinNftType[] = [];
    for (let index = _total; index > _total - _limit; index--) {
      if (index < 1) continue;
      const _vinItem = await this.getVinItem(index);
      if (_vinItem)
        _vins.push(_vinItem);
      else _limit ++;
    }
    return _vins;
  }

  async setVinNFTContract(): Promise<any> {
    if (!window.ethereum) return null;
    this.vinNFTContract = await this.getVinNFTContract(true, 'MATIC');
  }

  private async getVinNFTContract(
    readonly?: boolean,
    network?: string
  ): Promise<any> {
    const abi = require('../../assets/abis/vinNFT.json');

    return new (this.wallet.getWeb3().eth.Contract)(
      abi,
      environment.vinNFTMAddressMatic
    );
  }

  private async getTokenContract(tokenAddress: string) {
    const abi = require('../../assets/abis/erc20.json');

    return new (this.wallet.getWeb3().eth.Contract)(abi, tokenAddress);
  }

  private async getAccount(): Promise<string | null> {
    if (!this.currentAccount) {
      this.currentAccount = await this.wallet.getAccount();
    }
    return this.currentAccount;
  }

  async allowedToken(): Promise<number> {
    const account = await this.getAccount();
    const erc20Token = await this.getTokenContract(
      environment.feeCurrencyAddressMatic
    );

    return await erc20Token.methods
      .allowance(account, environment.vinNFTMAddressMatic)
      .call();
  }

  async getApprovedAmount(_address: string): Promise<number> {
    const account = await this.getAccount();
    const erc20Token = await this.getTokenContract(
      _address
    );

    const _allowance = await erc20Token.methods
      .allowance(account, environment.vinNFTMAddressMatic)
      .call();
    const decimals = await erc20Token.methods.decimals().call();
    console.log(_allowance, decimals);
    return _allowance / (10 ** decimals);
  }

  async getBalance(_address: string): Promise<number> {
    const account = await this.getAccount();
    const erc20Token = await this.getTokenContract(
      _address
    );

    const _balance = await erc20Token.methods
      .balanceOf(account)
      .call();
    const decimals = await erc20Token.methods.decimals().call();
    console.log(_balance, decimals);
    return _balance / (10 ** decimals);
  }

  async getDecimals(_address: string) {
    const erc20Token = await this.getTokenContract(
      _address
    );
    return await erc20Token.methods.decimals().call();
  }

  async approveToken(amount: string): Promise<number> {
    const account = await this.getAccount();
    const erc20Token = await this.getTokenContract(
      environment.feeCurrencyAddressMatic
    );
    console.log(amount, 'approve amount');
    return await erc20Token.methods
      .approve(environment.vinNFTMAddressMatic, amount)
      .send({ from: account });
  }

  async getMetadata(url: string) {
    let metadata: string = '';
    try {
      // const controller = new AbortController();
      // const abortFetch = setTimeout(() => controller.abort(), 1500);
      await fetch(url)
      .then((res) => res.json())
      .then((result) => {
        metadata = result;
      });
      // clearTimeout(abortFetch);
    } catch (error) {
      return '';
    }
    return metadata;
  }

  // Add all to db
  // async allToDb() {
  //   const totalCount = await this.vinNFTContract.methods.totalSupply().call();
  //   for (let index = 1; index <= totalCount; index++) {
  //     const ownerOf = await this.vinNFTContract.methods.ownerOf(index).call();
  //     const tokenURI = await this.vinNFTContract.methods.tokenURI(index).call();
  //     const metadata = await this.getMetadata(tokenURI);
  //     console.log(ownerOf, tokenURI, metadata);
  //     if (metadata === '') continue;
  //     await this.offchain.createNft(index, ownerOf, tokenURI, JSON.stringify(metadata), '');
  //   }
  // }
}
