<div class="mt-5 bg-white rounded-lg shadow-md overflow-hidden default-font">
    <div *ngIf="!article.imageUrl">
        <img [src]="article.videolink" alt="Article Image" *ngIf="!article?.isvideo" class="w-full h-48 object-cover">
        <video loop controlsList="nodownload" class="w-full h-48" controls *ngIf="article?.isvideo">
            <source type="video/webm" [src]="article.videolink" />
            <source type="video/mp4" [src]="article.videolink" />
        </video>
    </div>
    <div *ngIf="article.imageUrl">
        <img [src]="article.imageUrl" alt="Article Image" *ngIf="!article?.isvideo" class="w-full h-48 object-cover">
        <video loop controlsList="nodownload" class="w-full h-48" controls *ngIf="article?.isvideo">
            <source type="video/webm" [src]="article.imageUrl" />
            <source type="video/mp4" [src]="article.imageUrl" />
        </video>
    </div>
    <div class="p-4">
        <h2 class="text-xl font-semibold mb-2">{{ article.title }}</h2>
        <p class="text-gray-700 mb-4 line-clamp-3">{{ article.story}}</p>
        <div class="flex justify-between">
            <div class="block">
                <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-2 rounded mr-2"
                    (click)="toggleEnable()">
                    {{ article.status ? 'Enabled' : 'Disabled' }}
                </button>
                <select *ngIf="article.status" [(ngModel)]="article.priority" (change)="selectPriority($event.target.value)"
                    class="bg-white border border-gray-400 rounded px-2 py-2 shadow-sm text-sm leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300">
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                </select>
            </div>
            <div class="block">
                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded mr-2"
                    (click)="updateArticle()">Edit</button>
                <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-2 rounded"
                    (click)="removeArticle()">Remove</button>
            </div>
        </div>
    </div>
</div>