import { environment
 } from "src/environments/environment";
interface IList {
  haveItem: boolean;
  name: string;
}
export interface IPricingData {
  title: string;
  recommended: boolean;
  price: string;
  list: IList[];
  plan?: string;
}
export const PricingData: IPricingData[] = [
  // {
  //   title: 'VIN-Tracker Free',
  //   plan: 'free',
  //   price: environment.mintFee.free.toString(),
  //   recommended: false,
  //   list: [
  //     {
  //       haveItem: true,
     
  //      name: "VIN-Tracker is a vehicle's digital footprint linked to either a photograph or Vehicle Identification Number (VIN) with a description, attributes, media, and story backed up and minted onto the blockchain."
  //     },

  //     {
  //       haveItem: true,
  //       name: "Your vehicle's description and ability to change and update as your vehicle's life changes.",
  //     },
  //     {
  //       haveItem: true,
  //       name: '6 media files (photos, videos).',
  //     },
  //     {
  //       haveItem: true,
  //       name: 'VIN-Tracker ownership certification which ties your ownership to your VIN-Tracker with the ability to transfer ownership of your VIN-Tracker with your vehicle at the time of sale (+$10).',
  //     },

  //     {
  //       haveItem: true,
  //       name: "Recommended for the vehicle enthusiast or photographer who wants to showcase a vehicle digitally and potentially mint multiple VIN-Trackers for their collection to document a vehicle's history."
  //     },
  //   ],
  // },
  {
    title: "VIN-Tracker Perstige Edition with Digital Storage For Service Records",
    plan: 'professional',
    price: environment.mintFee.professional.toString(),
    recommended: true,
    list: [

      {
        haveItem: true,
        //name: "Your own data storage vault that retains your service records, important files, and any other documents that are associated with your car’s history.",
        name: "VIN-Tracker + a DATA STORAGE VAULT that retains your service records, important files and any other documents associated with your vehicle's history.",
      },
      {
        haveItem: true,
        name: "Your vehicle's description and ability to change and update the digital vault as your car’s life changes.",
      },
      {
        haveItem: true,
        name: '100 media files (photos, videos).',
      },
     
      {
        haveItem: true,
        name: "Your VIN-Tracker profile will be uploaded by our team to VINwiki and a PDF of the VINWiki history will be uploaded to your vault.",
      },

      {
        haveItem: true,
        name: 'VIN-Tracker ownership CERTIFICATION included with proof of ownership.',
      },
      {
        haveItem: true,
        name: "This package is best suited for a collector who is trying to keep all records organized and in a secure location for easy transfer and access, while having a digital collectible garage of their vehicle to showcase.",
      },
    ],
  },
  // {
  //   title: 'Vin-Tracker Professional Package with CARFAX',
  //   plan: 'professional',
  //   price: environment.mintFee.professional.toString(),
  //   recommended: false,
  //   list: [
     
  //     //  {
  //     //   haveItem: true,
  //     //   name: 'You will receive a physical trading card that represents your VINnft by mail.',
  //     // },
  //     // {
  //     //   haveItem: true,
  //     //   name: 'A complimentary CARFAX&#174; PDF will be uploaded at the time of your purchase to your digital vault.',
  //     // },

  //     {
  //       haveItem: true,
  //       name: 'VIN-Tracker with Expanded Digital Storage + you will receive a CARFAX uploladed to your DATA STORAGE VAULT.',
  //     },

    
  //     {
  //       haveItem: true,
  //       name: "Everything in The Digital Storage Package with up to 100 media files."
  //     }, 
  //     {
  //       haveItem: true,
  //       name: 'Ability to share photographs and files to others.',
  //     },
   
  //     {
  //       haveItem: true,
  //       name: "VIN-Tracker ownership CERTIFICATION included with proof of ownership.",
  //     },

  //     {
  //       haveItem: true,
  //       name: "Makes a great gift.",
  //     },
    
  //     {
  //       haveItem: true,
  //       name: "This package is most suited for an active collector who aims to use their VIN-Tracker as a marketing tool for selling purposes.",
  //     },
  //   ],
  // },
  // {
  //   title: 'Dealer & Concierge Services',
  //   price: 'Contact Us',
  //   recommended: false,
  //   list: [
  //     {
  //       haveItem: true,
  //       name: 'Premium Plus Package with the ability to buy/sell your vehicle through an authorized dealer.',
  //     },
  //     {
  //       haveItem: true,
  //       name: 'Additonal services include custom uploads, organizing records, and other VIN management support.',
  //     },
  //     {
  //       haveItem: true,
  //       name: 'This package is most suited for a buyer or seller on an online platform (bringatrailer, carsandbids, ebay), with an authorized dealer acting as the escrow agent to handle the transfer of money and title through the transfer of the VIN-Tracker.',
  //     },
     
  //   ],
  // },
];