import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { OpenGraphService } from 'src/app/open-graph.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  isPreviewComponent: boolean;

  constructor(private openGraphService: OpenGraphService) {}

  ngOnInit(): void {
    const url = 'https://www.vin-tracker.com/about';
    const title = 'About VIN-Tracker - Complete Vehicle Info on thie Chain' ;
    const description = 'Showcase and list your vehicle for sale using blockchain tehcnology';
    const image = 'https://digible.mypinata.cloud/ipfs/bafybeifbqxjve3h6dpkkeehmd3dicre4lf5g6luurbiywfj5cdt5ng7nla'
  
    this.openGraphService.setMetaTags(url, title, description, image);
  
  }

}
