import { Component, OnInit, Input } from '@angular/core';
import { OffchainService } from '../services/offchain.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-article-section',
  templateUrl: './view-article-section.component.html',
  styleUrls: ['./view-article-section.component.scss']
})
export class ViewArticleSectionComponent implements OnInit {
  @Input() allFlag: boolean;
  articles: any = [];
  loading: boolean = false;

  constructor(
    private readonly offchain: OffchainService,
  ) { }

  ngOnInit(): void {
    if (this.allFlag) this.loading = true;
    if (this.allFlag) {
      this.getAllArticles();
    } else {
      this.getLatestAriticles();
    }
    if (this.allFlag) this.loading = false;
  }



  async getAllArticles() {
    const articles = await this.offchain.getAllArticles();
    this.articles = articles.map((article: any) => {
      // if(article.imageUrl){
      //   return { ...article, imageUrl: `${environment.offchainApi}/${article.imageUrl}` }
      // }
      return article;
    });
  }

  async getLatestAriticles() {
    const articles = await this.offchain.getLatestArticles();
    this.articles = articles.map((article: any) => {
      // if(article.imageUrl){
      //   return { ...article, imageUrl: `${environment.offchainApi}/${article.imageUrl}` }
      // }
      return article;
    });
  }
}
