<div class="card shadow-lg">
  <a [routerLink]="'/item/' + singleItem.id" class="flex items-center justify-center"
    [ngClass]="fixedHeight ? 'md:h-[300px]' : ''">
    <div *ngIf="!singleItem?.isVideo">
      <div class="image-container">
        <img [src]="singleItem.image" class="card__image" [alt]="singleItem.name" />
        <div *ngIf="listPrice" class="priceBanner">
          <div class="priceBanner-inner">
            {{listPrice}}
          </div>
        </div>
      </div>
    </div>

    <video loop controlsList="nodownload" class="card__image mb-12" controls *ngIf="singleItem?.isVideo">
      <source type="video/webm" [src]="singleItem.image + '\#t=0.001'" />
      <source type="video/mp4" [src]="singleItem.image + '\#t=0.001'" />
    </video>
  </a>
  <div class="card__overlay">
    <div class="card__header">
      <span class="card__title font-semibold">{{ singleItem.name }}</span>
      <!-- <span class="text-sm font-medium text-black text-opacity-75">{{ singleItem?.vinSn }}</span> -->
    </div>
    <p class="card__description !pb-2">
      {{ singleItem.description }}
    </p>
    <!-- <div class="ledFont1 color-crimson text-xs px-8">
      VIN SN : {{ singleItem?.vinSn }}
    </div> -->
    <div *ngIf="singleItem.comments?.length > 0" class="text-sm px-8 text-ellipsis overflow-hidden whitespace-nowrap">
      <span class="raceFont1">Latest Comment</span>:
      {{ singleItem.comments[0].comment }}
    </div>
  </div>
  <button class="favorite flex items-center justify-center" (click)="toggleFavorite()">
    <i class="material-icons" [class.favorited]="isFavorited">favorite</i>
  </button>
</div>