<a [routerLink]="'/item/' + singleItem.id">
  <div
      class="w-full rounded overflow-hidden shadow-lg border border-2 border-yellow-300 border-solid image-container">
      <img [src]="singleItem.image" class="w-full h-64 object-cover" />
      <div class="priceBanner">{{ singleItem.price }}</div>
      <div class="productName default-font text-left text-xl w-full text-white px-4 py-2 line-clamp-1">{{
          singleItem.name }}</div>
  </div>
</a>
<button class="favorite flex items-center justify-center" (click)="toggleFavorite()">
<i class="material-icons" [class.favorited]="isFavorited">favorite</i>
</button>