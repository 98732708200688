import { Component, OnInit } from '@angular/core';
import { OffchainService } from '../services/offchain.service';

@Component({
  selector: 'app-articles-section',
  templateUrl: './articles-section.component.html',
  styleUrls: ['./articles-section.component.scss']
})
export class ArticlesSectionComponent implements OnInit {
  uploadingFile: File | null = null;
  filedata: string | null = null;
  previewType: string = '';
  articles: any = [];
  editFlag: boolean;
  mimeType: string | null = null;
  isLoggedIn: boolean;
  isLoading: boolean;
  articleId: null | string;
  username: string;
  password: string;
  isVideo: boolean;
  title: string = '';
  story: string = '';
  vinlink: string = '';
  videolink: string = '';

  constructor(
    private readonly offchain: OffchainService,
  ) { }

  ngOnInit(): void {
    this.editFlag = false;
    this.isLoading = false;
    this.isLoggedIn = false;
    this.articleId = null;
    this.isVideo = false;
    this.filedata = null;
    this.username = '';
    this.password = '';
    this.getAllArticles();
  }

  login() {
    if (this.username === 'admin' && this.password === '123') {
      this.isLoggedIn = true;
    } else {
      alert('Please input correct info!');
    }
  }

  async saveArticle() {
    const formData: any = new FormData();
    let S3BucketImageLink;
    if (this.uploadingFile){
      formData.append('file', this.uploadingFile);
      this.isLoading = true;
      let filePath = 'images/' + Math.random() * 10000000000000000 + '_' + "S3BucketImageLink";
      S3BucketImageLink = await this.offchain.uploadToS3Service(this.uploadingFile, filePath);
    }
    this.isLoading = true;
    let result;
    if (this.editFlag) { // if edit data
      formData.append('articleId', this.articleId);
      formData.append('title', this.title);
      formData.append('story', this.story);
      formData.append('isvideo', this.isVideo);
      formData.append('vinlink', this.vinlink);
      formData.append('mimeType', this.mimeType);
      formData.append('videolink', this.videolink);
      this.uploadingFile ? formData.append('imageUrl', S3BucketImageLink.Location) : "";
      // const data = {
      //   // 'articleId': this.articleId,
      //   'title': this.title,
      //   'story': this.story,
      //   'isvideo': this.isVideo,
      //   'vinlink': this.vinlink,
      //   'videolink': this.videolink,
      // }
      result = await this.offchain.updateArticle(formData);
      this.articleId = null;
    } else { // create new data
      formData.append('title', this.title);
      formData.append('story', this.story);
      formData.append('isvideo', this.isVideo);
      formData.append('mimeType', this.mimeType);
      formData.append('vinlink', this.vinlink);
      formData.append('videolink', this.videolink);
      formData.append('imageUrl', S3BucketImageLink.Location);
      result = await this.offchain.createArticle(formData);
    }
    if (result) {
      this.isLoading = false;
      this.cancel();
      this.getAllArticles();
    }
  }

  async getAllArticles() {
    this.articles = await this.offchain.getAllArticles();
  }

  async removeArticle(articleId: any) {
    this.isLoading = true;
    const res: any = this.offchain.removeArticle(articleId);
    if (res) {
      this.isLoading = false;
      this.articles = this.articles.filter((item: any) => item._id !== articleId);
    }
  }

  updateArticle(articleId: any) {
    const { title, story, isvideo, vinlink, videolink, mimeType } = this.articles.find((item: any) => item._id === articleId);
    this.editFlag = true;
    this.articleId = articleId;
    this.title = title;
    this.story = story;
    this.isVideo = isvideo ? isvideo : false;
    this.videolink = videolink;
    this.vinlink = vinlink;
    this.mimeType = mimeType ? mimeType : '';
  }

  cancel() {
    this.editFlag = false;
    this.articleId = null;
    this.title = '';
    this.story = '';
    this.filedata = null;
    this.mimeType = null;
    this.uploadingFile = null;
    this.isVideo = false;
    this.vinlink = '';
    this.videolink = '';
    this.previewType = '';
  }

  handleFileInput(event: any) {
    const file: File = event.target.files[0];
    // Check file type
    const allowedImageTypes = ['image/svg+xml', 'image/png', 'image/jpeg', 'image/gif'];
    const allowedVideoTypes = ['video/webm', 'video/mp4'];
    const fileType = file.type;
    if (allowedImageTypes.includes(fileType)) {
      this.previewType = 'image';
      this.isVideo = false;
    } else if (allowedVideoTypes.includes(fileType)) {
      this.isVideo = true;
      this.previewType = 'video';
    } else {
      alert('Invalid file type. Please select a valid file type.');
      return;
    }
    this.mimeType = fileType;
    // Check file size
    const maxSize = 100 * 1024 * 1024; // 100MB
    const fileSize = file.size;
    if (fileSize > maxSize) {
      alert('File size exceeds the maximum limit of 100MB.');
      return;
    }
    console.log(file, '---> file')
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.filedata = e.target.result;
    }
    reader.readAsDataURL(file);
    this.uploadingFile = file;
  }
}